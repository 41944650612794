var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: "icon " + (_vm.cover ? "ui-bg-img" : "ui-bg-img-contain"),
    style: {
      backgroundImage:
        "url(" +
        _vm.$lib.imgUrl(_vm.src, _vm.originUrl) +
        "?v=" +
        _vm.timestamp +
        ")",
      width: _vm.width,
      height: _vm.height,
      cursor: _vm.cursor
    },
    on: {
      click: function($event) {
        return _vm.$emit("click", true)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }