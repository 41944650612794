import { defineStore } from 'pinia'
import { getIdFromUrl } from 'vue-youtube'
import { YOUTUBE_MODAL } from '@/pinia/keys'

export const useYoutubeModalStore = defineStore(YOUTUBE_MODAL, {
    state: () => ({
        youtubeId: null,
    }),
    actions: {
        openByUrl(url: string) {
            this.youtubeId = getIdFromUrl(url) ?? null

            return !!this.youtubeId
        },
        openById(id) {
            this.youtubeId = id || null

            return !!this.youtubeId
        },
        close() {
            this.reset()
        },
        reset() {
            if (this.youtubeId) {
                this.youtubeId = null
            }
        },
    },
})
