class ArtistDataInfo {
  constructor() {
    this.artistidx = '';
    this.percent = 0;
    this.genre = '';
    this.location = '';
    this.teamName = '';
    this.teamDesc = '';
    this.teamDescImgUrl = '';
    this.playDesc = '';
    this.hashTag = '';
    this.gender = '';
    this.birth = '';
    this.career = '';
    this.subGenre = '';
    this.genreType = '';
    this.program = '';
    this.showHost = '';
    this.matchVoice = '';
    this.recommSong = '';
    this.mcStyle = '';
    this.teamType = '';
    this.bodyHeight = '';
    this.bodyWeight = '';
    this.bodyShoesize = '';
    this.bodyTopsize = '';
    this.bodyBottomsize = '';
    this.interview1 = '';
    this.interview2 = '';
    this.interview3 = '';
    this.teamImgUrl = '';
    this.isPickPro = '';
    this.imgUrl1 = '';
    this.imgUrl2 = '';
    this.imgUrl3 = '';
    this.imgUrl4 = '';
    this.imgUrl5 = '';
    this.imgUrl6 = '';
    this.imgUrl7 = '';
    this.imgUrl8 = '';
    this.imgUrl9 = '';
    this.imgUrl10 = '';
    this.checkImgUrl = '';
    this.bankImgUrl = '';
    this.liveMov = [];
    this.liveUrl = [];
    this.certUpdateInfo = '';
    this.movUrl1 = '';
    this.movUrl2 = '';
    this.movUrl3 = '';
    this.movUrl4 = '';
    this.movUrl5 = '';
    this.movUrl6 = '';
    this.movUrl7 = '';
    this.movUrl8 = '';
    this.movUrl9 = '';
    this.movUrl10 = '';
    this.movUrl11 = '';
    this.movUrl12 = '';
    this.movUrl13 = '';
    this.movUrl14 = '';
    this.movUrl15 = '';
    this.movUrl16 = '';
    this.movUrl17 = '';
    this.movUrl18 = '';
    this.movUrl19 = '';
    this.movUrl20 = '';
    this.priceStart  = '';
    this.priceEnd  = '';
    this.wantPrice  = '';
    this.memberCount  = '';
    this.saleType  = '';
    this.salePer  = '';
    this.checkDate  = '';
    this.pointCount  = '';
    this.reviewCount  = '';
    this.favCount  = '';
    this.viewCount  = '';
    this.proCount  = '';
    this.chatCount  = '';
    this.telCount  = '';
    this.contactCount  = '';
    this.offerPayCount  = '';
    this.cancelCount  = '';
    this.noshowCount  = '';
    this.pdfCount  = '';
    this.proAvg  = '';
    this.starCheck  = '';
    this.bank  = '';
    this.bankAccount  = '';
    this.bankHolder  = '';
    this.cusFlag  = '';
    this.listNum  = '';
    this.billkey  = '';
    this.billPrice  = '';
    this.cusPrice  = '';
    this.memberDate  = '';
    this.newMemberDate  = '';
    this.reqDate  = '';
    this.agreeDate  = '';
    this.popularDate  = '';
    this.listDisabledDate  = '';
    this.confirmDate  = '';
    this.directOfferListDate  = '';
    this.disabledDate  = '';
    this.userUpdateDate  = '';
    this.updatedDate  = '';
    this.createdDate  = '';
    this.priceType  = '';
    this.cusPriceStart  = '';
    this.cusPriceEnd  = '';
    this.includeVatFlag  = '';
    this.expPurchaseBtnFlag  = '';
    this.addWorkName  = '';
    this.addWorkPrice  = '';
    this.teamDescHtml  = '';
    this.categoryUid  = '';
    this.optionType  = '';
    this.regNo  = '';
  }
}

class ReqArtistData {
  constructor() {
    this.artistidx = '';
    this.genre = '';
    this.location = '';
    this.teamName = '';
    this.teamDesc = '';
    this.teamDescImgUrl = '';
    this.playDesc = '';
    this.hashTag = '';
    this.gender = '';
    this.birth = '';
    this.career = '';
    this.subGenre = '';
    this.genreType = '';
    this.program = '';
    this.showHost = '';
    this.matchVoice = '';
    this.recommSong = '';
    this.mcStyle = '';
    this.teamType = '';
    this.bodyHeight = '';
    this.bodyWeight = '';
    this.bodyShoesize = '';
    this.bodyTopsize = '';
    this.bodyBottomsize = '';
    this.interview1 = '';
    this.interview2 = '';
    this.interview3 = '';
    this.teamImgUrl = '';
    this.isPickPro = '';
    this.imgUrl1 = '';
    this.imgUrl2 = '';
    this.imgUrl3 = '';
    this.imgUrl4 = '';
    this.imgUrl5 = '';
    this.imgUrl6 = '';
    this.imgUrl7 = '';
    this.imgUrl8 = '';
    this.imgUrl9 = '';
    this.imgUrl10 = '';
    this.checkImgUrl = '';
    this.bankImgUrl = '';
    this.liveMov = [];
    this.liveUrl = [];
    this.certUpdateInfo = '';
    this.movUrl1 = '';
    this.movUrl2 = '';
    this.movUrl3 = '';
    this.movUrl4 = '';
    this.movUrl5 = '';
    this.movUrl6 = '';
    this.movUrl7 = '';
    this.movUrl8 = '';
    this.movUrl9 = '';
    this.movUrl10 = '';
    this.movUrl11 = '';
    this.movUrl12 = '';
    this.movUrl13 = '';
    this.movUrl14 = '';
    this.movUrl15 = '';
    this.movUrl16 = '';
    this.movUrl17 = '';
    this.movUrl18 = '';
    this.movUrl19 = '';
    this.movUrl20 = '';
  }
}

export { ReqArtistData, ArtistDataInfo };