<template>
  <div
    :class="`icon ${cover ? 'ui-bg-img' : 'ui-bg-img-contain'}`"
    :style="{
      backgroundImage: `url(${$lib.imgUrl(src, originUrl)}?v=${timestamp})`,
      width: width,
      height: height,
      cursor: cursor,
    }"
    @click="$emit('click', true)"
  >
  </div>
</template>

<script>
export default {
  name: 'icon',
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    cursor: {
      type: String,
    },
    cover: {
      type: Boolean,
      default: true,
    },
    originUrl: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        timestamp: new Date().getTime(),
    }

  }
}
</script>
