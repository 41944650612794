var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "modal",
      staticClass: "modal fade",
      attrs: { tabindex: "-1", role: "dialog" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          class: [
            { "no-border": _vm.noBorder },
            { "round-modal": _vm.roundModal }
          ],
          style: { maxWidth: _vm.maxWidth },
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm.$slots.header
              ? _c(
                  "div",
                  { staticClass: "modal-header" },
                  [_vm._t("header")],
                  2
                )
              : _vm._e(),
            _vm.title
              ? _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm.roundModal
                    ? _c(
                        "button",
                        {
                          staticClass: "btn-close",
                          attrs: { type: "button", "data-dismiss": "modal" },
                          on: { click: _vm.onCancel }
                        },
                        [
                          _c("icon", {
                            attrs: {
                              src: "img/common/close-black.png",
                              width: "2.8rem",
                              height: "2.8rem",
                              cover: false
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "modal-body",
                style: { maxHeight: _vm.bodyMaxHeight },
                on: { click: _vm.onBody }
              },
              [_vm._t("body")],
              2
            ),
            _vm.useFooter
              ? _c(
                  "div",
                  {
                    staticClass: "modal-footer",
                    class: { single: !_vm.useCancel }
                  },
                  [
                    _vm.useCancel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary",
                            attrs: { type: "button", "data-dismiss": "modal" },
                            on: { click: _vm.onCancel }
                          },
                          [_vm._v(_vm._s(_vm.cancel))]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.onConfirm }
                      },
                      [_vm._v(_vm._s(_vm.confirm))]
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      ),
      _c("div", {
        staticClass: "modal-backdrop",
        on: {
          click: function() {
            return _vm.hideOnbackdrop ? _vm.hide() : null
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }