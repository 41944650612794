import type { IRouteMeta } from '@/flow/index.js'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Intro from './views/Intro.vue'
import NotFound from './views/NotFound.vue'
import { BASE_URL } from '@/config/env'

Vue.use(VueRouter)

const flowMeta = (meta: IRouteMeta): IRouteMeta => meta

const router = new VueRouter({
    mode: 'history',
    base: BASE_URL,
    routes: [
        {
            path: '/',
            name: 'intro',
            meta: flowMeta({
                gaTitle: '로그인',
                noGnb: true,
                noLnb: true,
                // transition: 'slide-right',
                backgroundColor: '#a059b6',
            }),
            component: Intro,
        },
        {
            path: '/join',
            name: 'join',
            meta: flowMeta({
                gaTitle: '회원가입 (종류 선택)',
                title: '회원가입',
                noLnb: true,
                gnbLeftBtn: 'close',
            }),
            component: () => import('./views/Join.vue').catch(() => NotFound),
        },
        {
            path: '/join/interests',
            name: 'joinInterests',
            meta: flowMeta({
                gaTitle: '회원가입 (관심분야)',
                title: '회원가입',
                noLnb: true,
                useFooterBox: true,
            }),
            component: () => import('./views/JoinInterests.vue').catch(() => NotFound),
        },
        {
            path: '/join/wedding-date',
            name: 'JoinWeddingDate',
            meta: flowMeta({
                gaTitle: '회원가입 (결혼날짜)',
                title: '회원가입',
                noLnb: true,
                useFooterBox: true,
            }),
            component: () => import('./views/JoinWeddingDate.vue').catch(() => NotFound),
        },
        {
            path: '/join/input',
            name: 'joinInput',
            meta: flowMeta({
                gaTitle: '회원가입 (정보 입력)',
                title: '회원가입',
                noLnb: true,
                useFooterBox: true,
            }),
            component: () => import('./views/JoinInput.vue').catch(() => NotFound),
        },
        {
            path: '/home',
            name: 'home',
            meta: flowMeta({
                gaTitle: '홈',
                noGnb: true,
                noLnb: true,
                useNavBottom: true,
            }),
            component: () => import('./views/Home.vue').catch(() => NotFound),
        },
        {
            path: '/logout',
            name: 'logout',
            meta: flowMeta({
                gaTitle: '로그아웃',
                noGnb: true,
                noLnb: true,
            }),
            component: () => import('./views/Logout.vue').catch(() => NotFound),
        },
        {
            path: '/deep-link',
            name: 'DeepLink',
            meta: flowMeta({
                gaTitle: '앱으로 가기',
                noGnb: true,
                noLnb: true,
            }),
            component: () => import('./views/DeepLink.vue').catch(() => NotFound),
        },

        /**********
         * Artist
         **********/
        {
            name: 'ArtistMyPaidOfferFilter',
            path: '/artist/my-paid-offer-filter',
            meta: flowMeta({
                title: '',
            }),
            component: () => import('./views/artist/MyPaidOfferFilter.vue').catch(() => NotFound),
        },
        {
            name: 'ArtistList',
            path: '/artist/list',
            meta: flowMeta({
                gaTitle: '전문가 리스트',
                title: '',
            }),
            component: () => import('./views/artist/List.vue').catch(() => NotFound),
        },
        {
            name: 'Celebrity',
            path: '/artist/celebrity',
            meta: flowMeta({
                gaTitle: '연예인 섭외',
                title: '',
            }),
            component: () => import('./views/artist/Celebrity.vue').catch(() => NotFound),
        },
        // 전문가 프로필
        {
            name: 'ArtistProfile',
            path: '/artist/profile/:artistidx',
            meta: flowMeta({
                gaTitle: '전문가 프로필',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/Profile.vue').catch(() => NotFound),
        },
        {
            name: 'WriteReview',
            path: '/artist/profile/:artistidx/write-review',
            meta: flowMeta({
                gaTitle: '전문가 프로필 리뷰 쓰기',
                title: '리뷰',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/WriteReview.vue').catch(() => NotFound),
        },
        {
            name: 'ArtistReviewList',
            path: '/artist/profile/:artistidx/review-list',
            meta: flowMeta({
                gaTitle: '전문가 프로필 리뷰 리스트',
                title: '리뷰',
            }),
            component: () => import('./views/artist/ReviewList.vue').catch(() => NotFound),
        },
        {
            name: 'ArtistSearch',
            path: '/artist/search',
            meta: flowMeta({
                gaTitle: '전문가 검색 (상세 검색)',
                title: '상세검색',
                useFooterBox: true,
                transition: 'slide-up',
            }),
            component: () => import('./views/artist/Search.vue').catch(() => NotFound),
        },
        {
            name: 'ArtistFavOffer',
            path: '/artist/fav',
            meta: flowMeta({
                gaTitle: '찜한 의뢰 리스트',
                title: '찜한 의뢰',
            }),
            component: () => import('./views/artist/FavOffer.vue').catch(() => NotFound),
        },
        {
            path: '/artist/fav/:offerUid',
            meta: flowMeta({
                gaTitle: '찜한 의뢰 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        {
            name: 'ArtistMyOffer',
            path: '/artist/my-offer',
            meta: flowMeta({
                gaTitle: '지원한 의뢰 리스트',
                title: 'MY 의뢰',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/artist/MyOffer.vue').catch(() => NotFound),
        },
        // 지원한 의뢰
        {
            path: '/artist/my-offer/:offerUid',
            meta: flowMeta({
                gaTitle: '지원한 의뢰 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        // 지원한 내용 보기
        {
            name: 'AEProfile',
            path: '/artist/my-offer/:offerUid/:artistidx',
            meta: flowMeta({
                gaTitle: '지원한 내용 보기',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/Profile.vue').catch(() => NotFound),
        },
        // 1:1 문의
        {
            name: 'ArtistDirectOffer',
            path: '/artist/direct-offer',
            meta: flowMeta({
                gaTitle: '받은 의뢰 리스트',
                title: '받은 의뢰',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/artist/DirectOffer.vue').catch(() => NotFound),
        },
        // 1:1 문의 상세
        {
            name: 'ArtistDirectOfferDetail',
            path: '/artist/direct-offer/:offerUid',
            meta: flowMeta({
                gaTitle: '받은 의뢰 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        /**********
         * 프로필 등록하기
         **********/
        {
            path: '/artist/my-profile',
            meta: flowMeta({
                gaTitle: '프로필 등록하기',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/MyProfile.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/event-list',
            meta: flowMeta({
                gaTitle: '전문가 이벤트 신청 목록',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/EventList.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/privacy',
            meta: flowMeta({
                gaTitle: '전문가 신분증 및 통장',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/Privacy.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/fee-information-and-agreement/1',
            meta: flowMeta({
                gaTitle: '프로필 등록 - 약관 동의 1',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/FeeInformationAndAgreement1.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/fee-information-and-agreement/2',
            meta: flowMeta({
                gaTitle: '프로필 등록 - 약관 동의 3',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/FeeInformationAndAgreement2.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/fee-information-and-agreement/3',
            meta: flowMeta({
                gaTitle: '프로필 등록 - 약관 동의 3',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/FeeInformationAndAgreement3.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/default',
            meta: flowMeta({
                gaTitle: '나의 프로필 > 기본 정보 (활동명, 성별)',
                gnbRightBtn: 'my-profile',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/Default.vue').catch(() => NotFound),
        },
        {
            path: '/artist/my-profile/detail-main',
            meta: flowMeta({
                gaTitle: '나의 프로필 > 전문 분야',
                gnbRightBtn: 'my-profile',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/my_profile/DetailMain.vue').catch(() => NotFound),
        },
        {
            name: 'ArtistProfilePreview',
            path: '/artist/my-profile/preview',
            meta: flowMeta({
                gaTitle: '나의 프로필 > 프로필 미리보기',
                title: '미리보기',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/Profile.vue').catch(() => NotFound),
        },
        {
            name: 'PaymentHistoryList',
            path: '/artist/payment',
            meta: flowMeta({
                gnbLeftBtn: 'none',
                gaTitle: '결제 내역',
                title: '결제 내역',
                useNavBottom: true,
            }),
            component: () => import('./views/artist/PaymentHistory.vue').catch(() => NotFound),
        },
        
        /**********
         * Offer
         **********/
        {
            path: '/offer/search',
            meta: flowMeta({
                gaTitle: '의뢰 검색 설정',
                title: '의뢰 검색',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/SearchOffer.vue').catch(() => NotFound),
        },
        {
            name: 'OfferSearchResult',
            path: '/offer/search/result',
            meta: flowMeta({
                gaTitle: '의뢰 검색 결과 리스트',
                title: '검색결과',
            }),
            component: () => import('./views/offer/SearchResult.vue').catch(() => NotFound),
        },
        {
            path: '/offer/search/result/:offerUid',
            meta: flowMeta({
                gaTitle: '의뢰 검색 결과 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        {
            name: 'ClientRegisteredOfferDetail',
            path: '/offer/registered/:offerUid',
            meta: flowMeta({
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        {
            path: '/offer/:offerUid',
            meta: flowMeta({
                gaTitle: '의뢰 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        // 지원하기
        {
            name: 'ApplyOffer',
            path: '/offer/:offerUid/apply',
            meta: flowMeta({
                gaTitle: '지원하기',
                useFooterBox: true,
                // gnbRightBtn: 'calc-fee',
            }),
            component: () => import('./views/offer/ApplyOffer2.vue').catch(() => NotFound),
        },
        // 수수료 계산
        {
            name: 'CalcFee',
            path: '/offer/calc/fees',
            meta: flowMeta({
                gaTitle: '수수료 계산',
                title: '수수료 계산',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/CalcFee').catch(() => NotFound),
        },
        // 의뢰하기
        {
            path: '/offer/post/preview',
            meta: flowMeta({
                gaTitle: '의뢰 미리보기',
                title: '미리보기',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        {
            path: '/offer/post/complete',
            meta: flowMeta({
                gaTitle: '의뢰 등록 완료',
                useFooterBox: true,
                gnbLeftBtn: 'none',
                gnbBg: 'transparent',
                title: '',
            }),
            component: () => import('./views/offer/post/Complete.vue').catch(() => NotFound),
        },
        {
            name: 'PostRelatedOffer',
            path: '/offer/post/related-offer',
            meta: flowMeta({
                useFooterBox: true,
                noGnb: true,
            }),
            component: () => import('./views/offer/post/RelatedOffer.vue').catch(() => NotFound),
        },
        {
            path: '/offer/post/:step',
            meta: flowMeta({
                gaTitle: '',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/post/PostOffer.vue').catch(() => NotFound),
            children: [
                {
                    path: 'intro',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 시작',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/Intro.vue').catch(() => NotFound),
                },
                {
                    path: 'genre',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 장르',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/Genre.vue').catch(() => NotFound),
                },
                {
                    path: 'event-type',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 이벤트 타입',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/EventType.vue').catch(() => NotFound),
                },
                {
                    path: 'product-type',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 판매할 상품',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/ProductType.vue').catch(() => NotFound),
                },
                {
                    path: 'play-location',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 장소',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/PlayLocation.vue').catch(() => NotFound),
                },
                {
                    path: 'event-date',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 행사 시작 날짜',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/EventDate.vue').catch(() => NotFound),
                },
                {
                    path: 'event-date/calendar',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 행사 시작 날짜(달력)',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/EventDateCalendar.vue').catch(() => NotFound),
                },
                {
                    path: 'event-time',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 행사 시작 시간',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/EventTime.vue').catch(() => NotFound),
                },
                {
                    path: 'play-time',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 행사 진행 시간',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/PlayTime.vue').catch(() => NotFound),
                },
                {
                    path: 'map',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 지도',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/Map.vue').catch(() => NotFound),
                },
                {
                    path: 'map/detail',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 지도 (상세)',
                        title: '위치 선택',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/MapDetail.vue').catch(() => NotFound),
                },
                {
                    path: 'price',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 금액',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/Price.vue').catch(() => NotFound),
                },
                {
                    path: 'sing',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 원하는 노래',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/Sing.vue').catch(() => NotFound),
                },
                {
                    path: 'mc-style',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 진행 스타일',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/McStyle.vue').catch(() => NotFound),
                },
                {
                    path: 'addedInfo',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 추가정보',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/AddInfo.vue').catch(() => NotFound),
                },
                {
                    path: 'detail',
                    meta: flowMeta({
                        gaTitle: '의뢰 등록 > 바라는 점',
                        useFooterBox: true,
                    }),
                    component: () => import('./views/offer/post/step/Detail.vue').catch(() => NotFound),
                },
            ],
        },
        // 커스텀 의뢰 (장소)
        {
            path: '/offer/contact/place/:artistidx',
            meta: flowMeta({
                gaTitle: '커스텀 의뢰 (장소)',
                title: '예약 문의하기',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/post/Place.vue').catch(() => NotFound),
        },
        // 커스텀 의뢰 (강연자)
        {
            path: '/offer/contact/lecture/:artistidx',
            meta: flowMeta({
                gaTitle: '커스텀 의뢰 (전문가)',
                title: '예약 문의하기',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/post/CustomLecture.vue').catch(() => NotFound),
        },
        // 커스텀 의뢰 (전문가)
        {
            path: '/offer/contact/custom/:artistidx',
            meta: flowMeta({
                gaTitle: '커스텀 의뢰 (전문가)',
                title: '예약 문의하기',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/post/Custom.vue').catch(() => NotFound),
        },
        // 커스텀 의뢰 (etc)
        {
            path: '/offer/contact/etc/:artistidx',
            meta: flowMeta({
                gaTitle: '커스텀 의뢰 (etc)',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/post/CustomEtc.vue').catch(() => NotFound),
        },
        // 의뢰 거절
        {
            name: 'RejectOffer',
            path: '/offer/reject/:offerUid',
            meta: flowMeta({
                gaTitle: '의뢰 거절',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/RejectOffer.vue').catch(() => NotFound),
        },

        /**********
         * Client
         **********/
        {
            name: 'ClientMainMenuGroupArtistList',
            path: '/client/main-menu-group/:mainMenuGroupUid/artist/list',
            meta: flowMeta({
                gaTitle: '메인 메뉴 관리',
            }),
            component: () => import('@/views/client/MainMenuGroupArtistList.vue').catch(() => NotFound),
        },
        {
            name: 'ClientProfile',
            path: '/client/profile',
            meta: flowMeta({
                gaTitle: '일반회원 프로필 수정',
                title: '프로필 수정',
                useFooterBox: true,
            }),
            component: () => import('./views/client/Profile.vue').catch(() => NotFound),
        },
        {
            name: 'ClientRegisteredOffer',
            path: '/client/registered-offer',
            meta: flowMeta({
                gnbLeftBtn: 'back',
                useFooterBox: false,
                useNavBottom: false,
            }),
            component: () => import('./views/client/RegisteredOffer.vue').catch(() => NotFound),
        },
        {
            name: 'ClientMyOffer',
            path: '/client/my-offer',
            meta: flowMeta({
                gaTitle: '나의 의뢰 리스트',
                title: 'MY',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/client/MyOffer.vue').catch(() => NotFound),
        },
        // 나의 의뢰
        {
            path: '/client/my-offer/:offerUid',
            meta: flowMeta({
                gaTitle: '나의 의뢰 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },
        // 지원한 전문가
        {
            name: 'Recruiter',
            path: '/client/my-offer/:offerUid/recruiter',
            meta: flowMeta({
                gaTitle: '나의 의뢰에 지원한 전문가 리스트',
                title: '지원한 전문가',
            }),
            component: () => import('./views/client/Recruiter.vue').catch(() => NotFound),
        },
        {
            name: 'RecruiterArtistProfile',
            path: '/client/my-offer/:offerUid/:artistidx',
            meta: flowMeta({
                gaTitle: '나의 의뢰에 지원한 전문가 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/artist/Profile.vue').catch(() => NotFound),
        },
        {
            path: '/client/fav',
            name: 'ClientFav',
            meta: flowMeta({
                gaTitle: '찜한 전문가 리스트',
                title: '찜',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/client/Fav.vue').catch(() => NotFound),
        },
        {
            path: '/client/fav/:offerUid',
            meta: flowMeta({
                gaTitle: '찜한 전문가 상세',
                title: 'none',
                useFooterBox: true,
            }),
            component: () => import('./views/offer/Offer.vue').catch(() => NotFound),
        },

        /**********
         * Payment
         **********/
        {
            path: '/payment/:offerUid/:artistidx',
            meta: flowMeta({
                gaTitle: '결제하기',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/payment/Payment.vue').catch(() => NotFound),
        },
        {
            name: 'PaymentResult',
            path: '/payment/:offerUid/:artistidx/result',
            meta: flowMeta({
                gaTitle: '결제 결과',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/payment/PaymentResult.vue').catch(() => NotFound),
        },
        {
            path: '/payment/:offerUid/:artistidx/cancel',
            meta: flowMeta({
                gaTitle: '결제 취소',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/payment/PaymentCancel.vue').catch(() => NotFound),
        },

        /**********
         * Membership
         **********/
        {
            path: '/membership',
            meta: flowMeta({
                gaTitle: '멤버십',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/payment/Membership.vue').catch(() => NotFound),
        },
        {
            path: '/membership/join',
            meta: flowMeta({
                gaTitle: '멤버십 가입',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/payment/MembershipJoin.vue').catch(() => NotFound),
        },
        {
            path: '/membership/information',
            meta: flowMeta({
                gaTitle: '멤버십 안내',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/payment/MembershipInformation.vue').catch(() => NotFound),
        },

        /**********
         * New Membership
         **********/
        {
            path: '/new-membership',
            meta: flowMeta({
                gaTitle: 'new 멤버십',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/new-membership/NewMembership.vue').catch(() => NotFound),
        },
        {
            path: '/new-membership/payment',
            meta: flowMeta({
                gaTitle: 'new 멤버십 결제',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/new-membership/NewMembershipPayment.vue').catch(() => NotFound),
        },

        /**********
         * Message
         **********/

        {
            path: '/communicate',
            meta: flowMeta({
                gaTitle: '메시지 & 문의내역 리스트',
                title: '채팅',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/message/Communicate.vue').catch(() => NotFound),
        },
        {
            path: '/message/list',
            meta: flowMeta({
                gaTitle: '메시지 리스트',
                title: '채팅',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/message/List.vue').catch(() => NotFound),
        },
        {
            name: 'MessageRoom',
            path: '/message/:offerUid/:targetUseridx',
            meta: flowMeta({
                gaTitle: '메시지 상세',
                title: '채팅',
                // gnbRightBtn: 'menu',
                useFooterBox: true,
                backgroundColor: '#f5f5f5',
            }),
            component: () => import('./views/message/Room.vue').catch(() => NotFound),
        },

        /**********
         * 설정
         **********/
        {
            path: '/setting',
            meta: flowMeta({
                gaTitle: '설정',
                title: '설정',
                useFooterBox: true,
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/setting/Setting.vue').catch(() => NotFound),
        },
        {
            path: '/setting/alarm',
            meta: flowMeta({
                gaTitle: '설정 > 알림',
                title: '알림',
                useFooterBox: true,
            }),
            component: () => import('./views/setting/Alarm.vue').catch(() => NotFound),
        },
        {
            path: '/setting/alarm-locale',
            meta: flowMeta({
                gaTitle: '설정 > 알림(지역)',
                title: '알림',
                useFooterBox: true,
            }),
            component: () => import('./views/setting/AlarmLocale.vue').catch(() => NotFound),
        },

        /**********
         * 프로필
         **********/
         {
            path: '/profile',
            meta: flowMeta({
                gaTitle: '프로필',
                title: 'MY 프로필',
                gnbBg: 'transparent',
                useFooterBox: true,
                gnbRightBtn: 'profile-main',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/profile/ProfileList.vue').catch(() => NotFound),
        },

        /**********
         * 기타
         **********/
        {
            name: 'Qna',
            path: '/etc/qna',
            meta: flowMeta({
                gaTitle: '문의 내역',
                gnbLeftBtn: 'none',
                useNavBottom: true,
            }),
            component: () => import('./views/etc/Qna.vue').catch(() => NotFound),
        },
        {
            name: 'QnaDetail',
            path: '/etc/qna/detail',
            meta: flowMeta({
                gaTitle: '문의 내용',
                useFooterBox: true,
            }),
            component: () => import('./views/etc/QnaDetail.vue').catch(() => NotFound),
        },
        {
            path: '/etc/contact',
            meta: flowMeta({
                gaTitle: '문의하기',
                title: '문의하기',
                useFooterBox: true,
            }),
            component: () => import('./views/etc/Contact.vue').catch(() => NotFound),
        },
        {
            name: 'ContactToArtist',
            path: '/etc/contact-to-artist/:offerUid',
            meta: flowMeta({
                gaTitle: '전문가에게 문의하기',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/etc/ContactToArtist.vue').catch(() => NotFound),
        },
        {
            name: 'Consulting',
            path: '/etc/consulting',
            meta: flowMeta({
                gaTitle: '통화 상담',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/etc/Consulting.vue').catch(() => NotFound),
        },
        /** @deprecated */
        {
            name: 'Celebrity',
            path: '/etc/celebrity',
            meta: flowMeta({
                gaTitle: '연예인 섭외하기',
                title: '',
                useFooterBox: true,
            }),
            component: () => import('./views/etc/Celebrity.vue').catch(() => NotFound),
        },
        /** @deprecated */
        {
            name: 'EventConsulting',
            path: '/etc/event-consulting',
            meta: flowMeta({
                gaTitle: '이벤트 상담',
                title: '',
            }),
            component: () => import('./views/etc/EventConsulting.vue').catch(() => NotFound),
        },
        {
            path: '/etc/privacy',
            name: 'privacy',
            meta: flowMeta({
                gaTitle: '개인정보취급방침',
                title: '개인정보취급방침',
            }),
            component: () => import('./views/etc/Privacy.vue').catch(() => NotFound),
        },
        {
            path: '/etc/refund-policy',
            name: 'refund-policy',
            meta: flowMeta({
                gaTitle: '환불 및 취소 규정',
                title: '환불 및 취소 규정',
            }),
            component: () => import('./views/etc/RefundPolicy.vue').catch(() => NotFound),
        },
        {
            path: '/etc/inapposite-policy',
            name: 'inapposite-policy',
            meta: flowMeta({
                gaTitle: '부정거래/행위 정의 및 처리 규정',
                title: '부정거래/행위 정의 및 처리 규정',
            }),
            component: () => import('./views/etc/InappositePolicy.vue').catch(() => NotFound),
        },
        {
            path: '/etc/terms',
            name: 'terms',
            meta: flowMeta({
                gaTitle: '서비스 이용약관',
                title: '서비스 이용약관',
            }),
            component: () => import('./views/etc/Terms.vue').catch(() => NotFound),
        },
        {
            path: '/etc/information',
            name: 'information',
            meta: flowMeta({
                gaTitle: '이용 안내',
                title: '',
            }),
            component: () => import('./views/etc/Information.vue').catch(() => NotFound),
        },
        {
            path: '/etc/fee-information',
            name: 'FeeInformation',
            meta: flowMeta({
                gaTitle: '수수료 안내',
                title: '수수료 안내',
            }),
            component: () => import('./views/etc/FeeInformation.vue').catch(() => NotFound),
        },
        {
            path: '/etc/no-show',
            meta: flowMeta({
                useFooterBox: true,
            }),
            component: () => import('./views/etc/NoShow.vue').catch(() => NotFound),
        },
        {
            path: '*',
            name: 'NotFound',
            meta: flowMeta({}),
            component: NotFound,
        },
    ],
})

router.onError(() => {
    if (confirm('업데이트로 인하여 앱의 재실행이 필요합니다')) {
        window.location.reload(true)
    }
})

const originalPush = router.push

router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject)
    }

    return originalPush.call(this, location).catch(err => {
        if (err) {
            // 진짜 에러가 있을 때만 reject
            return Promise.reject(err)
        }

        return Promise.resolve(false)
    })
}

export default router
