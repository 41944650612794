import { moment } from '@/plugins/moment'

const Console = (type = 'log', ...msgs) => {
    if (process.env.NODE_ENV === 'production') return

    console[type](moment().format('HH:mm:ss'), '|', ...msgs)
}

const log = (...msgs) => {
    Console('log', ...msgs)
}

Object.defineProperty(log, 'error', {
    get() {
        return (...msgs) => {
            Console('error', ...msgs)
        }
    },
    set(...msgs) {
        Console('error', ...msgs)
    },
})

Object.defineProperty(log, 'ln', {
    get() {
        return (...msgs) => {
            Console('log', ...msgs)
        }
    },
    set(...msgs) {
        Console('log', ...msgs)
    },
})

export default log
