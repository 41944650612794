<template>
  <div
    class="img-uploader"
    :class="{
      show: isShow,
    }"
  >
    <input type="file" ref="file" accept="image/*" @change="fileChange">
    <img ref="img">
    <div class="img-uploader-btns">
      <button type="button" @click="closeUploader">취소</button>
      <button type="button" @click="imgRotate">회전</button>
      <button type="button" @click="imgCrop">자르기</button>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.min.css'

export default {
  data() {
    return {
      isShow: false,
      cropper: null,
      cropperOption: {},
      getCroppedCanvasOption: {},
      currentUid: null,
    }
  },
  mounted() {
    this.$on('request', (uid, option = null) => {
      this.currentUid = uid

      if (option && typeof option === 'object') {
        if (option.cropperOption) this.cropperOption = option.cropperOption
        if (option.getCroppedCanvasOption) this.getCroppedCanvasOption = option.getCroppedCanvasOption
      }

      this.request()
    })
  },
  methods: {
    request() {
      this.$refs.file.value = ''
      this.$refs.file.click()
    },
    response(success, url) {
      this.$emit('response', this.currentUid, success, url)
      this.closeUploader()
    },
    setCropper() {
      this.isShow = true
      this.cropper = new Cropper(this.$refs.img, {
        viewMode: 1,
        initialAspectRatio: 1,
        aspectRatio: 3 / 2,
        background: false,
        guides: false,
        highlight: false,
        dragMode: 'move',
        minCropBoxWidth: 300,
        minCropBoxHeight: 300,
        cropBoxMovable: false,
        cropBoxResizable: false,
        ...this.cropperOption,
      })
    },
    closeUploader() {
      this.isShow = false
      this.currentUid = null
      this.cropperOption = {}
      this.cropper.destroy()
    },
    imgRotate() {
      if (this.cropper) this.cropper.rotate(90)
    },
    imgCrop() {
      if (this.cropper) {
        const canvas = this.cropper.getCroppedCanvas({
          minWidth: 800,
          minHeight: 800,
          maxWidth: 2160,
          maxHeight: 2160,
          fillColor: '#fff',
          imageSmoothingEnabled: true,
          imageSmoothingQuality: 'high',
          ...this.getCroppedCanvasOption,
        })

        if (canvas) {
          canvas.toBlob(blob => {
            const req = {
              method: 'post',
              url: `/etc/upload`,
              data: new FormData(),
            }

            req.data.set('uploadFIle', blob, `${Date.now()}.jpeg`, '1')

            this.$http(req)
              .then(({ data }) => {
                log(...Object.values(req), data)

                const resultData = this.$lib.resultCheck(data.resultData)

                this.response(resultData?.success || false, data?.response?.uploadUrl || '')
              })
              .catch(log.error)
          }, 'image/jpeg',1)
        }
      }
    },
    fileChange() {
      let files = this.$refs.file.files
      let reader = new FileReader()

      this.$refs.img.src = ''
      if (files[0]) reader.readAsDataURL(files[0])
      reader.onloadend = () => {
        this.$refs.img.src = reader.result
        this.setCropper()
      }
    },
  },
}
</script>

<style lang="scss">
.img-uploader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1051;
  background-color: #000;

  input {
    display: none;
  }
  > img {
    display: none;
    max-width: 100%
  }
  .img-uploader-btns {
    position: absolute;
    bottom: 30px;
    right: 30px;
    left: 30px;
    display: block;
    height: 40px;
    font-size: 30px;
    text-align: right;
    button {
      background: transparent;
      border: 0;
      color: #fff;
      margin-left: 30px;
    }
  }

  &.show {
    display: block;
  }
}
</style>
