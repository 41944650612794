var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ui-footer-box",
      class: {
        "ui-footer-box--static": _vm.isStatic
      }
    },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "ui-footer-btns",
          class: {
            single: _vm.single && !_vm.triple,
            triple: _vm.triple,
            rate32: _vm.rate32,
            rate37: _vm.rate37
          }
        },
        [
          !_vm.single || _vm.triple
            ? _c(
                "button",
                {
                  attrs: { type: "button", disabled: _vm.btnDisabled },
                  on: {
                    click: function() {
                      return _vm.btnCb ? _vm.btnCb() : {}
                    }
                  }
                },
                [
                  _vm._t("btnLeftSlot"),
                  _vm._v(" " + _vm._s(_vm.btnText) + " "),
                  _vm._t("btnRightSlot")
                ],
                2
              )
            : _vm._e(),
          _vm.triple
            ? _c(
                "button",
                {
                  attrs: { type: "button", disabled: _vm.tripleBtnDisabled },
                  on: {
                    click: function() {
                      return _vm.tripleBtnCb ? _vm.tripleBtnCb() : {}
                    }
                  }
                },
                [
                  _vm._t("tripleBtnLeftSlot"),
                  _vm._v(" " + _vm._s(_vm.tripleBtnText) + " "),
                  _vm._t("tripleBtnRightSlot")
                ],
                2
              )
            : _vm._e(),
          _c(
            "button",
            {
              attrs: { type: "submit", disabled: _vm.submitDisabled },
              on: {
                click: function() {
                  return _vm.submitCb ? _vm.submitCb() : {}
                }
              }
            },
            [
              _vm._t("submitLeftSlot"),
              _vm._v(" " + _vm._s(_vm.submitText) + " "),
              _vm._t("submitRightSlot"),
              _vm.submitText === "다음"
                ? _c("icon", {
                    style: {
                      display: "inline-block",
                      marginRight: "-2.6rem",
                      marginLeft: "1.6rem"
                    },
                    attrs: {
                      src: "img/common/next-white.png",
                      width: "1rem",
                      height: "2rem",
                      cover: false
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm.draggAble
        ? [
            _c("transition", { attrs: { name: "fade", duration: 300 } }, [
              _vm.modalShow
                ? _c(
                    "div",
                    { staticClass: "footer-modal draggable" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "footer-modal-btn",
                          on: { touchmove: _vm.showFullPopup }
                        },
                        [_c("div", { staticClass: "drag-control" })]
                      ),
                      _vm.modalSlots
                        ? _vm._t("modalBody")
                        : [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.modalTitle))
                            ]),
                            _c("p", { staticClass: "body" }, [
                              _vm._v(_vm._s(_vm.modalBody))
                            ])
                          ]
                    ],
                    2
                  )
                : _vm._e()
            ]),
            _c("transition", { attrs: { name: "fade", duration: 300 } }, [
              _vm.modalShow
                ? _c("div", {
                    staticClass: "footer-modal footer-modal-bg",
                    on: {
                      click: function() {
                        return _vm.modalCb ? _vm.modalCb() : {}
                      }
                    }
                  })
                : _vm._e()
            ])
          ]
        : [
            _c("transition", { attrs: { name: "fade", duration: 300 } }, [
              _vm.modalShow
                ? _c(
                    "div",
                    { staticClass: "footer-modal" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "footer-modal-btn",
                          on: {
                            click: function() {
                              return _vm.modalCb ? _vm.modalCb() : {}
                            }
                          }
                        },
                        [
                          _c("icon", {
                            attrs: {
                              src: "img/common/iconfinder-down.png",
                              width: "23px",
                              height: "13px",
                              cover: false
                            }
                          })
                        ],
                        1
                      ),
                      _vm.modalSlots
                        ? _vm._t("modalBody")
                        : [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.modalTitle))
                            ]),
                            _c("p", { staticClass: "body" }, [
                              _vm._v(_vm._s(_vm.modalBody))
                            ])
                          ]
                    ],
                    2
                  )
                : _vm._e()
            ]),
            _c("transition", { attrs: { name: "fade", duration: 300 } }, [
              _vm.modalShow
                ? _c("div", { staticClass: "footer-modal footer-modal-bg" })
                : _vm._e()
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }