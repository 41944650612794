<template>
  <transition name="slide-up-down">
    <div
      v-if="isShow"
      class="page-modal"
      :class="{
        'page-modal--hideFooter': hideFooter,
      }"
    >
      <div class="page-modal-navi" v-if="useNavi">
        <div class="d-inline-block ui-btn" @click="() => hide()">
          <template v-if="closeIcon === 'back'">
            <icon
              :src="`img/common/back-arrow-button.png`"
              width="2.5rem"
              height="2.5rem"
              cursor="pointer"
              style="margin: 0.25rem"
              :cover="false"
            />
          </template>
          <template v-else>
            <icon
              src="img/lnb/x.png"
              width="23px"
              height="30px"
              cursor="pointer"
              :cover="false"
            />
          </template>
        </div>
      </div>
      <div class="page-modal-scroll">
        <div class="page-modal-header" v-if="usingHeader">
          <h3 v-if="title">{{title}}</h3>
          <p v-if="subtitle">{{subtitle}}</p>
        </div>

        <div v-if="useBorderLine" class="ui-border-line" style="height: 10px"></div>

        <slot name="body">
          <div class="page-modal-body"></div>
        </slot>
      </div>
      <slot />
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/common/Icon'
export default {
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    useBorderLine: {
      type: Boolean,
      default: false,
    },
    hideCb: {
      type: Function,
    },
    useNavi: {
      type: Boolean,
      default: true,
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    closeIcon: {
      type: String,
      default: 'x',
    },
  },
  data() {
    return {
      isShow: false
    }
  },
  computed: {
    uid() {
      return this._uid.toString()
    },
    usingHeader() {
      return !!(this.title || this.subtitle)
    }
  },
  watch: {
    '$route.hash'(hash) {
      if (hash !== '#' + this.uid) {
        this.hide()
      }
    },
  },
  methods: {
    show() {
      this.isShow = true
      let container = document.querySelector('.container');
      container.style.overflow = 'hidden';  
      this.$router.push({
        hash: this.uid,
        query: {
          ...this.$route.query,
        },
      })
    },
    hide() {
      this.isShow = false
      let container = document.querySelector('.container');
    
      typeof this.hideCb === 'function' && this.hideCb()

      if (this.$route.hash === '#' + this.uid) {
        this.$router.back()
      } else {
        if(!this.$route.hash) container.style.overflow = 'auto';
        else container.style.overflow = 'hidden';
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.page-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #fff;

  h3,
  h4 {
    white-space: pre-line;
  }
  p {
    font-size: 20px;
    color: #a6a6a6;
    white-space: pre-line;

    button {
      background-color: transparent;
      border: 0;
      margin: 0;
      padding: 0;
      display: inline;
      color: $color-deepLavender;
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  .page-modal-navi {
    padding: 3rem;
    position: relative;
    line-height: 3rem;
    font-size: 2.4rem;
    text-align: center;
    display: flex;
  }
  .page-modal-scroll {
    overflow-y: auto;
    padding: 0 3rem;
    height: calc(100vh - 90px);

    .page-modal-header {
      margin-bottom: 3rem;
    }
    .page-modal-body {
      margin: 3rem 0;
      h4 {
        margin: 0 !important;
        padding: 1rem 0;
        line-height: 1.5 !important;
      }
    }
  }

  &.page-modal--hideFooter {
    .page-modal-scroll {
      height: 100vh;
    }
  }

  &.guide-profile {
    .page-sub-title {
      font-size: 2rem;
      line-height: 2.6rem;
      color: #666;
    }

    .page-modal-header {
      margin-bottom: 0;
    }

    .page-modal-body {
      margin: 0 0 30px 0;
      .guideLetter {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.6rem;
        margin-bottom: 6rem;
        padding: 1.2rem 2rem;
        border-radius: 0.8rem;
        background-color: rgba(171, 84, 187, 0.1);
        font-size: 1.6rem;
        line-height: 2.1rem;
        color: #AB54BB;
      }

      .profile-sec-tit {
        color: black;
        font-size: 2.5rem;
        font-weight: 600;
        span {
          color: $color-deepLavender;
          &.line-badge {
            padding: 0.3rem 1.2rem;
            border: 1px solid $color-deepLavender;
            border-radius: 2.8rem;
            font-size: 1.6rem;
            vertical-align: middle;
          }
        }
      }

      .profile-registration-step-wrap {
        margin: 2.4rem 0 6rem;
        padding: 4rem 3rem;
        border-radius: 0.6rem;
        background-color: #F7F8FE;
        .profile-registration-step {
          padding-left: 0;
          padding-bottom: 3rem;
          border-bottom: 1px solid #D9D9D9;
          li {
            display: flex;
            align-items: center;
            gap: 2.4rem;
            img {
              width: 10rem;
              height: 10rem;
            }
            .step-tit {
              font-size: 2.4rem;
              color: #333;
              font-weight: 600;
              line-height: 3.4rem;
            }
            .step-txt {
              margin-top: 0.2rem;
              color: #666;
              font-size: 2rem;
              letter-spacing: -0.05rem;
            }
            .guide-btn {
              margin-top: 1.4rem;
              font-size: 2rem;
              line-height: 2.8rem;
            }
            & ~ li {
              position: relative;
              margin-top: 3.8rem;

              &::after {
                position: absolute;
                top: -2.4rem;
                left: 4.1rem;
                width: 1.6rem;
                height: 1.2rem;
                background: url(/img/my-profile/profile-arrow@2x.png) no-repeat 0 0/ contain;
                content: '';
              }
            }
          }
        }
        .notice-area {
          margin-top: 3rem;
          font-size: 2rem;
          .notice-tit {
            margin-bottom: 1.4rem;
            color: #333;
          }
          .notice-list {
            li {
              margin-left: 2rem;
              color: #666;
              list-style: disc;
              & ~ li {
                margin-top: 0.8rem;
              }
            }
          }
        }
      }

      .tip-list {
        margin-top: 2.4rem;
        padding: 3rem;
        border-radius: 1.2rem;
        background-color: #f5f5f5;
        li {
          margin-left: 2rem;
          font-size: 2rem;
          color: #666;
          list-style: disc;
          & ~ li {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }
  &.close-right {
    .page-modal-navi {
      justify-content: flex-end;
    }
  }
}
</style>
