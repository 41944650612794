import { validMultipleGenre } from '@/lib/globalLib'

export const location = {
  '0': '전국',
  '11': '서울',
  '21': '부산',
  '23': '인천',
  '22': '대구',
  '24': '광주',
  '25': '대전',
  '26': '울산',
  '29': '세종',
  '31': '경기',
  '32': '강원',
  '33': '충북',
  '34': '충남',
  '37': '경북',
  '38': '경남',
  '35': '전북',
  '36': '전남',
  '39': '제주',
}

export const age = {
  '0': '무관',
  '10': '10대 미만',
  '20': '10대',
  '30': '20대',
  '40': '30대',
  '50': '40대',
  '60': '50대',
  '70': '60대 이상',
}

export const eventType = [
  {
    category: '기업, 단체',
    eventTypes: [
      '기업행사',
      '관공서 행사',
      '온라인 행사',
      '홈쇼핑/라이브커머스',
      '지역/학교축제',
      '컨퍼런스/세미나',
      '홍보/전시행사',
      '모임행사',
      '정기 공연/연주',
      '준공식/개소식',
      '체육대회',
      '모델',
    ],
  },
  {
    category: '개인',
    eventTypes: [
      '주례없는 결혼식',
      '결혼식',
      '회갑/칠순',
      '돌잔치',
      '레슨/클래스',
    ],
  },
]

// 이거 안쓰는 중
export const artistSortAll = {
  listNum: '추천순',
  regDate: '등록순',
  lowPrice: '낮은 가격순',
  highPrice: '높은 가격순',
  point: '평점순',
  review: '리뷰수순',
  order: '섭외순',
  proOrder: '최근 지원일순',
  action: '활동 지원순',
  view: '조회수 높은 순',
}
export const artistSort = {
  listNum: '추천순',
  // action: '활동 많은 순',
  regDate: '최근 등록 순',
  order: '결제 많은 순',
  review: '리뷰 많은 순',
}

export const career = {
  0: '입력안함',
  1: '경력없음',
  2: '1년 미만',
  3: '1년',
  4: '2년',
  5: '3년',
  6: '4년',
  7: '5년',
  8: '6년',
  9: '7년',
  10: '8년',
  11: '9년',
  12: '10년',
  13: '11년 이상',
}

export const teamType = {
  a: '솔로',
  b: '듀엣(혼성)',
  c: '듀엣(동성)',
  d: '그룹(3인 이상)',
}

export const mcStyle = {
  0: '품격있는',
  1: '차분한',
  2: '밝고 경쾌한',
  3: '센스 있는',
  4: '유머 넘치는',
  5: '감동 있는',
}

export const gender = {
  f: '여성',
  m: '남성',
  g: '혼성',
}

export const bodyTopsize = {
  xs: 'XS(44)',
  s: 'S(55)',
  m: 'M(66)',
  l: 'L',
  xl: 'XL',
  xxl: 'XXL',
}

export const playLocation = [
  '실내 촬영',
  '실외 촬영',
  '실내+실외 촬영',
]

export const genreByEventType = [
  {
    condition: eventType => /(결혼식|주례없는 결혼식)/.test(eventType),
    genres: [
      '노래',
      'MC',
      '댄스',
      '연주',
      '퍼포먼스',
    ],
    adminMemos: [
      '뮤지컬웨딩 39만원~',
      '웨딩연주 32만원~',
      '웨딩댄스 20만원~',
      '주례 10%할인',
    ],
  },
  {
    condition: eventType => /(기업행사|관공서 행사|온라인 행사|컨퍼런스\/세미나|홈쇼핑\/라이브커머스|지역\/학교축제|홍보\/전시행사|준공식\/개소식|모임행사|체육대회)/.test(eventType),
    genres: [
      '노래',
      'MC',
      '쇼호스트',
      '댄스',
      '연주',
      'DJ',
      '퍼포먼스',
    ],
    adminMemos: [
      '행사 대행',
    ],
  },
  {
    condition: eventType => /(모델)/.test(eventType),
    genres: [
      '피팅모델',
      '화보, 잡지모델',
      '방송, 영상모델',
    ],
    adminMemos: [],
  },
  {
    condition: eventType => /(홈쇼핑\/라이브커머스)/.test(eventType),
    genres: [
      '쇼호스트',
      '노래',
      '댄스',
      'DJ',
      '연주',
      '퍼포먼스',
      // '기타',
    ],
    adminMemos: [],
  },
  {
    condition: () => true,
    genres: [
      '노래',
      'MC',
      '댄스',
      'DJ',
      '연주',
      '퍼포먼스',
      // '기타',
    ],
    adminMemos: [],
  },
]

export const showHost = [
  '식품',
  '생활용품',
  '디지털/가전',
  '패션의류',
  '뷰티',
  '스포츠/레저',
  '패션잡화',
  '가구/인테리어',
  '출산/유아',
  '교육/교구',
]

/**
 * 전문가 프로필 분야별 상세 검색
 */
export const genreRequirementKeys = {
  location: '지역',
  subGenre: '세부 전문 분야', // api 없음
  career: '경력', // careerStart, careerEnd
  teamType: '팀 종류', //  (a:솔로, b:듀엣(혼성), c:듀엣(동성), d:그룹(3인 이상))
  mcStyle: '진행 스타일', // api 없음
  gender: '성별', // (남=m,여=f)
  age: '나이대', // ageStart, ageEnd
  bodyHeight: '키',
  bodyWeight: '몸무게',
  bodyTopsize: '상의 사이즈', // xs,s,m,l,xl,xxl
  bodyBottomsize: '하의 사이즈',
  bodyShoesize: '신발사이즈',
  program: '방송 출연자만 보기', // y
  artistCheck: '신원인증 전문가만 보기' // y
}

/**
 * 전문가 프로필 분야별 상세 검색
 */
export const genreRequirement = {
  '노래': [
    'location',
    'subGenre',
    'career',
    'teamType',
    'gender',
    'age',
    'program',
    'artistCheck',
  ],
  'MC': [
    'location',
    'subGenre',
    'career',
    'mcStyle',
    'gender',
    'age',
    'program',
    'artistCheck',
    'genreType',
  ],
  '모델': [
    'location',
    'subGenre',
    'career',
    'gender',
    'age',
    'bodyHeight',
    'bodyWeight',
    'bodyTopsize',
    'bodyBottomsize',
    'bodyShoesize',
    'program',
    'artistCheck',
  ],
  '쇼호스트': [
    'location',
    'career',
    'gender',
    'age',
  ],
  '행사용품': [
    'location',
    'category',
    'subCategory',
  ],
  '행사패키지': [
    'location',
    'subGenre',
    'price',
  ],
  '댄스': [
    'location',
    'subGenre',
    'career',
    'gender',
    'age',
    'program',
    'artistCheck',
  ],
  '연주': [
    'location',
    'subGenre',
    'career',
    'gender',
    'age',
    'program',
    'artistCheck',
  ],
  '강연자': [
    'location',
    'subGenre',
    'gender',
    'program',
    'artistCheck',
  ],
  '퍼포먼스': [
    'location',
    'subGenre',
    'career',
    'age',
    'gender',
    'program',
    'artistCheck',
  ],
  default: [
    'location',
    'career',
    'gender',
    'age',
    'program',
    'artistCheck',
  ],
}

const postOfferStepByGenre = {
  '노래': [
    'intro',
    'event-type',
    'genre',
    'event-date',
    'event-time',
    'price',
    'play-time',
    'map',
    'sing',
    'addedInfo',
    'detail',
  ],
  'MC': [
    'intro',
    'event-type',
    'genre',
    'event-date',
    'event-time',
    'price',
    'play-time',
    'map',
    'mc-style',
    'addedInfo',
    'detail',
  ],
  '모델': [
    'intro',
    'event-type',
    'genre',
    'event-date',
    'event-time',
    'price',
    'play-location',
    'play-time',
    'map',
    'addedInfo',
    'detail',
  ],
  default: [
    'intro',
    'event-type',
    'genre',
    'event-date',
    'event-time',
    'price',
    'play-time',
    'map',
    'addedInfo',
    'detail',
  ],
}

/**
 * 의뢰하기 step
 */
export const getPostOfferStep = ({ genre, eventType }) => {
  let nextStep: string[]

  if (validMultipleGenre(genre, '노래')) {
    nextStep = [...postOfferStepByGenre['노래']]
  } else if (validMultipleGenre(genre, 'MC')) {
    nextStep = [...postOfferStepByGenre['MC']]
  } else if (validMultipleGenre(genre, '모델')) {
    nextStep = [...postOfferStepByGenre['모델']]
  } else {
    nextStep = [...postOfferStepByGenre.default]
  }

  if (eventType === '홈쇼핑/라이브커머스') {
    const index = nextStep.indexOf('genre')
    nextStep.splice(index + 1, 0, 'product-type')
  }

  return nextStep
}

export const guestPages = [
  // 'intro',
  /^\/$/,
  // 'join',
  /^\/join/,
  // 'joinInput',
  /^\/joinInput/,
  // 'privacy',
  /^\/etc\/privacy/,
  // 'refund-policy',
  /^\/etc\/refund-policy/,
  // 'terms',
  /^\/etc\/terms/,
  // 'inapposite-policy',
  /^\/etc\/inapposite-policy/,
  // 'logout',
  /^\/logout/,
  // 'ArtistProfile',
  /^\/artist\/profile\/[0-9]+/,
  /^\/artist\/profile\/[0-9]+\/write-review/,
  // 'offer',
  /^\/offer\/[0-9]+/,
  //'임시',
  /^\/offer\/post\/related-offer/,
];

export const cusFlag = [
  '', // 0: null
  '장소',       // 1: place
  '전문가',     // 2: custom
  '일반전문가',  // 3: normal
  '일반전문가2', // 4: normal2
  'etc',      // 5: etc
  'etc2',     // 6: etc2
  'etc3',     // 7: etc3
  '강연자' // 8: 강연자
]

export const paymentRelatedDates = {
  canCancel(remainDays) {
    return remainDays > 6
  },
  penaltyPercent(remainDays) {
    const days = [14, 7]

    for (let day of days) {
      if (remainDays >= day) {
        switch (day) {
          case 14: return 0
          case 7: return 50
        }
        break
      }
    }

    return 100
  },
}

export const fees = {
  hbFeePer: 15,
  hbFeePerForUserReferrer: 20,
}

export const productTypes = [
  '식품',
  '생활용품',
  '디지털/가전',
  '패션의류',
  '뷰티',
  '스포츠/레저',
  '패션잡화',
  '가구/인테리어',
  '출산/유아',
  '교육/교구',
]

export const genreTypes = [
  '결혼식 사회자',
  '아나운서',
  '리포터&캐스터',
  '쇼호스트',
  '행사MC',
  '개그맨',
  '성우',
  '강사&강연',
]

export const addWorkName = {
  trip: '출장비',
  install: '설치비',
  delivery: '배달비',
}