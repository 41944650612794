<template>
  <div class="ui-progress-bar">
    <div
      class="filled-bar"
      :style="{
        backgroundColor: color,
        width: percent + '%',
      }"
      ></div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '#2bc4cc',
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-progress-bar {
  position: fixed;
  top: 90px;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100vw;
  max-width: 768px;
  height: 4px;
  margin: 0 auto;
  transition: 0.3s;

  .filled-bar {
    background-color: #2bc4cc;
    max-width: 100%;
    height: inherit;
    transition: 0.3s;
  }
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  .ui-progress-bar {
    opacity: 0;
  }
}
</style>
