var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container not-found" }, [
    _c("h1", [_vm._v("Please reload :(")]),
    _c("h2", [_vm._v("업데이트로 인하여 앱의 재실행이 필요합니다")]),
    _c(
      "button",
      {
        staticClass: "btn btn-outline-danger",
        on: {
          click: function() {
            return _vm.reload()
          }
        }
      },
      [_vm._v("Reload")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }