<template>
  <div
    class="ui-footer-box"
    :class="{
      'ui-footer-box--static': isStatic,
    }"
  >
    <slot />

    <div class="ui-footer-btns" :class="{ single: single && !triple, triple, rate32, rate37 }">
      <!-- btn -->
      <button
        v-if="!single || triple"
        type="button"
        @click="() => btnCb ? btnCb() : {}"
        :disabled="btnDisabled"
      >
        <slot name="btnLeftSlot"/>
        {{btnText}}
        <slot name="btnRightSlot"/>
      </button>
      <!-- tripleBtn -->
      <button
        v-if="triple"
        type="button"
        @click="() => tripleBtnCb ? tripleBtnCb() : {}"
        :disabled="tripleBtnDisabled"
      >
        <slot name="tripleBtnLeftSlot"/>
        {{tripleBtnText}}
        <slot name="tripleBtnRightSlot"/>
      </button>
      <!-- submit -->
      <button type="submit" @click="() => submitCb ? submitCb() : {}" :disabled="submitDisabled">
        <slot name="submitLeftSlot"/>
        {{submitText}}
        <slot name="submitRightSlot"/>
        <icon v-if="submitText === '다음'" src="img/common/next-white.png" width="1rem" height="2rem" :cover="false" :style="{
          display: 'inline-block',
          marginRight: '-2.6rem',
          marginLeft: '1.6rem',
        }"/>
      </button>
    </div>
    
    <template v-if="draggAble">
      <transition name="fade" :duration="300">
        <div v-if="modalShow" class="footer-modal draggable">
          <div class="footer-modal-btn" @touchmove="showFullPopup">
            <div class="drag-control"></div>
          </div>
          <slot v-if="modalSlots" name="modalBody"></slot>
          <template v-else>
            <p class="title">{{modalTitle}}</p>
            <p class="body">{{modalBody}}</p>
          </template>
        </div>
      </transition>
      <transition name="fade" :duration="300">
        <div v-if="modalShow" class="footer-modal footer-modal-bg" @click="() => modalCb ? modalCb() : {}"></div>
      </transition>
    </template>
    <template v-else>
      <transition name="fade" :duration="300">
        <div v-if="modalShow" class="footer-modal">
          <div class="footer-modal-btn" @click="() => modalCb ? modalCb() : {}">
            <icon src="img/common/iconfinder-down.png" width="23px" height="13px" :cover="false"/>
          </div>
          <slot v-if="modalSlots" name="modalBody"></slot>
          <template v-else>
            <p class="title">{{modalTitle}}</p>
            <p class="body">{{modalBody}}</p>
          </template>
        </div>
      </transition>
      <transition name="fade" :duration="300">
        <div v-if="modalShow" class="footer-modal footer-modal-bg"></div>
      </transition>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    single: {
      type: Boolean,
      default: true,
    },
    triple: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: '다음',
    },
    submitCb: {
      type: Function,
      default: null,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      default: '미리보기',
    },
    btnCb: {
      type: Function,
      default: null,
    },
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    tripleBtnText: {
      type: String,
      default: '',
    },
    tripleBtnCb: {
      type: Function,
      default: null,
    },
    tripleBtnDisabled: {
      type: Boolean,
      default: false,
    },
    rate32: {
      type: Boolean,
      default: false,
    },
    rate37: {
      type: Boolean,
      default: false,
    },
    modalShow: {
      type: Boolean,
    },
    modalTitle: {
      type: String,
    },
    modalBody: {
      type: String,
    },
    modalCb: {
      type: Function,
    },
    modalFullCb: {
      type: Function,
    },
    isStatic: {
      type: Boolean,
      default: false,
    },
    draggAble: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modalSlots() {
      return this.$slots.modalBody ? true : false
    }
  },
  methods: {
    showFullPopup(e) {
      var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      var touchY = event.targetTouches[0].pageY;
      // 현재 터치 위치가 브라우저 높이의 60% 이상인 경우 풀 팝업으로 전환
      if (touchY < windowHeight * 0.6) {
        this.modalFullCb();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

.ui-footer-box {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  max-width: 768px;
  margin: auto;
  background-color: #fff;

  p {
    padding: 3rem;
    margin: 0;
    font-size: 1.8rem;
    color: #a6a6a6;
  }

  .ui-footer-btns {
    overflow: hidden;

    button {
      display: block;
      float: left;
      padding: 0;
      width: 50%;
      height: 9rem;
      line-height: 9.5rem;
      background: none;
      border: 0;
      font-size: 2.2rem;
      font-weight: 500;
      text-align: center;
    }

    &.single button {
      width: 100%;
    }

    &.triple {
      button {
        width: 25%;
      }
      button[type="submit"] {
        width: 50%;
      }
    }

    &.rate32 {
      button {
        width: 60%;
      }
      button[type="submit"] {
        width: 40%;
      }
    }

    &.rate37 {
      button {
        width: 30%;
      }
      button[type="submit"] {
        width: 70%;
      }
    }

    button[type="button"] {
      background-color: #fff;
      color: $color-deepLavender;
      border: 1px solid $color-deepLavender;

      &:disabled {
        color: #c7c7c7;
        border-color: #c7c7c7;
      }
    }

    button[type="submit"] {
      background-color: $color-deepLavender;
      color: #fff;

      &:disabled {
        background-color: #c7c7c7;
      }
    }
  }

  .footer-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: 90px;
    z-index: 2;
    background-color: #fff;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    height: auto;
    padding-bottom: 40px;

    &.footer-modal-bg {
      top: -100vh;
      bottom: 100%;
      z-index: 1;
      background-color: rgba($color: #000000, $alpha: 0.2);
      border-radius: 0;
      height: auto;
    }

    p {
      padding: 0;
      white-space: pre-line;
      text-align: center;
      line-height: 1.5;
      &.title {
        font-size: 2.6rem;
        font-weight: 600;
        color: #000;
        padding: 0.5em;
      }
      &.body {
        font-size: 2rem;
        font-weight: normal;
        color: #555;
      }
    }

    .footer-modal-btn {
      width: 60px;
      padding: 30px 0 20px;
      margin: 0 auto;
      cursor: pointer;
      .icon {
        margin: 0 auto;
      }
    }

    &.draggable{
      .footer-modal-btn {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1.4rem 0;
        .drag-control {
          width: 4rem;
          height: 0.4rem;
          border-radius: 10rem;
          background-color: #EEEEEE;
        }
      }
    }
  }

  &.ui-footer-box--static {
    position: static !important;
  }
  &.full {
    height: 100%;
    .ui-footer-btns {
      position: fixed;
      bottom: 0;
      width: 100%;
      max-width: 768px;
    }
    .footer-modal {
      position: static;
      height: 100%;
      padding-bottom: 90px;
    }
  }
}
</style>
