import http from 'axios'
import {
  setHttp,
  resultCheck,
  isNewMembershipUser,
  toNumber,
  validMultipleGenre,
} from '@/lib/globalLib'
import log from '@/lib/log'
import ChannelService from '@/plugins/ChannelService'
import { ReqArtistData, ArtistDataInfo } from '@/model/ArtistModel'


const defaultState = () => {
  return {
    userData: {},
    artistData: new ArtistDataInfo(),
    reqArtistData: new ReqArtistData(),
    mainProfile: {},
    artistProfiles: [],
    artistProfileStep: 0,
    artistProfilePercent: 0,
    artistProfileDefaultChecked: false,
    artistProfileDetailChecked: false,
    artistProfileActiveChecked: false,
    artistProfileBankChecked: false,
    artistProfilePrivacyChecked: false,
    advData: [],
    confirmFeesPer: null,
    couponData: [],
    pushMsgNum: null,
    useCard: null,
    genreText: [],
    pureGenre: [],
    hashTagDate: null,
    mainImageDate: null,
    newMsgData: {
      lastMsgUid: 0,
      viewMsgUid: 0,
    },
    readAllMsg: true,
    category: [],
    chUserData: {},
    subGenreText: [],
    etcGenreText: [],
    artistAccountInfo: {
      id: 0,
      agreeDate: 0,
      checkImgUrl: '',
      bankImgUrl: '',
      disCheckImgUrl: '',
      disBankImgUrl: '',
    },
    tooltipShow: null,
  }
}

const setFeeInformationAndAgreement = (artistData) => {
  if (artistData) {
    artistData.agreeDate = Number(artistData?.agreeDate || 0)
    if (artistData.agreeDate > 0) {
      sessionStorage.setItem('feeInformationAndAgreement', artistData.agreeDate.toString())
    }
  }
}

export default {
  state: defaultState(),
  mutations: {
    setUserState(state, data) {
      if (!data) data = defaultState()
      data.newMsgData = 'newMsgData' in data && data.newMsgData && typeof data.newMsgData === 'object' ? data.newMsgData : {
        lastMsgUid: 0,
        viewMsgUid: 0
      }
      setFeeInformationAndAgreement(data?.artistData)
      if (data.userData?.ngWordCount !== undefined) data.userData.ngWordCount = parseInt(data.userData.ngWordCount) || 0
      if (data.userData?.ngWordDate !== undefined) data.userData.ngWordDate = parseInt(data.userData.ngWordDate) || 0
      state = Object.assign(state, data)
    },
    setUserData(state, payload = {}) {
      if (payload?.ngWordCount !== undefined) payload.ngWordCount = parseInt(payload.ngWordCount) || 0
      if (payload?.ngWordDate !== undefined) payload.ngWordDate = parseInt(payload.ngWordDate) || 0
      state.userData = Object.assign(state.userData, payload)
    },
    setArtistData(state, payload = {}) {
      if (!payload) state.artistData = new ArtistDataInfo()
      if (payload && typeof payload === 'object') {
        state.artistData = {
          ...state.artistData,
          ...payload,
        }
      }
    },
    setArtistAccountInfo(state, payload = {}) {
      if (payload && typeof payload === 'object') {
        state.artistAccountInfo = {
          ...state.artistAccountInfo,
          ...payload,
        }
      }
    },
    setArtistProfiles(state, payload = []) {
      state.artistProfiles.splice(0, state.artistProfiles.length);
      state.artistProfiles = payload;
    },
    setMainProfile(state, payload = {}) {
      state.mainProfile = payload
    },
    setChUserData(state, payload = {}) {
      if (payload && typeof payload === 'object') {
        state.chUserData = { ...payload };
      }
    },
    setMsgUid(state, payload = {
      mode: 'view',
      msgUid: 0
    }) {
      let msgUid = payload.msgUid || 0
      switch (payload.mode) {
        case 'view':
          state.newMsgData.viewMsgUid = msgUid
          break
        case 'last':
          state.newMsgData.lastMsgUid = msgUid
          break
      }
    },
    setReadAllMsg(state, payload = true) {
      state.readAllMsg = payload
    },
    setArtistProfileStep(state, step) {
      state.artistProfileStep = Number(step)
    },
    setArtistProfilePrivacy(state, payload) {
      state.artistProfilePrivacyChecked = payload
    },
    setArtistProfileStatus(state, {
      artistProfilePercent = 0,
      artistProfileDefaultChecked = false,
      artistProfileDetailChecked = false,
      artistProfileActiveChecked = false,
      artistProfileBankChecked = false,
      artistProfilePrivacyChecked = false,
    }) {
      state.artistProfilePercent = artistProfilePercent || 0
      state.artistProfileDefaultChecked = artistProfileDefaultChecked
      state.artistProfileDetailChecked = artistProfileDetailChecked
      state.artistProfileActiveChecked = artistProfileActiveChecked
      state.artistProfileBankChecked = artistProfileBankChecked
      state.artistProfilePrivacyChecked = artistProfilePrivacyChecked
    },
    setCouponData(state, payload = []) {
      state.couponData = payload
    },
    setSubGenreText(state, payload = []) {
      state.subGenreText = payload
    },
    setEtcGenreText(state, payload = []) {
      state.etcGenreText = payload
    },
    setTooltipShow(state, payload = null) {
      console.log('setTooltipShow : ',payload)
      localStorage.setItem('tooltipShow',payload)
      state.tooltipShow = payload
    },
  },
  actions: {
    logout({
      commit
    }, cb) {

      commit('setUserState', false)
      if (typeof sessionStorage === 'object') {
        sessionStorage.removeItem('sns')
        sessionStorage.removeItem('snsUid')
        sessionStorage.removeItem('useridx')
        sessionStorage.removeItem('userToken')
      }
      localStorage.removeItem('chUserData')
      
      if (typeof window.ChannelIO == 'function') {
        ChannelService.updateUser({
          "pluginKey": "622f1426-3d4a-4122-9256-5b28bbc7c317", // fill your plugin key
          memberId: null,
          profile: {
            category: null,
            createdDate: null,
            favoritesCnt: null,
            lastOfferDt: null,
            lastPayDate: null,
            loginCount: null,
            loginDate: null,
            mobileNumber: null,
            name: null,
            offerCnt: null,
            payCnt: null,
            userIdx: null,
            memberId: null
          },
          hideChannelButtonOnBoot: true,
        }, function onBoot(err, user) {
          if (err) {
            console.log('error : ', err);
          }
        });

        ChannelService.shutdown();
      }

      if (cb) cb()
    },
    login({
      commit,
      dispatch
    }, {
      sns,
      snsUid,
      pushToken = '',
      platform = 'webapp'
    }) {
      return new Promise((resolve, reject) => {
        let errMsg = ''

        if (!sns) errMsg += `${errMsg ? ', ' : ''}sns`
        if (!snsUid) errMsg += `${errMsg ? ', ' : ''}snsUid`
        if (errMsg) {
          log.error(errMsg + ' 없음')
          resolve({
            success: false,
            code: null,
            msg: '잘못된 접근입니다.'
          })
          return
        }

        const req = {
          method: 'post',
          url: '/user/login',
          data: {
            sns,
            snsUid,
            pushToken
          },
          headers: {
            platform
          }
        }

        http(setHttp(req))
          .then(({
            data
          }) => {
            log(...Object.values(req), data)

            const resultData = resultCheck(data.resultData)

            if (resultData.success) {
              if (data.response.userData && 'useridx' in data.response.userData) {
                commit('setUserState', data.response)
                if (data.response.chUserData) {
                  commit('setChUserData', data.response.chUserData);
                  localStorage.setItem('chUserData', JSON.stringify(data.response.chUserData))
                }
                if (typeof sessionStorage === 'object') {
                  localStorage.setItem('sns', sns)
                  localStorage.setItem('snsUid', snsUid)
                  sessionStorage.setItem('useridx', data.response.userData.useridx)
                  sessionStorage.setItem('userToken', data.response.userData.userToken)
                  sessionStorage.setItem('platform', platform)
                }
              }
            } else {
              dispatch('logout')
            }

            resolve(resultData)
          })
          .catch(reject)
      })
    },
    getProfileList({
      commit,
      dispatch
    }) {
      return new Promise((resolve, reject) => {
        let errMsg = ''
        const useridx = sessionStorage.getItem('useridx')
        const usertoken = sessionStorage.getItem('userToken')
      
        const req = {
          method: 'get',
          url: `/artist/profile/list/all`,
          headers: {
            useridx,
            usertoken,
          },
        } 
        http(setHttp(req))
          .then(({
            data
          }) => {
            log(...Object.values(req), data)
            const resultData = resultCheck(data.resultData)
            let artistData = new Array();

            if (resultData.success) {
              artistData = data.response.artistData;
              if(artistData){
                commit('setArtistProfiles', artistData);
                resolve(artistData)
              }
            } else {
              reject(new Error('resultData가 success가 아닙니다.'))
            }
          })
          .catch((error) => {
            console.error('HTTP 요청 실패:', error)
            reject(error)
          })
      })
    },
  },
  getters: {
    getArtistData(state) {
      return state.artistData;
    },
    getArtistProfiles(state) {
      return state.artistProfiles;
    },
    getMainProfile(state) {
      return state.mainProfile;
    },
    getArtistAccountInfo(state) {
      return state.artistAccountInfo;
    },
    getArtistProfileStep(state) {
      return state.artistProfileStep;
    },
    isApprovedProfile(state) {
      try {
        return toNumber(state.artistProfileStep) == 3 || false
      } catch {
        return false
      }
    },
    isTriedNewMembership(state) {
      return Number(state.artistData?.newMemberDate) > 0
    },
    isPlusMemberTargetArtist(state, getters) {
      if (state.userData?.category !== 'artist') {
        return false
      }

      return getters.isApprovedProfile && !getters.isTriedNewMembership
    },
    isNewMembershipUser(state) {
      return isNewMembershipUser(state.artistData?.newMemberDate)
    },
    hasNewMsg(state) {
      const lastMsgUid = state?.newMsgData?.lastMsgUid || 0
      const viewMsgUid = state?.newMsgData?.viewMsgUid || 0
      return lastMsgUid > viewMsgUid || !state.readAllMsg
    },
    downtime(state) {
      const userData = state?.userData
      const ngWordCount = parseInt(userData?.ngWordCount) || 0
      const ngWordDate = parseInt(userData?.ngWordDate) || 0

      let downtime = 0

      if (ngWordCount === 2) {
        downtime += 60 * 60
      }

      if (ngWordCount === 3) {
        downtime += 60 * 60 * 24
      }

      return ngWordDate + downtime
    },
    isDowntime(state, getters) {
      const now = Math.floor(Date.now() / 1000)

      return getters.downtime > 0 && getters.downtime > now
    },
    getChUserData(state) {
      return state.chUserData ? state.chUserData : JSON.parse(localStorage.getItem('chUserData') || null) == null ? {} : JSON.parse(localStorage.getItem('chUserData'));
    },
    getSubGenreText(state) {
      return state?.subGenreText || [] ;
    },
    getEtcGenreText(state) {
      return state?.etcGenreText || [] ;
    },
    getTooltipShow(state) {
      
      if(state.tooltipShow != null) return state.tooltipShow
      else{
        if(JSON.parse(localStorage.getItem('tooltipShow')) != null){
          return JSON.parse(localStorage.getItem('tooltipShow'))
        }else{
          return true
        }
      }
    },
  },
}
