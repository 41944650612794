<template>
  <div class="container not-found">
    <h1>Please reload :(</h1>
    <h2>업데이트로 인하여 앱의 재실행이 필요합니다</h2>
    <button class="btn btn-outline-danger" @click="() => reload()">Reload</button>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    reload() {
      sessionStorage.removeItem('historyStack')
      window.location.href = '/home'
    },
  },
}
</script>

<style lang="scss">
.not-found {
  padding-top: 10vh;
  text-align: center;
  h1 {
    font-size: 8vw;
  }
  h2 {
    font-size: 3vw;
    margin-top: 5vh;
  }
  button {
    margin-top: 4vw;
    font-size: 4vw;
  }
}
</style>
