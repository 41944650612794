import type { Module } from 'vuex'
import { moment } from '@/plugins/moment'
import type { IArtistEvent } from '../flow/index.js'

export const artistEventList: ArtistEvent[] = [
    // 이벤트는 여기에서 등록합니다.
    {
        title: '24시간 할인 이벤트 참여하기',
        url: 'http://naver.me/G85plJUT',
        startAt: '2020-07-15',
        endAt: null,
    },
    {
        title: '나만의 ‘단독’ 이벤트 등록하기',
        url: 'http://naver.me/x9QFfiQe',
        startAt: '2020-07-15',
        endAt: null,
    },
]

type IState = {
    artistEventList: IArtistEvent[]
}

const artistEventModule: Module<IState> = {
    state: {
        artistEventList: []
    },
    mutations: {
        setArtistEventList(state, payload: IArtistEvent[]) {
            state.artistEventList.length = 0
            state.artistEventList = state.artistEventList.concat(payload)
        },
    },
    actions: {
        getArtistEventList({ commit }) {
            const payload = JSON.parse(JSON.stringify(artistEventList))
            commit('setArtistEventList', payload)
        },
    },
    getters: {
        hasNewArtistEvent(state, getters) {
            const validArtistEventList: IArtistEvent[] = getters.validArtistEventList
            const now = moment(moment().format('YYYY-MM-DD')).valueOf()
            const fourday = 1000 * 60 * 60 * 24 * 4

            return validArtistEventList.some(row => {
                return row.startAt && now - moment(row.startAt).valueOf() <= fourday
            })
        },
        validArtistEventList(state) {
            const now = Date.now()
            const oneday = 1000 * 60 * 60 * 24

            return state.artistEventList.filter((row) => {
                if (!row.title | !row.url) {
                    return false
                }

                if (row.startAt && now < moment(row.startAt).valueOf()) {
                    return false
                }

                if (row.endAt && now >= moment(row.endAt).valueOf() + oneday) {
                    return false
                }

                return true
            })
        },
    },
}

export default artistEventModule
