import { defineStore } from 'pinia'
import { POST_OFFER } from '@/pinia/keys'

/**
 * 의뢰 등록할 때 필요한 데이터를 추가적으로 전달하기 위한 store
 */
export const usePostOfferStore = defineStore(POST_OFFER, {
    state: () => ({
        artistData: null,
    }),
    actions: {
        setArtist(data = null) {
            this.artistData = data || null
        },
    },
})
