<template>
  <transition name="fade" :duration="300">
    <div id="lnb" v-show="lnb">
      <transition name="slide-right-left" :duration="300">
        <div class="lnb-front" v-if="lnb">
          <!-- <div class="lnb-btn-close" @click="lnb = 'hide'">
            <icon src="img/common/close-gray-button.png" width="2rem" height="2rem" cursor="pointer" />
          </div> -->

          <div class="profile-area" v-if="isClient">
            <div class="profile-thumbnail ui-bg-img" v-if="profileImg" :style="{ backgroundImage: `url(${$lib.cdnUrl(profileImg)})`, width: '14rem', height: '14rem' }" />
            <icon class="profile-thumbnail" v-else src="img/user/no-profile.png" width="14rem" height="14rem" />
            <icon
              v-if="userData.category === 'client'"
              class="profile-write"
              src="img/common/write.png"
              width="3.6rem" height="3.6rem"
              @click="goto('/client/profile')"
            >
            </icon>
            <div class="profile-hello">안녕하세요!</div>
            <div class="profile-name">{{userData.nickName}}님</div>
          </div>
          <div else class="ui-border-line ui-h-0 ui-mb-7"></div>

          <!-- 일반회원 메뉴 -->
          <ul v-if="userData.category === 'client'">
            <li class="ui-btn" @click="goto('/client/my-offer')">
              <icon src="img/lnb/doc.png" width="2.2rem" height="3.6rem" :cover="false" style="margin-left :0.2rem"/>
              <span>나의 의뢰</span>
            </li>
            <li class="ui-btn" @click="goto('/message/list')">
              <icon src="img/lnb/msg.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>메시지</span>
              <div class="badge" v-if="newMsg">N</div>
            </li>
            <li class="ui-btn" @click="goto('/client/fav')">
              <icon src="img/lnb/heart.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>찜한 전문가</span>
            </li>
            <li class="ui-btn" @click="goto('/etc/information')">
              <icon src="img/lnb/info.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>이용안내</span>
            </li>
            <li class="ui-btn" @click="goto('http://pf.kakao.com/_yzxhwC')">
              <icon src="img/lnb/question.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>카카오톡 1:1문의</span>
            </li>
            <li class="ui-btn" @click="goto('/setting')">
              <icon src="img/lnb/set.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>설정</span>
            </li>
            <li class="ui-btn" @click="goto('/profile')">
              <icon src="img/lnb/set.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>프로필</span>
            </li>
          </ul>

          <!-- 전문가 메뉴 -->
          <ul v-if="userData.category === 'artist'">
            <!-- TODO: isDev일때만 멤버십 -->
            <template v-if="isDev">
              <li class="ui-btn" @click="goto('/membership')">
                <icon src="img/lnb/profile.png" width="2.4rem" height="3.6rem" :cover="false" />
                <span>멤버십</span>
              </li>
            </template>

            <li class="ui-btn" @click="goto('/artist/my-offer')">
              <icon src="img/lnb/doc.png" width="2.2rem" height="3.6rem" :cover="false" style="margin-left :0.2rem"/>
              <span>지원한 의뢰</span>
            </li>

            <li class="ui-btn" @click="goto('/artist/direct-offer')">
              <icon src="img/lnb/consulting.png" width="2.8rem" height="3.6rem" :cover="false" :style="{
                marginRight: '0.7rem',
                marginLeft: '0.3rem',
              }"/>
              <span>받은 의뢰</span>
              <!-- <div class="badge">N</div> -->
            </li>

            <li class="ui-btn" @click="goto('/message/list')">
              <icon src="img/lnb/msg.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>메시지</span>
              <div class="badge" v-if="newMsg">N</div>
            </li>

            <!-- 찜한 의뢰로 변경해야함. -->
            <li class="ui-btn" @click="goto('/artist/fav')">
              <icon src="img/lnb/heart.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>찜한 의뢰</span>
            </li>

            <li class="ui-btn" @click="goto('/etc/information')">
              <icon src="img/lnb/info.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>이용안내</span>
            </li>

            <li class="ui-btn" @click="goto('/etc/fee-information')">
              <icon src="img/lnb/card.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>수수료 안내</span>
            </li>

            <li class="ui-btn" @click="goto('http://pf.kakao.com/_yzxhwC')">
              <icon src="img/lnb/question.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>카카오톡 1:1문의</span>
            </li>

            <li class="ui-btn" @click="goto('/setting')">
              <icon src="img/lnb/set.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>설정</span>
            </li>
            
            <li class="ui-btn" @click="goto('/profile')">
              <icon src="img/lnb/set.png" width="2.6rem" height="3.6rem" :cover="false" />
              <span>프로필</span>
            </li>
          </ul>
        </div>
      </transition>
      <div class="lnb-back" @click="lnb = 'hide'"></div>
    </div>
  </transition>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: {
    Icon,
  },
  computed: {
    lnb: {
      get() {
        return this.$store.state.lnb
      },
      set() {
        this.$store.commit('setLnb', 'toggle')
      },
    },
    userData: {
      get() {
        return this.$store.state.user.userData
      },
    },
    artistData: {
      get() {
        return this.$store.state.user.artistData
      },
    },
    isClient: {
      get() {
        return this.userData.category === 'client'
      },
    },
    newMsg() {
      const newMsgData = this.$store.state.user.hasOwnProperty('newMsgData') ? this.$store.state.user.newMsgData : { lastMsgUid: 0, viewMsgUid: 0 }
      const lastMsgUid = newMsgData ? Number(newMsgData.lastMsgUid || 0) : 0
      const viewMsgUid = newMsgData ? Number(newMsgData.viewMsgUid || 0) : 0
      return lastMsgUid > viewMsgUid || !this.$store.state.user.readAllMsg
    },
    profileImg() {
      if (this.isClient) {
        return this.userData.imgUrl
        } else {
        return this.artistData.teamImgUrl
      }
    },
    isDev() {
      return process.env.NODE_ENV !== 'production'
    },
  },
  watch: {
    lnb(sta) {
      document.body.style.overflow = sta ? 'hidden' : null
    },
  },
  methods: {
    goto(url) {
      this.lnb = 'hide'

      if (/^https?:\/\//.test(url)) {
        this.$runToApp({
          appCb: () => location.href = `hbscheme://gotoWeb?url=${encodeURI(url)}`,
          webCb: () => window.open(encodeURI(url)),
        }, true)
        return
      } else if (/^\//.test(url)) {
        this.$router.push(url)
        return
      }

      setTimeout(() => {
        this.$router.push(url)
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/constant.scss';

#lnb {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;

  .lnb-front {
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 80%;
    max-width: 480px;
    height: 100%;
    background-color: $color-white;
    color: #929292;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);

    .lnb-btn-close {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 1;
    }

    .profile-area {
      padding: 3rem 0 8rem;
      text-align: center;

      .profile-thumbnail {
        margin: 0 auto;
        // width: 10rem;
        // height: 10rem;
        width: 14rem;
        height: 14rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
      }

      .profile-write {
        position: absolute;
        top: 13rem;
        left: 50%;
        margin-left: 4rem;
      }

      .profile-hello {
        font-size: 2rem;
        line-height: 2rem;
        padding: 1.4rem 0 1rem;
      }

      .profile-name {
        font-size: 2.8rem;
      }
    }

    // @media all and (min-width: 768px) {
    //   .lnb-btn-close {
    //     display: none;
    //   }
    // }

    ul {
      padding: 0 3rem;
      li {
        overflow: hidden;
        margin-bottom: 4rem;

        > * {
          float: left;
          line-height: 4rem;
        }

        > .icon {
          margin-right: 1.6rem;
        }

        > span {
          font-size: 2.8rem;
        }

        > .badge {
          display: block;
          float: right;
          margin: 1rem 0;
          padding: 0;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          background-color: $color-deepLavender;
          color: #fff;
          font-size: 1.15rem;
          font-weight: 400;
          line-height: 2rem;
        }
      }
    }
  }
  .lnb-back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
</style>