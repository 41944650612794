import Toasted from 'vue-toasted'

export default {
  install(Vue) {
    Vue.use(Toasted, {
      theme: 'primary',
      position: 'bottom-center',
      duration: 2000,
    })
    Vue.prototype.$toast = function (msg) {
      const $router = {}
      const routerKeys = ['replace', 'push', 'go', 'back']

      this.$toasted.show(msg)

      for (let idx in routerKeys) {
        $router[routerKeys[idx]] = (router) => {
          setTimeout(() => {
            this.$router[routerKeys[idx]](router)
          }, 1000)
        }
      }

      return $router
    }
  }
}