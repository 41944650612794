let billData = sessionStorage.getItem('billData')
billData = billData ? JSON.parse(billData) : {}

export default {
  state: {
    iniMid: billData.iniMid, // 일반결제 상점코드
    billMid: billData.billMid, // 정기결제 상점코드
    billDays: billData.billDays, // 정기결제 사용기간 만료 계산용 일수
    billPrice: billData.billPrice, // 정기결제 금액 (실제 결제 금액)
    billTimestamp: billData.billTimestamp, // 정기결제용
    billOrderid: billData.billOrderid, // 정기결제용
    billHashData: billData.billHashData, // 정기결제용
    promoEndDate: billData.promoEndDate, // 프로모션 끝나는 일시
    promoPrice: billData.promoPrice, // 원가
    promoSalePer: billData.promoSalePer, // 할인률(%)
  },
  mutations: {
    setBillData(state, payload) {
      if (payload && typeof payload === 'object') {
        sessionStorage.setItem('billData', JSON.stringify(payload || {}))
        state = Object.assign(state, payload || {})
      }
    },
  },
  actions: {

  },
  getters: {

  }
}