export default {
  install(Vue) {
    let stack = []
    Vue.prototype.$alert = function (message) {
      if (typeof message === 'string') {
        this.$toast(message)
        return
      }
      message.code = Number(message.code)
      const codeIdx = stack.indexOf(message.code)
      const guestPage = this.$config.constant.guestPages.some(path => path.test(location.pathname))
      if (message.code === -2 && !guestPage) {
        if (codeIdx === -1) {
          message.msg = '로그인 정보가 만료 되었습니다.'
          this.$toast(message.msg)
          setTimeout(() => {
            stack.splice(codeIdx, 1)
          }, 1000)
          stack.push(message.code)
        }
        return
      }
      this.$toast(message.msg)
    }
  }
}