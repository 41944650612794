/*
 * globalLib
 */
import { getIdFromUrl as getYoutubeIdFromUrl } from 'vue-youtube'
import http from 'axios'
import moment from 'moment'
import { BASE_URL } from '@/config/env.js'
import { errorMsg } from '@/config/msg.js'
import { location } from '@/config/constant.js'

export const getOrigin = () => {
  const hostname = window.location.hostname
  const arr = hostname.split('.')
  let origin = 'https://api.heybeagle.co.kr'

  if (/dev|local/.test(arr[0] || !isNaN(arr[0])) || checkNgrok()) {
    // origin = 'http://apidev.heybeagle.co.kr'
    origin = 'https://wimzapidev.openobject.net'
  }

  return origin
}

export const checkLocalhost = () => {
  const hostname = window.location.hostname
  return /^(localhost|[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)$/.test(hostname)
}

export const checkNgrok = () => {
  const hostname = window.location.hostname
  return /^.+\.ngrok\.io$/.test(hostname)
}

export const CDN_URL = getOrigin()
export const API_URL = CDN_URL + '/api'
// export const API_URL = (checkLocalhost() ? window.location.origin : CDN_URL) + '/api'

export const imgUrl = (filename, originUrl = false) => {
  let extMatch = filename.match(/\.([a-zA-Z]+)$/)
  let ext = extMatch ? extMatch[1] : 'png'
  if (extMatch === null) {
    return filename
  }

  if (ext !== 'svg' && !originUrl && 'devicePixelRatio' in window && window.devicePixelRatio > 1) {
    let reg = new RegExp(`\\.${ext}$`)

    if (window.devicePixelRatio <= 2) {
      filename = filename.replace(reg, `@2x.${ext}`)
    } else {
      filename = filename.replace(reg, `@3x.${ext}`)
    }
  }


  return `${BASE_URL === '/' ? '' : BASE_URL }/${filename}`
}

export const cdnUrl = (url) => {
  if (/^https?:\/\//.test(url) || /^(data:|blob:)/.test(url)) return url
  return `${CDN_URL}/${url}`
}

export const webUrl = (path) => {
  if (/^https?:\/\//.test(path) || /^data:/.test(path)) return path
  let url = 'http://webdev.heybeagle.co.kr/'
  if (process.env.NODE_ENV === 'production') url = 'http://web.heybeagle.co.kr/'
  return url + path.replace(/^\//, '')
}

export const resultCheck = (resultData) => {
  let errorCode = resultData.errorCode

  if (errorCode === '0' || errorCode === 0) {
    return {
      success: true,
      code: 0,
      msg: '',
    }
  }

  if (errorMsg[errorCode]) {
    return {
      success: false,
      code: Number(errorCode),
      msg: errorMsg[errorCode],
    }
  }

  return {
    success: false,
    code: Number(errorCode) || errorCode,
    msg: `처리에 실패하였습니다.${errorCode ? ` (${errorCode})` : ''}`,
  }
}

export const httpParam = {
  method: 'get',
  baseURL: `${API_URL.replace(/\/$/, '')}`,
  url: '',
  data: {},
  params: {},
  headers: {
    platform: 'webapp',
    useridx: null,
    appVersion: null,
    usertoken: null,
  },
  timeout: 300000,
}

export const setHttp = (param = {}) => {
  const loop = (arr1 = {}, arr2 = {}, result = {}) => {
    for (let key in arr1) {
      if (key === 'headers') {
        result.headers = loop(arr1.headers, arr2.headers)
      } else {
        result[key] = arr2[key] ? arr2[key] : arr1[key]
      }
    }
    return result
  }
  return loop(JSON.parse(JSON.stringify(httpParam)), param)
}

export const addComma = (num) => {
  if (!num) return num

  try {
    if (typeof num !== 'number') {
      num = parseInt(num);
    }
  } catch (error) {
    console.error("An error occurred:", error);
    num = 0;
  }
  
  const regexp = /\B(?=(\d{3})+(?!\d))/g
  num = num.toString()
  let nums = num.split('.')
  nums[0] = nums[0].replace(/([-][\D|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|,])/g, '')
  nums[0] = nums[0].replace(regexp, ',')
  return nums.join('.')
}

export const moneyFormat = (price = 0, currency = 'KRW') => {
  price = parseInt(price, 10) || 0

  const lessMillion = price % 100000000
  const tenThousand = Math.floor(lessMillion / 10000)
  const million = Math.floor(price / 100000000)
  let money = ''

  switch (currency) {
    case 'KRW':
      if (million > 0) {
        money += money ? ' ' : ''
        money += `${addComma(million)}억`
      }

      if (tenThousand > 0) {
        money += money ? ' ' : ''
        money += `${addComma(tenThousand)}만`
      } else {
        money += money ? ' ' : ''
        money += `${addComma(lessMillion)}`
      }

      return money

    default:
      return addComma(price)
  }
}

export const getRatioHeight = (ratio = 16/9, width = 48) => {
  return Number(width) / Number(ratio)
}

export const getAvgPoint = (row = {}) => {
  const pointCount = Number(row?.pointCount || 0)
  return Math.floor(pointCount) + (pointCount % 1 ? 0.5 : 0)
}

export const getLocationText = (numbers) => {
  if (!numbers) return '전국'

  return numbers.split(/,\s?/)
    .map(key => {
      if (isNaN(Number(key))) return key
      return location[key]
    })
    .join(', ')

}

export const addrMinify = (address) => {
  if (!address) return '미등록'
  return address.split(' ').slice(0, 2).map((str, idx) => idx === 0 ? str.replace(/^(..).+$/, '$1') : str).join(' ')
}

export const str2json = (str) => {
  if (!str || typeof str !== 'string') return {}
  let result = {}
  str.split('|').forEach(row => {
    let arr = row.split('=')
    if (arr.length === 2) {
      result[arr[0]] = arr[1]
    }
  })
  return result
}

export const getUrlFromText = (text = '') => {
  text = text.replace(/(?:\r\n|\r|\n)/g, '<br>')
  let urls = text.match(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/g)
  if (!urls) return {
    urls: [],
    texts: [text],
  }

  let texts = urls.reduce((text, reg) => {
    return text.replace(reg, '{{link}}')
  }, text).split('{{link}}')

  return {
    texts,
    urls,
  }
}

export const isAllowMovUrl = (url: string) => {
  if (!url) return false

  const hostnames = [
    //
    // /tv\.naver\.com/,
    // /shoppinglive\.naver\.com/,
    // /naver\.me/,
  ]

  if (getYoutubeIdFromUrl(url)) {
    return true
  }

  return hostnames.some((hostname) => hostname.test(url))
}

export const isNewMembershipUser = (newMemberDate) => {
//   const ms = Number(newMemberDate) * 1000
//   return ms >= Date.now()
    // 멤버십 무료 전환 처리
    return true;
}

export const validNgWordCount = (data, store) => {
  const ngWordCount = parseInt(data?.response?.ngWordCount) || 0
  // TODO: 일단 보류
  // const ngWordDate = parseInt(data?.response?.ngWordDate) || 0
  const userData = store.state?.user?.userData
  const isArtist = userData?.category === 'artist'

  if (ngWordCount === 0) {
    return true
  }

  if (store) {
    // TODO: 아래 내용 대신 임시로 사용
    if (ngWordCount >= 2 && isArtist) {
      disableArtistProfile(store)
    }
    showNgWordModal(store, ngWordCount)

    // TODO: 일단 보류
    // store.commit('setUserData', {
    //   ngWordCount,
    //   ngWordDate,
    // })

    // if (ngWordCount === 4 && isArtist) {
    //   store.commit('setArtistData', { confirmDate: 0 })
    // }

    // execNgWordProcess(store)
  }

  return false
}

export const execNgWordProcess = (store) => {
  return false
  // TODO: 일단 보류
  // const userData = store?.user?.userData
  // const ngWordCount = parseInt(userData?.ngWordCount) || 0

  // if (ngWordCount === 0) {
  //   return false
  // }

  // if (store.getters.downtime > 0 && !store.getters.isDowntime) {
  //   return false
  // }

  // showNgWordModal(store, ngWordCount)

  // return true
}

export const showNgWordModal = (store, ngWordCount: number) => {
  store.commit('setNgWordCount', ngWordCount)

  // const span = {
  //   black: text => '<span style="font-size: 18px;color: #000;">' + text + '</span>',
  //   gray: text => '<span style="font-size: 18px;color: #959595;">' + text + '</span>',
  // }

  // let body = `\n${`<img src="${imgUrl('img/common/triangle.png')}" width="64px" />`}\n\n`

  // switch (ngWordCount) {
  //   case 1:
  //     body += span.black(`사기, 부정 거래 방지를 위해\n결제 전 연락처 전달은 불가합니다.\n`)
  //     body += span.gray(`(전화번호,카톡,메일 등)\n\n연속 시도 시 이용이 제한되니\n제외하고 다시 등록해주세요.`)
  //     break
  //   case 2:
  //     body += span.black(`이번이 마지막 안내입니다.\n다시 시도 하실 경우\n부정 거래로 간주되어\n서비스 이용이 제한됩니다.\n\n`)
  //     body += span.gray(`제외하고 다시 등록해주세요.`)
  //     break
  //   default:
  //     body += span.black(`서비스 정책 위반으로\n이용 정지될 예정입니다.\n\n`)
  //     body += span.gray(`오류 신고 또는 의견이 있으신 경우\n고객센터로 연락주세요.`)
  //     break
  // }

  // store.commit('setModal', {
  //   show: true,
  //   body,
  //   textAlign: 'text-center',
  //   confirm: '확인',
  //   confirmCb: modal => {
  //     // TODO: 일단 보류
  //     // if (ngWordCount > 0) {
  //     //   store.router.push('/home')
  //     // }
  //     modal.hide()
  //   },
  //   noBorder: true,
  //   useCancel: false,
  // })
}

/**
 * value의 값을 끝에서 digits번째까지 0으로 변경
 */
export const floor = (value, digits = 0) => {
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  if (typeof value !== 'number' || isNaN(value)) {
    return value
  }

  const n = '1' + '0'.repeat(digits)

  return Math.floor(value / n) * n
}

export const disableArtistProfile = (store) => {
  const useridx = sessionStorage.getItem('useridx')
  const usertoken = sessionStorage.getItem('userToken')

  const req = {
    method: 'put',
    url: `/artist/profile/disable`,
    headers: {
      useridx,
      usertoken,
    },
  }

  http(setHttp(req))
    .then(({ data }) => {
      log(...Object.values(req), data)

      const resultData = resultCheck(data.resultData)

      if (resultData.success) {
        store.commit('setArtistData', { confirmDate: 0 })
      }
    })
    .catch(log.error)
}

export const t_genre = (genre: string, separator = ',') => {
    genre = String(genre ?? '')

    return genre
        .split(separator)
        .map(row => {
            const val = row.trim()

            switch (val) {
                case '노래':
                    return '가수'

                case 'MC':
                    return '사회자'

                default:
                    return val
            }
        })
        .join(`${separator} `)
}

export const t_genre_reverse = (genre: string, separator = ',') => {
  genre = String(genre ?? '')

  return genre
    .split(separator)
    .map(row => {
      const val = row.trim()

      switch (val) {
        case '가수':
          return '노래'

        case '사회자':
          return 'MC'

        default:
          return val
      }
    })
    .join(`${separator} `)
}

export const t_subGenre = (subGenre: string, separator = ',') => {
  subGenre = String(subGenre ?? '')

  return subGenre
    .split(separator)
    .map(row => {
      const val = row.trim()

      switch (val) {
        case '기타':
          return '기타'

        default:
          return val
      }
    })
    .join(`${separator} `)
}

export const moneyRangeFormat = (data: { priceStart?: number, priceEnd?: number } = {}) => {
  let priceStart = Number(data?.priceStart) || 0
  let priceEnd = Number(data?.priceEnd) || 0
  let input = [priceStart, priceEnd]

  priceStart = Math.min(...input)
  priceEnd = Math.max(...input)
  input = []

  if (priceStart) {
      input.push(priceStart)
  }

  if (priceEnd && priceStart !== priceEnd) {
      input.push(priceEnd)
  }

  return input.map((row) => `${addComma(row)}원`).join(' - ')
}

export const toNumber = (value) => Number(value) || 0

/**
 * 복수 genre('장르1,장르2')가 input 중 1개 이상 정확하게 일치할 때 true
 */
export const validMultipleGenre = (genres = '', ...inputs: string[]) => {
  return (genres || '')
    .split(',')
    .some(row => validGenre(row, ...inputs))
}

/**
 * 단일 genre가 input 중 1개 이상 정확하게 일치할 때 true
 */
export const validGenre = (genre = '', ...inputs: string[]) => {
  genre = t_genre_reverse((genre || '').trim())

  return inputs.some(input => genre === t_genre_reverse(input))
}

export const timeFormat = {
  created(unix: string | number) {
    const now = Date.now()
    let created = parseInt(unix) || undefined

    if (created === undefined) {
      return ''
    }

    created *= 1000

    const createdAt = moment(created)

    if ((now - created) >= (24 * 60 * 60 * 1000)) {
      return createdAt.format('YYYY.MM.DD')
    }

    return createdAt.format('HH:mm')
  }
}

export const loadImageWithSekeleton = (url) => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        //console.log('loadImageWithSekeleton ' + url);
        resolve();
      };
      img.onerror = (e) => {
        //console.log('ERROR loadImageWithSekeleton ' + url);
        resolve();
      };
    });
  }
  
