var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade", duration: 300 } }, [
    _c(
      "div",
      {
        directives: [
          { name: "show", rawName: "v-show", value: _vm.lnb, expression: "lnb" }
        ],
        attrs: { id: "lnb" }
      },
      [
        _c(
          "transition",
          { attrs: { name: "slide-right-left", duration: 300 } },
          [
            _vm.lnb
              ? _c("div", { staticClass: "lnb-front" }, [
                  _vm.isClient
                    ? _c(
                        "div",
                        { staticClass: "profile-area" },
                        [
                          _vm.profileImg
                            ? _c("div", {
                                staticClass: "profile-thumbnail ui-bg-img",
                                style: {
                                  backgroundImage:
                                    "url(" +
                                    _vm.$lib.cdnUrl(_vm.profileImg) +
                                    ")",
                                  width: "14rem",
                                  height: "14rem"
                                }
                              })
                            : _c("icon", {
                                staticClass: "profile-thumbnail",
                                attrs: {
                                  src: "img/user/no-profile.png",
                                  width: "14rem",
                                  height: "14rem"
                                }
                              }),
                          _vm.userData.category === "client"
                            ? _c("icon", {
                                staticClass: "profile-write",
                                attrs: {
                                  src: "img/common/write.png",
                                  width: "3.6rem",
                                  height: "3.6rem"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goto("/client/profile")
                                  }
                                }
                              })
                            : _vm._e(),
                          _c("div", { staticClass: "profile-hello" }, [
                            _vm._v("안녕하세요!")
                          ]),
                          _c("div", { staticClass: "profile-name" }, [
                            _vm._v(_vm._s(_vm.userData.nickName) + "님")
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", {
                    staticClass: "ui-border-line ui-h-0 ui-mb-7",
                    attrs: { else: "" }
                  }),
                  _vm.userData.category === "client"
                    ? _c("ul", [
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("/client/my-offer")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              staticStyle: { "margin-left": "0.2rem" },
                              attrs: {
                                src: "img/lnb/doc.png",
                                width: "2.2rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("나의 의뢰")])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("/message/list")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/msg.png",
                                width: "2.6rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("메시지")]),
                            _vm.newMsg
                              ? _c("div", { staticClass: "badge" }, [
                                  _vm._v("N")
                                ])
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("/client/fav")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/heart.png",
                                width: "2.6rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("찜한 전문가")])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("/etc/information")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/info.png",
                                width: "2.6rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("이용안내")])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("http://pf.kakao.com/_yzxhwC")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/question.png",
                                width: "2.6rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("카카오톡 1:1문의")])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("/setting")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/set.png",
                                width: "2.6rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("설정")])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "ui-btn",
                            on: {
                              click: function($event) {
                                return _vm.goto("/profile")
                              }
                            }
                          },
                          [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/set.png",
                                width: "2.6rem",
                                height: "3.6rem",
                                cover: false
                              }
                            }),
                            _c("span", [_vm._v("프로필")])
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.userData.category === "artist"
                    ? _c(
                        "ul",
                        [
                          _vm.isDev
                            ? [
                                _c(
                                  "li",
                                  {
                                    staticClass: "ui-btn",
                                    on: {
                                      click: function($event) {
                                        return _vm.goto("/membership")
                                      }
                                    }
                                  },
                                  [
                                    _c("icon", {
                                      attrs: {
                                        src: "img/lnb/profile.png",
                                        width: "2.4rem",
                                        height: "3.6rem",
                                        cover: false
                                      }
                                    }),
                                    _c("span", [_vm._v("멤버십")])
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/artist/my-offer")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                staticStyle: { "margin-left": "0.2rem" },
                                attrs: {
                                  src: "img/lnb/doc.png",
                                  width: "2.2rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("지원한 의뢰")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/artist/direct-offer")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                style: {
                                  marginRight: "0.7rem",
                                  marginLeft: "0.3rem"
                                },
                                attrs: {
                                  src: "img/lnb/consulting.png",
                                  width: "2.8rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("받은 의뢰")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/message/list")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/msg.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("메시지")]),
                              _vm.newMsg
                                ? _c("div", { staticClass: "badge" }, [
                                    _vm._v("N")
                                  ])
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/artist/fav")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/heart.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("찜한 의뢰")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/etc/information")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/info.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("이용안내")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/etc/fee-information")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/card.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("수수료 안내")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("http://pf.kakao.com/_yzxhwC")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/question.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("카카오톡 1:1문의")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/setting")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/set.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("설정")])
                            ],
                            1
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "ui-btn",
                              on: {
                                click: function($event) {
                                  return _vm.goto("/profile")
                                }
                              }
                            },
                            [
                              _c("icon", {
                                attrs: {
                                  src: "img/lnb/set.png",
                                  width: "2.6rem",
                                  height: "3.6rem",
                                  cover: false
                                }
                              }),
                              _c("span", [_vm._v("프로필")])
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _c("div", {
          staticClass: "lnb-back",
          on: {
            click: function($event) {
              _vm.lnb = "hide"
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }