import Vue from 'vue'
import { resultCheck } from '@/lib/globalLib'
import log from '@/lib/log'

export default {
  state: {
    requestProfile: {
      artistidx : '',
      genre : '',
      location : '',
      teamName : '',
      teamDesc : '',
      teamDescImgUrl : '',
      playDesc : '',
      hashTag : '',
      gender : '',
      birth : '',
      career : '',
      subGenre : '',
      genreType : '',
      program : '',
      showHost : '',
      matchVoice : '',
      recommSong : '',
      mcStyle : '',
      teamType : '',
      bodyHeight : '',
      bodyWeight : '',
      bodyShoesize : '',
      bodyTopsize : '',
      bodyBottomsize : '',
      interview1 : '',
      interview2 : '',
      interview3 : '',
      teamImgUrl : '',
      isPickPro : '',
      imgUrl1 : '',
      imgUrl2 : '',
      imgUrl3 : '',
      imgUrl4 : '',
      imgUrl5 : '',
      imgUrl6 : '',
      imgUrl7 : '',
      imgUrl8 : '',
      imgUrl9 : '',
      imgUrl10 : '',
      checkImgUrl : '',
      bankImgUrl : '',
      liveMov : [],
      liveUrl : [],
      certUpdateInfo : '',
      movUrl1 : '',
      movUrl2 : '',
      movUrl3 : '',
      movUrl4 : '',
      movUrl5 : '',
      movUrl6 : '',
      movUrl7 : '',
      movUrl8 : '',
      movUrl9 : '',
      movUrl10  : '',
      movUrl11  : '',
      movUrl12  : '',
      movUrl13  : '',
      movUrl14  : '',
      movUrl15  : '',
      movUrl16  : '',
      movUrl17  : '',
      movUrl18  : '',
      movUrl19  : '',
      movUrl20  : '',
    },
  },
  mutations: {
    setTempArtistData(state, payload = {}) {
      state = Object.assign(state, payload)
    }
  },
  actions: {
    saveProfile({ state, rootState, commit, dispatch }, payload = {}) {
      return new Promise(resolve => {
        /**
         * 링크 순서
         *
         * Default
         * Default2~3
         *
         * Detail
         * Detail2~4
         * DetailSing1~3
         * DetailMc
         * DetailModel
         * Detail5
         *
         * Active
         * Active2
         * Active3
         */

        // genre : 장르 (,로 구분)
        // location : 지역 (,로 구분)
        // priceStart : 시작금액
        // priceEnd : 끝금액
        // teamName : 팀이름
        // memberCount : 멤버수
        // teamDesc : 팀설명
        // playDesc : 활동경력
        // hashTag : 해시태그(,로 구분)
        // gender : 성별 (m:남,f:여)
        // birth : 출생년도 (yyyy)
        // career : 경력기간 (0:입력안함,1:경력없음,2:1년미만,3~12 (1~10년), 13:10년이상)
        // subGenre : 세부 전문 분야 (, 로 다중구분)
        // program : 출연한 방송 프로그램명 (|로 다중구분)
        // matchVoice : 닮은 목소리 (|로 다중구분)
        // recommSong : 자신있는 곡 (추천곡) (|로 다중구분)
        // mcStyle : MC-진행 스타일 (|로 다중구분)
        // teamType : 팀 종류 (a:솔로, b:듀엣(혼성), c:듀엣(동성), d:그룹(3인 이상)) (,로 구분)
        // bwh : 신체 사이즈 (b-w-h)
        // bodyHeight : 키 (0:입력안함)
        // bodyWeight : 몸무게 (0:입력안함)
        // bodyShoesize : 신발사이즈 (0:입력안함)
        // bodyTopsize : 상의사이즈 (xs,s,m,l,xl,xxl)
        // bodyBottomsize : 하의사이즈 (0:입력안함)
        // imgUrl1~5 : 이미지 파일
        // movUrl1~5 : 동영상URL
        // checkImgUrl : 신원이미지파일
        // userImgUrl : 내 프로필 이미지 파일 (변경할때만 추가)

        // liveMov[] : 쇼핑라이브 썸네일 파일 (liveUrl과 한쌍) (array)
        // liveUrl[] : 쇼핑라이브 URL (array)
        let data = new FormData()

        // if (payload.genre && payload.genre !== rootState.user.artistData.genre) {
        //   payload.liveMov = ['']
        //   payload.liveUrl = ['']
        // }

        if (rootState.user.artistData.confirmDate > 0 && ((payload.checkImgUrl && payload.checkImgUrl !== rootState.user.artistData.checkImgUrl) || (payload.bankImgUrl && payload.bankImgUrl !== rootState.user.artistData.bankImgUrl))) {
          payload.certUpdateInfo = rootState.user.artistData.teamName;
          if(payload.checkImgUrl !== rootState.user.artistData.checkImgUrl) {
            payload.certUpdateInfo += ' | 신분증'
          }

          if(payload.bankImgUrl !== rootState.user.artistData.bankImgUrl) {
            payload.certUpdateInfo += ' | 통장'
          }

        }

        if(payload.liveMov && Array.isArray(payload.liveMov) && payload.liveMov.length && !payload.liveUrl ){
          const formData = {
            liveMov: [],
            liveUrl: [],
          }
          for (const index in payload.liveMov) {
            const row = payload.liveMov[index]
            formData.liveMov.push(row.image)
            formData.liveUrl.push(row.url)
          }
          payload.liveMov = formData.liveMov;
          payload.liveUrl = formData.liveUrl;
        }
        
        for (let key in payload) {
          let isNull = false
          let val = payload[key]

          if (['liveMov', 'liveUrl'].includes(key) && Array.isArray(val) && val.length) {
            for (const childVal of val) {
              data.append(`${key}[]`, childVal || '')
            }
            continue
          }

          if (val === null) isNull = true
          if (val == undefined || val == 'undefined') isNull = true

          switch (key) {
            case 'teamName':
              if (val === '') isNull = true
              break
          }

          if (isNull) delete payload[key]
          else {
            if(Object.keys(state.requestProfile).includes(key) ){
              data.append(key, payload[key])
            }
          }
        }

        const req = {
          method: 'post',
          url: '/artist/profile',
          data,
          timeout: 300000,
        }
        // const query = qs.parse(location.search.replace(/^\?/, ''))
        // const platform = query.platform || sessionStorage.getItem('platform')
        // const useridx = sessionStorage.getItem('useridx')
        // const usertoken = sessionStorage.getItem('userToken')
        // const httpParam = setHttp(req)

        // httpParam.headers.platform = platform ? platform : 'webapp'

        // if (useridx) {
        //   httpParam.headers.useridx = useridx
        // } else {
        //   delete httpParam.headers.useridx
        // }

        // if (usertoken) {
        //   httpParam.headers.usertoken = usertoken
        // } else {
        //   delete httpParam.headers.usertoken
        // }
        Vue.prototype.$http(req)
          .then(({ data }) => {
            log(...Object.values(req), data)

            const resultData = resultCheck(data.resultData)

            if (resultData.success) {
              if(data.response.saveData.artistidx){
                payload.artistidx = data.response.saveData.artistidx
              }

              if (payload.imgUrl1) {
                payload.teamImgUrl = payload.imgUrl1
              }

              let movData = []
              for (let i = 1; i <= 20; i++) {
                let movUrl = payload[`movUrl${i}`]
                if (movUrl) {
                  movData.push({
                    movUrl: movUrl
                  })
                }
              }
              if (movData.length > 0) {
                payload.movData = movData
              }

              if (Array.isArray(payload.liveMov) && Array.isArray(payload.liveUrl)) {
                // payload에 liveMov, liveUrl가 있으면 변환해줌
                const liveMov = []

                for (const index in payload.liveMov) {
                  const image = payload.liveMov[index]
                  const url = payload.liveUrl[index]

                  if (image && url) {
                    liveMov.push({
                      image,
                      url,
                    })
                  }
                }

                payload.liveMov = liveMov
                delete payload.liveUrl
              } else {
                // liveMov, liveUrl가 유효하지 않으면 삭제함
                if (payload.liveMov) {
                  delete payload.liveMov
                }

                if (payload.liveUrl) {
                  delete payload.liveUrl
                }
              }

              commit('setArtistData', payload)
            }

            resolve(resultData)
          })
          .catch(log.error)

      })
    },
    
    updateProfile({ rootState, commit, dispatch }, payload = {}) {
      return new Promise(resolve => {
        if (payload.imgUrl1) {
          payload.teamImgUrl = payload.imgUrl1
        }

        let movData = []
        for (let i = 1; i <= 20; i++) {
          let movUrl = payload[`movUrl${i}`]
          if (movUrl) {
            movData.push({
              movUrl: movUrl
            })
          }
        }
        if (movData.length > 0) {
          payload.movData = movData
        }

        if (Array.isArray(payload.liveMov) && Array.isArray(payload.liveUrl)) {
          // payload에 liveMov, liveUrl가 있으면 변환해줌
          const liveMov = []

          for (const index in payload.liveMov) {
            const image = payload.liveMov[index]
            const url = payload.liveUrl[index]

            if (image && url) {
              liveMov.push({
                image,
                url,
              })
            }
          }

          payload.liveMov = liveMov
          delete payload.liveUrl
        } else {
          // liveMov, liveUrl가 유효하지 않으면 삭제함
          if (payload.liveMov) {
            delete payload.liveMov
          }

          if (payload.liveUrl) {
            delete payload.liveUrl
          }
        }

        commit('setArtistData', payload)

        resolve(payload)
      })
      .catch(log.error);
    },
  },
  getters: {
  }
}
