var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "div",
        {
          class: {
            deepLavender: _vm.gnbBg === "deepLavender",
            transparent:
              _vm.$route.meta.title === "none" || _vm.gnbBg === "transparent"
          },
          style: {
            backgroundColor:
              _vm.$route.meta.title === "none"
                ? "rgba(255,255,255, " + _vm.gnbBgAlpha + ")"
                : null
          },
          attrs: { id: "gnb" }
        },
        [
          _c("div", { staticClass: "gnb-header" }, [
            _vm.isShow
              ? _c(
                  "div",
                  { staticClass: "gnb-left" },
                  [
                    !_vm.$route.meta.noLnb && _vm.$route.name === "home"
                      ? [
                          _c("icon", {
                            staticClass: "ui-btn",
                            attrs: {
                              src:
                                "img/common/menu-" +
                                (_vm.gnbBg === "deepLavender"
                                  ? "white"
                                  : "gray") +
                                ".png",
                              width: "3rem",
                              height: "3rem"
                            },
                            on: {
                              click: function() {
                                return _vm.openLnb()
                              }
                            }
                          })
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass: "ui-btn",
                              on: { click: _vm.gnbLeftBtnCb }
                            },
                            [
                              _vm.gnbLeftBtn === "close"
                                ? _c("icon", {
                                    staticStyle: { margin: "5px" },
                                    attrs: {
                                      src: "img/common/close-gray-button.png",
                                      width: "2rem",
                                      height: "2rem",
                                      cursor: "pointer"
                                    }
                                  })
                                : _vm.gnbLeftBtn !== "none"
                                ? _c("icon", {
                                    staticStyle: { margin: "0.25rem" },
                                    attrs: {
                                      src:
                                        "img/common/back-arrow-button" +
                                        (_vm.$route.meta.title === "none"
                                          ? _vm.gnbBgAlpha < 1
                                            ? "-w"
                                            : ""
                                          : "") +
                                        ".png",
                                      width: "2.5rem",
                                      height: "2.5rem",
                                      cursor: "pointer",
                                      cover: false
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "gnb-center ui-ellipsis" },
              [
                _vm.$store.state.gnbTitle || _vm.$route.meta.title
                  ? _c(
                      "router-link",
                      { attrs: { to: "/home" } },
                      [
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$lib.t_genre(_vm.$store.state.gnbTitle) ||
                                (_vm.$route.meta.title === "none"
                                  ? ""
                                  : _vm.$lib.t_genre(_vm.$route.meta.title))
                            )
                          )
                        ]
                      ],
                      2
                    )
                  : _c("router-link", { attrs: { to: "/home" } })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "gnb-right",
                class: [
                  {
                    "gnb-right-messageRoom": _vm.$route.name === "MessageRoom"
                  },
                  { "multi-btns": _vm.gnbRightBtn === "profile-main" }
                ]
              },
              [
                _vm.$route.name === "ClientRegisteredOffer"
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "ui-btn color-lavender",
                          style: {
                            position: "absolute",
                            width: "max-content",
                            right: 0
                          },
                          on: { click: _vm.gnbRightBtnCb }
                        },
                        [_vm._v(" 새로 문의하기 ")]
                      )
                    ]
                  : _vm.$route.name === "MessageRoom"
                  ? [
                      _vm.$store.state.gnbBtns.moreBtnInMessageRoomForArtist
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.gnbRightBtnCb("forArtist")
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  style: {
                                    marginLeft: "24px",
                                    marginTop: "2px"
                                  },
                                  attrs: {
                                    src: "img/message/more.png",
                                    width: "27px",
                                    height: "30px",
                                    cover: false,
                                    cursor: "pointer"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.gnbRightBtnCb("out")
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  style: {
                                    marginLeft: "24px",
                                    marginTop: "2px"
                                  },
                                  attrs: {
                                    src: "img/message/goout.png",
                                    width: "22px",
                                    height: "30px",
                                    cover: false,
                                    cursor: "pointer",
                                    "origin-url": ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                      _vm.$store.state.gnbBtns.phone
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "ui-btn",
                                on: {
                                  click: function() {
                                    return _vm.gnbRightBtnCb("phone")
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  style: {
                                    marginLeft: "24px",
                                    marginTop: "2px"
                                  },
                                  attrs: {
                                    src: "img/gnb/phone.png",
                                    width: "22px",
                                    height: "30px",
                                    cover: false,
                                    cursor: "pointer"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ]
                  : [
                      _vm.gnbRightBtn === "search"
                        ? _c("div", { staticClass: "ui-icon-search" }, [
                            _c("div", { staticClass: "ui-icon-search-circle" }),
                            _c("div", { staticClass: "ui-icon-search-bar" })
                          ])
                        : _vm._e(),
                      _vm.gnbRightBtn === "menu"
                        ? _c(
                            "div",
                            {
                              staticClass: "ui-icon-menu",
                              on: { click: _vm.gnbRightBtnCb }
                            },
                            [
                              _c("div", { staticClass: "ui-icon-menu-bar" }),
                              _c("div", { staticClass: "ui-icon-menu-bar" }),
                              _c("div", { staticClass: "ui-icon-menu-bar" })
                            ]
                          )
                        : _vm._e(),
                      _vm.gnbRightBtn === "my-profile"
                        ? _c(
                            "div",
                            {
                              staticClass: "ui-icon-my-profile",
                              on: { click: _vm.gnbRightBtnCb }
                            },
                            [_vm._v(" 저장 및 나가기 ")]
                          )
                        : _vm._e(),
                      _vm.gnbRightBtn === "close"
                        ? _c(
                            "div",
                            {
                              staticClass: "ui-btn",
                              on: { click: _vm.gnbRightBtnCb }
                            },
                            [
                              _c("icon", {
                                staticStyle: { margin: "5px" },
                                attrs: {
                                  src: "img/common/close-gray-button.png",
                                  width: "2rem",
                                  height: "2rem",
                                  cursor: "pointer"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.gnbRightBtn === "calc-fee"
                        ? _c(
                            "div",
                            {
                              staticClass: "ui-icon-my-profile",
                              on: { click: _vm.gnbRightBtnCb }
                            },
                            [
                              _c("icon", {
                                staticStyle: { margin: "5px" },
                                attrs: {
                                  src: "img/common/close-gray-button.png",
                                  width: "2rem",
                                  height: "2rem",
                                  cursor: "pointer"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.gnbRightBtn === "profile-main"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "ui-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.rightBtnCb("profile")
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    src: "img/gnb/profile.png",
                                    width: "3.2rem",
                                    height: "3.2rem"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "ui-btn",
                                on: {
                                  click: function($event) {
                                    return _vm.rightBtnCb("setting")
                                  }
                                }
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    src: "img/gnb/setting.png",
                                    width: "3.2rem",
                                    height: "3.2rem"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ]
              ],
              2
            )
          ]),
          /^\/offer\/post\/[0-9]{1,2}/.test(_vm.$route.path) &&
          _vm.$route.params.step > 1
            ? _c(
                "div",
                { staticClass: "gnb-body gnb-body-postOffer" },
                [
                  _c("ProgressBar", {
                    attrs: {
                      percent: _vm.$store.state.gnbBody.postOfferPercent
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }