import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'
import modal from './modal'
import modals from './modals'
import postOffer from './postOffer'
import tempArtist from './tempArtist'
import billData from './billData'
import keepAlive from './keepAlive'
import etc from './etc'
import artistEvent from './artistEvent'
import ngWord from './ngWord'
import { checkLocalhost, checkNgrok } from '../lib/globalLib'
import type {
  IGnbLeftBtn,
  IGnbRightBtn,
} from '../flow/index.js'

Vue.use(Vuex)

// let historyTimer = null

export default new Vuex.Store({
  state: {
    lnb: false,
    gnbTitle: null,
    gnbHeight: 90,
    gnbBg: null, // white | deepLavender | transparent
    gnbLeftBtn: null,
    gnbRightBtn: null,
    gnbLeftBtnCb: null,
    gnbRightBtnCb: null,
    gnbBody: {
      postOfferPercent: 0,
    },
    gnbBtns: {
      phone: false,
      moreBtnInMessageRoomForArtist: false,
    },
    history: {
      back: false,
      stack: [],
      isMulitPush: false,
    },
    loading: false,
    app: {
      width: 0,
      height: 0,
    },
    showProfileSelectModal: false,
    showProfileSelectFullModal: false,
  },
  modules: {
    user,
    modal,
    modals,
    postOffer,
    tempArtist,
    billData,
    keepAlive,
    etc,
    artistEvent,
    ngWord,
  },
  mutations: {
    setLnb(state, mode = 'toggle') {
      if (mode === 'toggle') {
        state.lnb = !state.lnb
      } else {
        state.lnb = mode === 'show' ? true : false
      }
    },

    setGnbHeight(state, height = 90) {
      state.gnbHeight = height
    },

    setGnbBg(state, color = null) {
      state.gnbBg = color
    },

    setGnbTitle(state, title = null) {
      state.gnbTitle = title
    },

    setGnbLeftBtnCb(state, cb = null) {
      if (cb) state.gnbLeftBtnCb = cb
      else state.gnbLeftBtnCb = null
    },

    setGnbRightBtnCb(state, cb = null) {
      if (cb) state.gnbRightBtnCb = cb
      else state.gnbRightBtnCb = null
    },

    setGnbLeftBtn(state, btn?: IGnbLeftBtn = null) {
      if (btn) state.gnbLeftBtn = btn
      else state.gnbLeftBtn = null
    },

    setGnbRightBtn(state, btn?: IGnbRightBtn = null) {
      if (btn) state.gnbRightBtn = btn
      else state.gnbRightBtn = null
    },

    setGnbBtns(state, btns = {}) {
      state.gnbBtns = {
        ...state.gnbBtns,
        ...btns,
      }
    },

    setHistory(state, payload = {}) {
      state.history = Object.assign(state.history, payload)
    },
    setHistoryStack(state, stack) {
      state.history.stack = stack
    },
    setLoading(state, loading = false) {
      state.loading = loading
    },
    setGnbBody(state, payload = {}) {
      if (payload.postOfferPercent >= 0) state.gnbBody.postOfferPercent = payload.postOfferPercent
    },
    setAppData(state, payload = {}) {
      if (payload.width) state.app.width = payload.width
      if (payload.height) state.app.height = payload.height
    },
    setProfileSelectModal(state, payload = false) {
      state.showProfileSelectModal = payload;
    },
    setProfileSelectFullModal(state, payload = false) {
      state.showProfileSelectFullModal = payload;
    }
  },
  actions: {
  },
  getters: {
    isLAN() {
      return checkLocalhost() || checkNgrok()
    },
    isShowProfileSelectModal(state) {
      return state.showProfileSelectModal;
    },
    isShowProfileSelectFullModal(state) {
      return state.showProfileSelectFullModal;
    }
  },
})
