import qs from 'querystring'
import store from '@/store'

export default {
  install(Vue, {router}) {
    const ignoreReg = [
      /^\/logout/,
    ]

    const mulitPush = (url, arr) => {
      if (ignoreReg.some(reg => reg.test(url))) {
        url = arr.shift()
      }

      if (/^https?:\/\//.test(url)) {
        location.href = url
      }

      if (url) {
        store.commit('setHistory', {
          isMulitPush: true,
        })

        router.push(url, () => {
          url = arr.shift()
          mulitPush(url, arr)
        })
        return
      }

      store.commit('setHistory', {
        isMulitPush: false,
      })
    }

    Vue.prototype.$historyStack = {
      mulitPush(url, arr) {
        return mulitPush(url, arr)
      },
      set(urls) {
        let historyStack = []
        if (typeof urls === 'object' && Array.isArray(urls)) {
          historyStack = historyStack.concat(urls)
        } else if (typeof urls === 'string' && urls) {
          historyStack.push(urls)
        }
        sessionStorage.setItem('historyStack', JSON.stringify(historyStack))
      },
      get: () => {
        if (!sessionStorage.getItem('userToken')) return

        let historyStack = sessionStorage.getItem('historyStack')
        let locationQS = qs.parse(location.search.replace(/^\?/, ''))

        historyStack = historyStack ? JSON.parse(historyStack) : []

        if (historyStack.length === 0 && location.pathname !== '/') {
          historyStack.push(location.pathname + location.search)
        }

        if (!/^\/home/.test(historyStack[0])) {
          historyStack.unshift('/home')
        }

        let lastUrl = ''
        let lastUrlKey = historyStack.length - 1 >= 0 ? historyStack.length - 1 : 0
        let lastUrlQS = qs.parse(historyStack[lastUrlKey].split('?').reduce((result, row, key) => {
          if (key === 0) lastUrl = row
          if (key === 1) result = row
          return result
        }, ''))
        let redirectQS = qs.stringify(Object.assign({}, lastUrlQS, locationQS))
        historyStack[lastUrlKey] = lastUrl + '?' + redirectQS

        mulitPush(historyStack.shift(), historyStack)
      },
    }
  }
}