var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-navBottom" },
    [
      _c(
        "div",
        {
          staticClass: "ui-navBottom-btn",
          class: { "ui-navBottom-btn-active": _vm.isActive("/home") },
          on: {
            click: function() {
              return _vm.gotoTab("/home")
            }
          }
        },
        [
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isActive("/home"),
                expression: "!isActive('/home')"
              }
            ],
            attrs: {
              src: "img/nav-bottom/home.png",
              width: "28px",
              height: "28px",
              cover: false
            }
          }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isActive("/home"),
                expression: "isActive('/home')"
              }
            ],
            attrs: {
              src: "img/nav-bottom/home-on.png",
              width: "28px",
              height: "28px",
              cover: false
            }
          }),
          _c("span", { staticClass: "text" }, [_vm._v("홈")])
        ],
        1
      ),
      _vm.isClient
        ? [
            _c(
              "div",
              {
                staticClass: "ui-navBottom-btn",
                class: {
                  "ui-navBottom-btn-active": _vm.isActive("/client/my-offer")
                },
                on: {
                  click: function() {
                    return _vm.gotoTab("/client/my-offer")
                  }
                }
              },
              [
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isActive("/client/my-offer"),
                      expression: "!isActive('/client/my-offer')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/human-outline.png",
                    width: "28px",
                    height: "28px",
                    cover: false,
                    "origin-url": ""
                  }
                }),
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("/client/my-offer"),
                      expression: "isActive('/client/my-offer')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/human-outline-on.png",
                    width: "28px",
                    height: "28px",
                    cover: false,
                    "origin-url": ""
                  }
                }),
                _c("span", { staticClass: "text" }, [_vm._v("MY")]),
                _vm.hasNewOfferPro
                  ? _c("div", { staticClass: "new" })
                  : _vm._e()
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass: "ui-navBottom-btn",
                class: {
                  "ui-navBottom-btn-active": _vm.isActive("/artist/my-offer")
                },
                on: {
                  click: function() {
                    return _vm.gotoTab("/artist/my-offer")
                  }
                }
              },
              [
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isActive("/artist/my-offer"),
                      expression: "!isActive('/artist/my-offer')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/paper.png",
                    width: "28px",
                    height: "28px",
                    cover: false
                  }
                }),
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("/artist/my-offer"),
                      expression: "isActive('/artist/my-offer')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/paper-on.png",
                    width: "28px",
                    height: "28px",
                    cover: false
                  }
                }),
                _c("span", { staticClass: "text" }, [_vm._v("MY 의뢰")]),
                _vm.hasNewDirectOffer
                  ? _c("div", { staticClass: "new" })
                  : _vm._e()
              ],
              1
            )
          ],
      _c(
        "div",
        {
          staticClass: "ui-navBottom-btn",
          class: { "ui-navBottom-btn-active": _vm.isActive("/communicate") },
          on: {
            click: function() {
              return _vm.gotoTab("/communicate")
            }
          }
        },
        [
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isActive("/communicate"),
                expression: "!isActive('/communicate')"
              }
            ],
            attrs: {
              src: "img/nav-bottom/dialog.png",
              width: "28px",
              height: "28px",
              cover: false,
              "origin-url": ""
            }
          }),
          _c("icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isActive("/communicate"),
                expression: "isActive('/communicate')"
              }
            ],
            attrs: {
              src: "img/nav-bottom/dialog-on.png",
              width: "28px",
              height: "28px",
              cover: false,
              "origin-url": ""
            }
          }),
          _c("span", { staticClass: "text" }, [_vm._v("채팅")]),
          _vm.hasNewMsg || _vm.hasNewQna
            ? _c("div", { staticClass: "new" })
            : _vm._e()
        ],
        1
      ),
      _vm.isClient
        ? [
            _c(
              "div",
              {
                staticClass: "ui-navBottom-btn",
                class: {
                  "ui-navBottom-btn-active": _vm.isActive("/client/fav")
                },
                on: {
                  click: function() {
                    return _vm.gotoTab("/client/fav")
                  }
                }
              },
              [
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isActive("/client/fav"),
                      expression: "!isActive('/client/fav')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/favorite.png",
                    width: "28px",
                    height: "28px",
                    cover: false,
                    "origin-url": ""
                  }
                }),
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("/client/fav"),
                      expression: "isActive('/client/fav')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/favorite-on.png",
                    width: "28px",
                    height: "28px",
                    cover: false,
                    "origin-url": ""
                  }
                }),
                _c("span", { staticClass: "text" }, [_vm._v("찜")])
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass: "ui-navBottom-btn",
                class: {
                  "ui-navBottom-btn-active": _vm.isActive("/artist/payment")
                },
                on: {
                  click: function() {
                    return _vm.gotoTab("/artist/payment")
                  }
                }
              },
              [
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isActive("/artist/payment"),
                      expression: "!isActive('/artist/payment')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/pay.png",
                    width: "28px",
                    height: "28px",
                    cover: false
                  }
                }),
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("/artist/payment"),
                      expression: "isActive('/artist/payment')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/pay-on.png",
                    width: "28px",
                    height: "28px",
                    cover: false
                  }
                }),
                _c("span", { staticClass: "text" }, [_vm._v("결제 내역")])
              ],
              1
            )
          ],
      _vm.isClient
        ? [
            _c(
              "div",
              {
                staticClass: "ui-navBottom-btn",
                class: { "ui-navBottom-btn-active": _vm.isActive("/setting") },
                on: {
                  click: function() {
                    return _vm.gotoTab("/setting")
                  }
                }
              },
              [
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isActive("/setting"),
                      expression: "!isActive('/setting')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/more.png",
                    width: "28px",
                    height: "28px",
                    cover: false
                  }
                }),
                _c("icon", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isActive("/setting"),
                      expression: "isActive('/setting')"
                    }
                  ],
                  attrs: {
                    src: "img/nav-bottom/more-on.png",
                    width: "28px",
                    height: "28px",
                    cover: false
                  }
                }),
                _c("span", { staticClass: "text" }, [_vm._v("더보기")])
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              {
                staticClass: "ui-navBottom-btn profile has-img",
                class: { "ui-navBottom-btn-active": _vm.isActive("/profile") },
                on: {
                  touchend: _vm.clearTimer,
                  touchstart: _vm.startTimer,
                  click: _vm.handleClick
                }
              },
              [
                _vm.profileImg
                  ? _c("div", [
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.isActive("/profile"),
                            expression: "!isActive('/profile')"
                          }
                        ],
                        staticClass: "profile-img",
                        style: {
                          backgroundImage: "url(" + _vm.profileImg + ")"
                        }
                      }),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isActive("/profile"),
                            expression: "isActive('/profile')"
                          }
                        ],
                        staticClass: "profile-img",
                        style: {
                          backgroundImage: "url(" + _vm.profileImg + ")"
                        }
                      })
                    ])
                  : _c(
                      "div",
                      [
                        _c("icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isActive("/profile"),
                              expression: "!isActive('/profile')"
                            }
                          ],
                          attrs: {
                            src: "img/nav-bottom/profile-no-img.png",
                            width: "34px",
                            height: "34px",
                            cover: false
                          }
                        }),
                        _c("icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isActive("/profile"),
                              expression: "isActive('/profile')"
                            }
                          ],
                          attrs: {
                            src: "img/nav-bottom/profile-no-img.png",
                            width: "34px",
                            height: "34px",
                            cover: false
                          }
                        })
                      ],
                      1
                    ),
                _c("span", { staticClass: "text" }, [_vm._v("프로필")]),
                _vm.hasNewAlarm ? _c("div", { staticClass: "new" }) : _vm._e()
              ]
            )
          ],
      _vm.isArtist
        ? _c(
            "div",
            {
              staticClass: "tooltip-profile",
              class: {
                hide: this.aprvProfiles.length < 1 || _vm.tooltipShow == false
              }
            },
            [
              _c("div", { staticClass: "tooltip-profile-inner" }, [
                _c("button", {
                  staticClass: "tooltip-profile-close",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.hideTooltip()
                    }
                  }
                }),
                _vm._m(0)
              ])
            ]
          )
        : _vm._e(),
      _vm.showProfileSelectModal
        ? _c("ProfileSelect", {
            attrs: {
              profiles: _vm.aprvProfiles || [],
              hasNewAlarm: _vm.alarmList || []
            },
            on: {
              close: _vm.modalClose,
              "open-full": _vm.fullModal,
              submit: function() {
                return _vm.nextStep()
              }
            }
          })
        : _vm._e(),
      _vm.showProfileSelectFullModal
        ? _c("ProfileSelectFull", {
            attrs: {
              profiles: _vm.aprvProfiles || [],
              hasNewAlarm: _vm.alarmList || []
            },
            on: {
              close: _vm.modalFullClose,
              submit: function() {
                return _vm.nextStep()
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("프로필을 꾸~욱 누르면"),
      _c("br"),
      _vm._v("다른 프로필로 전환할 수 있어요!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }