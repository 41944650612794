export default {
  state: {
    count: 0,
  },
  mutations: {
    setNgWordCount(state, payload = 0) {
      state.count = payload
    },
  },
  actions: {

  },
  getters: {

  }
}
