<template>
  <footer-box
    :submit-text="`프로필 추가`"
    :modalShow="isShow"
    class="select-profile-modal"
    :modalCb="() => modalClose()"
    :submitCb="() => submit()"
    :modalFullCb="() => modalFullShow()"
    :draggAble="true"
  ><!-- 비활성화 case :submitDisabled="true" -->
    <template #modalBody>
      <div class="select-profile-wrap">
        <div class="select-profile-area">
          <div class="select-profile-tit">프로필 전환</div>
            <ul class="profile-select-list">
              <li v-for="(row, key) in profiles" :key="key">
                <input type="radio" :id="'profile'+(key + 1)" class="sr-only" name="profileChange" v-model="isPickPro" :value="row.isPickPro">
                <label :for="'profile'+(key + 1)" @click="() => select(row)">
                  <div class="img-area">
                    <div class="img"
                        :style="{
                        backgroundColor: 'lightgrey',
                        backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl || row.imgUrl1)})`
                      }"
                    >
                    </div>
                    <div v-if="hasAnyTrueValue(row.artistidx)" class="new"></div>
                  </div>
                  <div class="profile-info">
                    <div class="userNickName">{{row.teamName}}</div>
                    <div class="genre">{{row.genre}}</div>
                  </div>
                  <div class="radio-btn"></div>
                </label>
              </li>
            </ul>
        </div>
      </div>
    </template>
    <template #submitLeftSlot>
      <icon
        slot="submitLeftSlot"
        src="img/common/plus-white.png"
        width="3.2rem"
        height="3.2rem"
        :cover="false"
        :style="{
            display: 'inline-block',
            marginBottom: '-8px',
        }"
      />
    </template>
  </footer-box>
</template>

<script>
import FooterBox from '@/components/common/FooterBox.vue'
import Icon from '@/components/common/Icon'

export default {
  name: 'ProfileSelect',
  components: {
      FooterBox,
      Icon
  },
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    profiles: {
      type: Array,
      default: ()=>{
          return [];
      },
    },
    hasNewAlarm: {
      type: Array,
      default: ()=>{
          return [];
      },
    },
  },
  
  data: () => ({
      selectProfile: {},
      isPickPro: 1
  }),
  created() {
  },
  methods: {
      modalFullShow() {
        this.$emit('open-full');
      },
      modalClose() {
        this.$emit('close');
      },
      submit() {
          this.$emit('submit', this.selectProfile);
      },
      // 프로필 목록 선택 이벤트 -> 바텀시트 닫기 + 토스트 팝업 노출
      select(value) {
        const req = {
          method: 'post',
          url: `/artist/change/pickPro/${value.artistidx}`,
        }
        return this.$http(req)
          .then(({ data }) => {
            setTimeout(() => {
              this.modalClose()
              this.$toast(`[${value.teamName} | ${value.genre}] 으로 프로필이 전환되었습니다.`)
            }, 100)
            return
            })
          .catch((e) => {
            log.error(e)
            return
          })
      },
      hasAnyTrueValue(artistidx) {
        const artistAlarm = this.hasNewAlarm.find(item => item.artistidx === artistidx);
        if (!artistAlarm) {
          return false; // 해당 artistidx를 가진 객체가 없으면 false 반환
        }
        // 객체의 속성 중 하나라도 '1'이면 true 반환
        return Object.values(artistAlarm).slice(1).some(value => value === "1");
      }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.select-profile-modal {
  .select-profile-wrap {
    padding: 0 3rem;
    .select-profile-area {
      .select-profile-tit {
        margin-top: 1.2rem;
        margin-bottom: 3rem;
        font-size: 2.8rem;
        font-weight: 600;
        text-align: center;
      }
      .profile-select-list {
        overflow: scroll;
        max-height: 47.6rem;
        li {
          & ~ li {
            margin-top: 1.2rem;
          }
          input {
            &:checked {
              & + label {
                background-color: #F7F8FE;
                .radio-btn {
                  position: relative;
                  width: 2.8rem;
                  height: 2.8rem;
                  &::before {
                    border: 1px solid #AB54BB;
                  }
                  &::after {
                    background-color: #AB54BB;
                  }
                }
              }
            }
          }
          label {
            display: flex;
            align-items: center;
            gap: 2rem;
            margin: 0;
            padding: 2.2rem 3rem;
            border-radius: 1.2rem;
            .img-area {
              position: relative;
              .img {
                overflow: hidden;
                flex-shrink: 0;
                width: 6.6rem;
                height: 6.6rem;
                border-radius: 11rem;
                border: 1px solid #ededed;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
              }
              .new {
                position: absolute;
                top: 0;
                right: 2px;
                width: 1.2rem;
                height: 1.2rem;
                border: 2px solid #fff;
                border-radius: 50%;
                background-color: #F33D12;
              }
            }
            
            .profile-info {
              flex: 1;
              .userNickName {
                  color: #000;
                  font-size: 2rem;
                  font-weight: 600;
              }
              .genre {
                  margin-top: 0.5rem;
                  color: #666666;
                  font-size: 1.8rem;
              }
            }
          }
          .radio-btn {
            position: relative;
            width: 2.8rem;
            height: 2.8rem;
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border: 1px solid #A8A8A8;
              border-radius: 2.8rem;
              content: '';
            }
            &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 1.4rem;
              height: 1.4rem;
              border-radius: 2.8rem;
              transform: translate(-50%, -50%);
              content: '';
            }
          }
        }
      }
    }
  }
}
.draggable {
  z-index: 100;
}
</style>
