const init = {
  show: false,
  title: undefined,
  body: '',
  bodyType: 'text', // text, image
  bodyCb: null,
  textAlign: 'text-center', // text-left, text-center, text-right
  confirm: '확인',
  confirmCb: null,
  useCancel: true,
  cancel: undefined,
  cancelCb: null,
  showCb: null,
  shownCb: null,
  hideCb: null,
  hiddenCb: null,
  useFooter: true,
  hideOnbackdrop: false,
  maxWidth: undefined,
  noBorder: false,
  bodyMaxHeight: undefined,
}

export default {
  state: Object.assign({}, init),
  mutations: {
    setModal(state, data = init) {
      if (!data) data = init
      state = Object.assign(state, init, data)
    },
  },
  actions: {

  },
  getters: {

  }
}