var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-up-down" } }, [
    _vm.isShow
      ? _c(
          "div",
          {
            staticClass: "page-modal",
            class: {
              "page-modal--hideFooter": _vm.hideFooter
            }
          },
          [
            _vm.useNavi
              ? _c("div", { staticClass: "page-modal-navi" }, [
                  _c(
                    "div",
                    {
                      staticClass: "d-inline-block ui-btn",
                      on: {
                        click: function() {
                          return _vm.hide()
                        }
                      }
                    },
                    [
                      _vm.closeIcon === "back"
                        ? [
                            _c("icon", {
                              staticStyle: { margin: "0.25rem" },
                              attrs: {
                                src: "img/common/back-arrow-button.png",
                                width: "2.5rem",
                                height: "2.5rem",
                                cursor: "pointer",
                                cover: false
                              }
                            })
                          ]
                        : [
                            _c("icon", {
                              attrs: {
                                src: "img/lnb/x.png",
                                width: "23px",
                                height: "30px",
                                cursor: "pointer",
                                cover: false
                              }
                            })
                          ]
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "page-modal-scroll" },
              [
                _vm.usingHeader
                  ? _c("div", { staticClass: "page-modal-header" }, [
                      _vm.title
                        ? _c("h3", [_vm._v(_vm._s(_vm.title))])
                        : _vm._e(),
                      _vm.subtitle
                        ? _c("p", [_vm._v(_vm._s(_vm.subtitle))])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm.useBorderLine
                  ? _c("div", {
                      staticClass: "ui-border-line",
                      staticStyle: { height: "10px" }
                    })
                  : _vm._e(),
                _vm._t("body", [_c("div", { staticClass: "page-modal-body" })])
              ],
              2
            ),
            _vm._t("default")
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }