<template>
  <div
    v-if="mounted"
    class="container intro"
    :style="{
      backgroundColor: 'transparent',
    }"
  >
    <div class="logo-box">
      <h1 class="logo">Heybeagle</h1>
    </div>
    <div>
      <div class="text-center mb-4">
        <input v-model="form.snsUid" type="text" placeholder="snsUid">
      </div>

      <button
        type="button"
        class="btn btn-default btn-block btn-lg"
        @click="() => this.serviceStart('naver', form.snsUid || '36656182')"
      >
        네이버로 시작
      </button>

      <button
        type="button"
        class="btn btn-default btn-block btn-lg"
        @click="() => this.serviceStart('apple', form.snsUid || '36656182')"
      >
        애플로 시작
      </button>

      <button
        type="button"
        class="btn btn-default btn-block btn-lg"
        @click="() => this.serviceStart('kakao', form.snsUid || '812754446')"
      >
        카카오톡으로 시작
      </button>

      <button
        type="button"
        class="btn btn-default btn-block btn-lg"
        @click="() => this.serviceStart('facebook', form.snsUid || '1615235288584270')"
      >
        페이스북으로 시작
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'intro',
  data() {
    return {
      mounted: false,
      form: {
        snsUid: '',
      },
    }
  },
  computed: {
    userState() {
      return this.$store.state.user
    },
    query() {
      return this.$route.query
    },
  },
  beforeMount() {
    const sns = this.query.sns || localStorage.getItem('sns')
    const snsUid = this.query.snsUid || localStorage.getItem('snsUid')
    const imgUrl = this.query.imgUrl || null
    const platform = this.query.platform || sessionStorage.getItem('platform') || 'webapp'
    const nickName = this.query.nickName || ''
    const pushToken = this.query.pushToken || ''
    const name = this.query.name || localStorage.getItem('name')
    const phone = this.query.phone || localStorage.getItem('phone')

    /**
     * queryString에 snsUid, sns가 있으면 join으로 이동
     * @example /?snsUid=chatest1&sns=kakao
     */
    if (sns && snsUid) {
      this.serviceStart(sns, snsUid, pushToken, platform, nickName, imgUrl, name, phone)
      return
    }

    // 인트로 화면은 로컬에서만 접속할 수 있습니다.
    if (platform === 'webapp' && this.$store.getters.isLAN) {
      this.mounted = true
      return
    }

    // 로그아웃처리
    localStorage.clear()
    this.$store.dispatch('logout', () => {
      // 앱인 경우 로그인으로 이동됨
      location.href = 'hbscheme://logout'

      // 웹인 경우 커스텀스킴이 실행되지 않고 웹버전으로 이동됨
      setTimeout(() => {
        location.href = 'http://heybeagle.co.kr'
      }, 500)
    })
  },
  methods: {
    serviceStart(sns, snsUid = this.$route.query.snsUid, pushToken = '', platform = 'webapp', nickName = '', imgUrl, name = this.$route.query.name, phone = this.$route.query.phone) {
      /**
       * 1. sns 로그인을 하여 snsUid를 받는다.
       * 2. login api 전송.
       * 3. response.userData가 비어있다.
       *  - snsUid, sns 정보를 담아서 회원가입으로 보낸다.
       *  - 회원가입 완료.
       * 4. 로그인 완료.
       */
      if ('useridx' in this.$store.state.user.userData) {
        this.$router.push('/home')
      } else {
        this.login(sns, snsUid, pushToken, platform, nickName, imgUrl, name, phone)
      }
    },
    login(sns, snsUid = this.$route.query.snsUid, pushToken = '', platform = 'webapp', nickName = '', imgUrl = '', name= this.$route.query.name, phone= this.$route.query.phone) {
      if (!snsUid) {
        this.$alert('잘못된 접근입니다.')
        return
      }

      this.$store
        .dispatch('login', { sns, snsUid, pushToken, platform })
        .catch((error) => {
          log.error(error)

          return {
            success: false,
            code: null,
            msg: '서버 오류가 발생하였습니다.',
          }
        })
        .then(result => {
          if (result.success) {
            if ('useridx' in this.userState.userData) {
              this.$router.push('/home')
            } else {
              this.$router.push({
                path: '/join',
                query: { sns, snsUid, pushToken, platform, nickName, imgUrl, name, phone },
              })
            }
            return
          } else if (result.msg) {
            this.$alert(result.msg)
          }
        })
    },
  },
}
</script>
