var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mounted
    ? _c(
        "div",
        {
          staticClass: "container intro",
          style: {
            backgroundColor: "transparent"
          }
        },
        [
          _vm._m(0),
          _c("div", [
            _c("div", { staticClass: "text-center mb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.snsUid,
                    expression: "form.snsUid"
                  }
                ],
                attrs: { type: "text", placeholder: "snsUid" },
                domProps: { value: _vm.form.snsUid },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "snsUid", $event.target.value)
                  }
                }
              })
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-block btn-lg",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return this$1.serviceStart(
                      "naver",
                      _vm.form.snsUid || "36656182"
                    )
                  }
                }
              },
              [_vm._v(" 네이버로 시작 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-block btn-lg",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return this$1.serviceStart(
                      "apple",
                      _vm.form.snsUid || "36656182"
                    )
                  }
                }
              },
              [_vm._v(" 애플로 시작 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-block btn-lg",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return this$1.serviceStart(
                      "kakao",
                      _vm.form.snsUid || "812754446"
                    )
                  }
                }
              },
              [_vm._v(" 카카오톡으로 시작 ")]
            ),
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-block btn-lg",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return this$1.serviceStart(
                      "facebook",
                      _vm.form.snsUid || "1615235288584270"
                    )
                  }
                }
              },
              [_vm._v(" 페이스북으로 시작 ")]
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-box" }, [
      _c("h1", { staticClass: "logo" }, [_vm._v("Heybeagle")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }