var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "app", attrs: { id: "app" } },
    [
      !_vm.$route.meta.noGnb ? _c("Gnb") : _vm._e(),
      !_vm.$route.meta.nolnb ? _c("lnb") : _vm._e(),
      _c(
        "div",
        {
          ref: "page",
          style: {
            backgroundColor: _vm.backgroundColor
          },
          attrs: { id: "page" }
        },
        [
          _c(
            "transition",
            {
              attrs: {
                name: _vm.transitionOptions.name,
                duration: _vm.transitionOptions.duration
              }
            },
            [
              _c(
                "keep-alive",
                {
                  attrs: {
                    max: _vm.keepAlive.max,
                    include: _vm.keepAlive.include
                  }
                },
                [
                  _c("router-view", {
                    class: [
                      "gnb-h-" + _vm.gnbHeight,
                      {
                        "use-navBottom": _vm.$route.meta.useNavBottom
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$route.meta.useNavBottom ? _c("NavBottom") : _vm._e(),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            title: _vm.modal.title,
            bodyCb: _vm.modal.bodyCb,
            confirm: _vm.modal.confirm,
            confirmCb: _vm.modal.confirmCb,
            cancel: _vm.modal.cancel,
            cancelCb: _vm.modal.cancelCb,
            useCancel: _vm.modal.useCancel,
            useFooter: _vm.modal.useFooter,
            showCb: _vm.modal.showCb,
            shownCb: _vm.modal.shownCb,
            hideCb: _vm.modal.hideCb,
            hiddenCb: function() {
              if (_vm.modal.hiddenCb) {
                _vm.modal.hiddenCb()
              }
              _vm.$store.commit("setModal", false)
            },
            hideOnbackdrop: _vm.modal.hideOnbackdrop,
            maxWidth: _vm.modal.maxWidth,
            noBorder: _vm.modal.noBorder,
            bodyMaxHeight: _vm.modal.bodyMaxHeight
          }
        },
        [
          _vm.modal.bodyType === "text"
            ? _c("div", {
                class: _vm.modal.textAlign || "text-center",
                style: {
                  whiteSpace: "pre-line",
                  padding: "2rem 1rem"
                },
                attrs: { slot: "body" },
                domProps: { innerHTML: _vm._s(_vm.modal.body) },
                slot: "body"
              })
            : _vm.modal.bodyType === "image"
            ? _c("img", {
                attrs: { slot: "body", src: _vm.modal.body, alt: "image" },
                slot: "body"
              })
            : _vm._e()
        ]
      ),
      _c(
        "modal",
        {
          ref: "NetworkModal",
          attrs: {
            confirm: "다시시도",
            confirmCb: function() {
              return _vm.networkModalConfirmCb()
            },
            cancel: "완료",
            hiddenCb: function() {
              _vm.$store.commit("setNetworkModal", false)
            },
            "max-width": "42rem"
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c(
              "div",
              {
                staticClass: "w-100",
                style: { display: "block", textAlign: "center" }
              },
              [
                _c("icon", {
                  style: { margin: "2rem auto 0" },
                  attrs: {
                    src: "img/common/error_ico.png",
                    width: "10rem",
                    height: "10rem",
                    cover: false
                  }
                }),
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    style: {
                      whiteSpace: "pre-line",
                      marginTop: "3rem",
                      fontWeight: 500,
                      fontSize: "3.2rem",
                      color: "#000"
                    }
                  },
                  [_vm._v(_vm._s("네트워크가 연결되지\n않았습니다."))]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "text-center",
                style: {
                  whiteSpace: "pre-line",
                  padding: "3rem 0",
                  fontSize: "2.4rem"
                }
              },
              [_vm._v("확인 후 다시 시도해주세요.")]
            )
          ])
        ]
      ),
      _c(
        "page-modal",
        { ref: "ngWordModal", attrs: { useNavi: false, hideFooter: "" } },
        [
          _c(
            "div",
            {
              staticClass: "page-modal-body ngWordModal",
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _c(
                "div",
                { staticClass: "ngWordModal-header" },
                [
                  _c("div", { staticClass: "ngWordModal-siren" }, [
                    _c("img", {
                      attrs: { src: _vm.$lib.imgUrl("img/ng-word/siren.png") }
                    })
                  ]),
                  _c("h1", [_vm._v("정책 위반 경고")]),
                  _vm.isClient
                    ? [
                        _c("p", [
                          _vm._v(" 결제 전 연락처, 카카오톡, SNS, 이메일 등"),
                          _c("br"),
                          _vm._v(" 외부 채널의 교환은 불가해요!"),
                          _c("br"),
                          _c("br"),
                          _c("b", [_vm._v("재시도 시")]),
                          _c("br"),
                          _c("b", [_vm._v("등록한 의뢰 및 문의가 삭제")]),
                          _vm._v("됩니다. ")
                        ])
                      ]
                    : [
                        _vm.ngWordCount === 1
                          ? _c("p", [
                              _vm._v(
                                " 결제 전 연락처, 카카오톡, SNS, 이메일 등"
                              ),
                              _c("br"),
                              _vm._v(" 외부 채널의 교환은 불가해요!"),
                              _c("br"),
                              _c("br"),
                              _c("b", [_vm._v("재시도 시 바로 이용정지")]),
                              _vm._v("됩니다. ")
                            ])
                          : _c("p", [
                              _vm._v(" 운영정책 위반 누적으로"),
                              _c("br"),
                              _vm._v(" 이용정지 처리되었습니다."),
                              _c("br"),
                              _c("br"),
                              _c("b", [_vm._v("경고 누적 시 탈퇴 및 퇴출")]),
                              _vm._v("됩니다. ")
                            ])
                      ]
                ],
                2
              ),
              _c("div", { staticClass: "ui-border-line ui-h-1" }),
              _c("div", { staticClass: "ngWordModal-qna" }, [
                _c("h1", [_vm._v("자주 묻는 질문")]),
                _c(
                  "ul",
                  [
                    _vm.isClient
                      ? [
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(
                                " Q. 오프라인 미팅이 필요할 때는 어떻게 하나요? "
                              )
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 인터뷰, 회의 등으로 오프라인 미팅이 불가피하다면"
                              ),
                              _c("br"),
                              _vm._v(" 헤이비글 고객센터로 연락주세요. ")
                            ])
                          ]),
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(
                                " Q. 프로필 외 추가 자료를 받고 싶을 때는 어떻게 하나요? "
                              )
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 연락처를 교환할 필요 없이 자료를 요청하시면"
                              ),
                              _c("br"),
                              _vm._v(
                                " 어플 또는 담당자를 통해 자료를 전달드립니다. "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(
                                " Q. 연락처 교환을 하지 않았는데 경고가 나왔어요. "
                              )
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 특정 키워드 사용 시 자동 경고, 이용정지 처리됩니다."
                              ),
                              _c("br"),
                              _vm._v(
                                " 가급적 연락 수단, 외부 채널명 등의 사용은 피해주세요."
                              ),
                              _c("br"),
                              _vm._v(
                                " (정상 이용한 경우 등록한 내용은 삭제되지 않아요.) "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(" Q. 결제되었는데 연락처 교환이 안돼요. ")
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 결제된 후에는 채팅하기를 통해 소통하세요."
                              ),
                              _c("br"),
                              _vm._v(" (채팅 : MY > 결제내역 > 채팅하기)"),
                              _c("br"),
                              _vm._v(" 답변하기에서는 연락처 교환이 불가해요. ")
                            ])
                          ])
                        ]
                      : [
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(
                                " Q. 의뢰인이 연락처를 요구할 때는 어떻게 하나요? "
                              )
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 의뢰인 요구에 의한 교환도 정책위반에 해당됩니다."
                              ),
                              _c("br"),
                              _vm._v(
                                " 문의하기 또는 채팅하기를 통한 소통을 요청하세요."
                              ),
                              _c("br"),
                              _vm._v(
                                " 사전 연락이 불가피한 경우에는 고객센터로 연락주세요. "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(
                                " Q. 연락처 교환을 하지 않았는데 경고가 나왔어요. "
                              )
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 특정 키워드 사용 시 자동 경고, 이용정지 처리됩니다."
                              ),
                              _c("br"),
                              _vm._v(
                                " 가급적 연락 수단, 외부 채널명 등의 사용은 피해주세요."
                              ),
                              _c("br"),
                              _vm._v(
                                " (고객센터로 연락주시면 빠른 시간 내에 복원해드려요.) "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(
                                " Q. 자료(프로필, 샘플)를 보내야 할 때는 어떻게 하나요? "
                              )
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 자료가 파일인 경우에는 고객센터로 전달을 요청하세요."
                              ),
                              _c("br"),
                              _vm._v(" 링크로 전달이 가능한 경우에는"),
                              _c("br"),
                              _vm._v(
                                " 답변하기나 채팅창에 직접 입력하시면 됩니다."
                              ),
                              _c("br"),
                              _vm._v(
                                " (단, 링크된 화면에 연락처가 있을 경우 정책위반에 해당) "
                              )
                            ])
                          ]),
                          _c("li", [
                            _c("div", { staticClass: "qna-item qna-q" }, [
                              _vm._v(" Q. 결제되었는데 연락처 교환이 안돼요. ")
                            ]),
                            _c("div", { staticClass: "qna-item qna-a" }, [
                              _vm._v(
                                " A. 결제된 후에는 채팅하기를 통해 소통하세요."
                              ),
                              _c("br"),
                              _vm._v(" (채팅 : MY 의뢰 > 결제내역 > 채팅하기)"),
                              _c("br"),
                              _vm._v(" 답변하기에서는 연락처 교환이 불가해요. ")
                            ])
                          ])
                        ]
                  ],
                  2
                ),
                _c("div", { staticClass: "ngWordModal-helpcenter" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function() {
                          return _vm.$gotoWeb(
                            "http://pf.kakao.com/_yzxhwC/chat"
                          )
                        }
                      }
                    },
                    [_vm._v(" 고객센터 바로가기 ")]
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "ngWordModal-footer" },
                [
                  _c("footer-box", {
                    staticClass: "ngWordModal-footer-box",
                    attrs: {
                      submitText: "확인",
                      submitCb: function() {
                        return _vm.$refs.ngWordModal.hide()
                      },
                      isStatic: ""
                    }
                  })
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "PageModal",
        {
          ref: "youtubeModal",
          attrs: {
            hideCb: function() {
              _vm.youtubeModalStore.reset()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex align-items-center",
              style: {
                margin: "0 -3rem",
                height: "100%",
                background: "#000"
              },
              attrs: { slot: "body" },
              slot: "body"
            },
            [
              _vm.youtubeModalStore.youtubeId
                ? _c("youtube", {
                    ref: "youtube",
                    style: {
                      width: "100vw",
                      height: "56.25vw"
                    },
                    attrs: {
                      "video-id": _vm.youtubeModalStore.youtubeId,
                      "player-vars": {
                        rel: 0,
                        showinfo: 0,
                        controls: 0,
                        autoplay: 1
                      }
                    },
                    on: { ready: _vm.onYoutubeReady }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c("img-uploader", {
        ref: "ImgUploader",
        on: {
          response: function() {
            var args = [],
              len = arguments.length
            while (len--) args[len] = arguments[len]

            return _vm.$root.$emit.apply(
              _vm.$root,
              ["img-uploader:response"].concat(args)
            )
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }