<template>
  <div id="app" ref="app">
    <Gnb v-if="!$route.meta.noGnb"/>
    <lnb v-if="!$route.meta.nolnb"/>
    <div
      id="page"
      ref="page"
      :style="{
        backgroundColor,
      }"
    >
      <transition
        :name="transitionOptions.name"
        :duration="transitionOptions.duration"
      >
        <keep-alive
          :max="keepAlive.max"
          :include="keepAlive.include"
        >
          <router-view
            :class="[
              `gnb-h-${gnbHeight}`, {
                'use-navBottom': $route.meta.useNavBottom,
              },
            ]
            "
            />
        </keep-alive>
      </transition>
    </div>

    <NavBottom v-if="$route.meta.useNavBottom" />

    <modal
      ref="modal"
      :title="modal.title"
      :bodyCb="modal.bodyCb"
      :confirm="modal.confirm"
      :confirmCb="modal.confirmCb"
      :cancel="modal.cancel"
      :cancelCb="modal.cancelCb"
      :useCancel="modal.useCancel"
      :useFooter="modal.useFooter"
      :showCb="modal.showCb"
      :shownCb="modal.shownCb"
      :hideCb="modal.hideCb"
      :hiddenCb="() => {
        if (modal.hiddenCb) modal.hiddenCb()
        $store.commit('setModal', false)
      }"
      :hideOnbackdrop="modal.hideOnbackdrop"
      :maxWidth="modal.maxWidth"
      :noBorder="modal.noBorder"
      :bodyMaxHeight="modal.bodyMaxHeight"
    >
      <div
        v-if="modal.bodyType === 'text'"
        slot="body"
        :class="modal.textAlign || 'text-center'"
        :style="{
          whiteSpace: 'pre-line',
          padding: '2rem 1rem',
        }"
        v-html="modal.body"
      ></div>
      <img
        v-else-if="modal.bodyType === 'image'"
        slot="body"
        :src="modal.body"
        alt="image"
      >
    </modal>

    <modal
      ref="NetworkModal"
      confirm="다시시도"
      :confirmCb="() => networkModalConfirmCb()"
      cancel="완료"
      :hiddenCb="() => {
        $store.commit('setNetworkModal', false)
      }"
      max-width="42rem"
    >
      <div
        slot="body"
      >
        <div class="w-100" :style="{ display: 'block', textAlign: 'center' }">
          <icon src="img/common/error_ico.png" width="10rem" height="10rem" :cover="false" :style="{ margin: '2rem auto 0' }" />
          <h5 class="modal-title" :style="{
            whiteSpace: 'pre-line',
            marginTop: '3rem',
            fontWeight: 500 ,
            fontSize: '3.2rem',
            color: '#000',
          }"
          >{{`네트워크가 연결되지\n않았습니다.`}}</h5>
        </div>
        <div
          class="text-center"
          :style="{
            whiteSpace: 'pre-line',
            padding: '3rem 0',
            fontSize: '2.4rem',
          }"
        >확인 후 다시 시도해주세요.</div>
      </div>
    </modal>

    <page-modal
      ref="ngWordModal"
      :useNavi="false"
      hideFooter
    >
      <div
        slot="body"
        class="page-modal-body ngWordModal"
      >
        <div class="ngWordModal-header">
          <div class="ngWordModal-siren">
            <img :src="$lib.imgUrl('img/ng-word/siren.png')" />
          </div>

          <h1>정책 위반 경고</h1>

          <template v-if="isClient">
            <p>
              결제 전 연락처, 카카오톡, SNS, 이메일 등<br>
              외부 채널의 교환은 불가해요!<br><br>
              <b>재시도 시</b><br>
              <b>등록한 의뢰 및 문의가 삭제</b>됩니다.
            </p>
          </template>
          <template v-else>
            <p v-if="ngWordCount === 1">
              결제 전 연락처, 카카오톡, SNS, 이메일 등<br>
              외부 채널의 교환은 불가해요!<br><br>
              <b>재시도 시 바로 이용정지</b>됩니다.
            </p>
            <p v-else>
              운영정책 위반 누적으로<br>
              이용정지 처리되었습니다.<br><br>
              <b>경고 누적 시 탈퇴 및 퇴출</b>됩니다.
            </p>
          </template>
        </div>

        <div class="ui-border-line ui-h-1" />

        <div class="ngWordModal-qna">
          <h1>자주 묻는 질문</h1>

          <ul>
            <template v-if="isClient">
              <li>
                <div class="qna-item qna-q">
                  Q. 오프라인 미팅이 필요할 때는 어떻게 하나요?
                </div>
                <div class="qna-item qna-a">
                  A. 인터뷰, 회의 등으로 오프라인 미팅이 불가피하다면<br>
                  헤이비글 고객센터로 연락주세요.
                </div>
              </li>

              <li>
                <div class="qna-item qna-q">
                  Q. 프로필 외 추가 자료를 받고 싶을 때는 어떻게 하나요?
                </div>
                <div class="qna-item qna-a">
                  A. 연락처를 교환할 필요 없이 자료를 요청하시면<br>
                  어플 또는 담당자를 통해 자료를 전달드립니다.
                </div>
              </li>

              <li>
                <div class="qna-item qna-q">
                  Q. 연락처 교환을 하지 않았는데 경고가 나왔어요.
                </div>
                <div class="qna-item qna-a">
                  A. 특정 키워드 사용 시 자동 경고, 이용정지 처리됩니다.<br>
                  가급적 연락 수단, 외부 채널명 등의 사용은 피해주세요.<br>
                  (정상 이용한 경우 등록한 내용은 삭제되지 않아요.)
                </div>
              </li>

              <li>
                <div class="qna-item qna-q">
                  Q. 결제되었는데 연락처 교환이 안돼요.
                </div>
                <div class="qna-item qna-a">
                  A. 결제된 후에는 채팅하기를 통해 소통하세요.<br>
                  (채팅 : MY  > 결제내역 > 채팅하기)<br>
                  답변하기에서는 연락처 교환이 불가해요.
                </div>
              </li>
            </template>
            <template v-else>
              <li>
                <div class="qna-item qna-q">
                  Q. 의뢰인이 연락처를 요구할 때는 어떻게 하나요?
                </div>
                <div class="qna-item qna-a">
                  A. 의뢰인 요구에 의한 교환도 정책위반에 해당됩니다.<br>
                  문의하기 또는 채팅하기를 통한 소통을 요청하세요.<br>
                  사전 연락이 불가피한 경우에는 고객센터로 연락주세요.
                </div>
              </li>

              <li>
                <div class="qna-item qna-q">
                  Q. 연락처 교환을 하지 않았는데 경고가 나왔어요.
                </div>
                <div class="qna-item qna-a">
                  A. 특정 키워드 사용 시 자동 경고, 이용정지 처리됩니다.<br>
                  가급적 연락 수단, 외부 채널명 등의 사용은 피해주세요.<br>
                  (고객센터로 연락주시면 빠른 시간 내에 복원해드려요.)
                </div>
              </li>

              <li>
                <div class="qna-item qna-q">
                  Q. 자료(프로필, 샘플)를 보내야 할 때는 어떻게 하나요?
                </div>
                <div class="qna-item qna-a">
                  A. 자료가 파일인 경우에는 고객센터로 전달을 요청하세요.<br>
                  링크로 전달이 가능한 경우에는<br>
                  답변하기나 채팅창에 직접 입력하시면 됩니다.<br>
                  (단, 링크된 화면에 연락처가 있을 경우 정책위반에 해당)
                </div>
              </li>

              <li>
                <div class="qna-item qna-q">
                  Q. 결제되었는데 연락처 교환이 안돼요.
                </div>
                <div class="qna-item qna-a">
                  A. 결제된 후에는 채팅하기를 통해 소통하세요.<br>
                  (채팅 : MY 의뢰 > 결제내역 > 채팅하기)<br>
                  답변하기에서는 연락처 교환이 불가해요.
                </div>
              </li>
            </template>
          </ul>

          <div class="ngWordModal-helpcenter">
            <span
              @click="() => $gotoWeb('http://pf.kakao.com/_yzxhwC/chat')"
            >
              고객센터 바로가기
            </span>
          </div>
        </div>

        <div class="ngWordModal-footer">
          <footer-box
            class="ngWordModal-footer-box"
            submitText="확인"
            :submitCb="() => $refs.ngWordModal.hide()"
            isStatic
          />
        </div>
      </div>
    </page-modal>

    <PageModal
      ref="youtubeModal"
      :hideCb="() => {
        youtubeModalStore.reset()
      }"
    >
      <div
        slot="body"
        :style="{
          margin: '0 -3rem',
          height: '100%',
          background: '#000',
        }"
        class="d-flex align-items-center"
      >
        <youtube
          v-if="youtubeModalStore.youtubeId"
          ref="youtube"
          :video-id="youtubeModalStore.youtubeId"
          :player-vars="{ rel: 0, showinfo: 0, controls: 0, autoplay: 1 }"
          :style="{
              width: `100vw`,
              height: `56.25vw`,
          }"
          @ready="onYoutubeReady"
        />
      </div>
    </PageModal>

    <img-uploader ref="ImgUploader" @response="(...args) => $root.$emit('img-uploader:response', ...args)" />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import http from 'axios'
import Gnb from '@/components/common/Gnb'
import Lnb from '@/components/common/Lnb'
import Modal from '@/components/common/Modal'
import Icon from '@/components/common/Icon'
import NavBottom from '@/components/common/NavBottom'
import ImgUploader from '@/components/utils/ImgUploader'
import PageModal from '@/components/common/PageModal'
import FooterBox from '@/components/common/FooterBox'
import { useYoutubeModalStore } from '@/pinia/modules/youtube-modal'
import ChannelService from './plugins/ChannelService'

window.pushClick = function() { return false }

export default defineComponent({
  name: 'app',
  components: {
    Gnb,
    Lnb,
    Modal,
    Icon,
    NavBottom,
    ImgUploader,
    PageModal,
    FooterBox,
  },
  metaInfo() {
    return {
      meta: [
        // { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        // { name: 'viewport', content: 'width=540, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=0' },
        { name: 'viewport', content: 'width=540, maximum-scale=1, user-scalable=0' },
      ]
    }
  },
  data() {
    return {
      transition: 'fade',
      historyTimer: null,
      mainPath: [
        '/home',
        '/client/my-offer',
        '/artist/my-offer',
        '/artist/direct-offer',
        '/etc/qna',
        '/communicate',
        '/message/list',
        '/artist/payment',
        '/client/fav',
        '/setting',
        '/profile',
      ],
    }
  },
  computed: {
    gnbHeight() {
      if (this.$route.meta.noGnb || this.$route.meta.title === 'none') return 0
      return this.$store.state.gnbHeight
    },
    // footerBoxHeight() {
    //   return this.$route.meta.useFooterBox ? 100 : 0
    // },
    userState() {
      return this.$store.state.user
    },
    modal() {
      return this.$store.state.modal
    },
    backgroundColor() {
      let backgroundColor = this.$route.meta.backgroundColor || '#fff'
      // if (this.userData.category === 'client' && this.$route.name === 'home') {
      //   backgroundColor = '#a156b8'
      // }
      return backgroundColor
    },
    keepAlive() {
      return this.$store.getters.keepAlive({
        route: this.$route,
      })
    },
    isArtist: {
      get() {
        return this.userData.category === 'artist'
      },
    },
    mainProfile: {
      get() {
        return this.$store.state.user.mainProfile
      },
    },
    isTriedNewMembership() {
      return this.$store.getters.isTriedNewMembership
    },
    isPlusMemberTargetArtist() {
      return this.$store.getters.isPlusMemberTargetArtist
    },
    userData() {
      return this.$store.state.user.userData
    },
    isClient() {
      return this.userData.category === 'client'
    },
    ngWordCount() {
      return this.$store.state?.ngWord?.count || 0
    },
    transitionOptions() {
      const isMulitPush = this.$store.state?.history?.isMulitPush ?? false

      if (isMulitPush || this.$route.name == 'joinInput') {
        return {
          name: undefined,
          duration: 0,
        }
      }

      return {
        name: this.transition,
        duration: 300,
      }
    },
    youtubeModalStore() {
      return useYoutubeModalStore()
    },
  },
  watch: {
    $route(to, from) {
      const guestPage = this.$config.constant.guestPages.some(path => path.test(to.path))

      this.transition = this.setTransition(to, from)

      if (!guestPage && !this.userData.hasOwnProperty('useridx')) {
        this.$router.push('/logout')
        return
      } else if (from.name === 'join' && to.name === 'intro') {
        this.$router.push('/logout')
        return
      }

      if (to.path !== from.path && this.mainPath.indexOf(to.path) !== -1) {
        if(to.path !== '/setting' && (!sessionStorage.getItem('useridx') || !sessionStorage.getItem('userToken'))){
            setTimeout(() => {
                this.getEtcMain();
            }, 350)
        }else {
            this.getEtcMain();
        }
      }

      if(to.path !== from.path && to.path.indexOf('/home') !== -1 ) {
        console.log('');
      } else {
        if(typeof window.ChannelIO == 'function') {
          ChannelService.setPage(this.$route);
          ChannelService.track('PageView'); 
        }
      }

      this.setGA(to)
      this.checkPayment()
      this.checkApplyOffer()
    },
    '$store.state.modal.show'(isShow) {
      if (isShow) {
        this.$refs.modal.show()
      } else {
        this.$refs.modal.hide()
      }
    },
    '$store.state.modals.networkModal'(isShow) {
      if (isShow) {
        this.$refs.NetworkModal.show()
      } else {
        this.$refs.NetworkModal.hide()
      }
    },
  },
  beforeMount() {
    this.$store.commit('setAppData', {
      // width: window.outerWidth,
      // height: window.outerHeight,
      width: window.innerWidth,
      height: window.innerHeight,
    })
  },
  mounted() {
    const sns = localStorage.getItem('sns')
    const snsUid = localStorage.getItem('snsUid')
    const useridx = sessionStorage.getItem('useridx')
    const usertoken = sessionStorage.getItem('userToken')
    const hasLogin = sns && snsUid && useridx && usertoken

    // 테스트를 위한 강제 로그인.
    if (process.env.NODE_ENV === 'development') {
      /**
       * 로그인 url
       * @param {'naver'|'kakao'|'facebook'} sns
       * @param {string|number} snsUid
       */
      let doLogin = (sns, snsUid) => window.location.href = getLoginUrl(sns, snsUid)
      let getLoginUrl = (sns, snsUid) => `${window.location.origin}?sns=${sns}&snsUid=${snsUid}`
      let logMsg = '%c로그인하기 %c\n\n'
      logMsg += `client :\n${getLoginUrl('naver', 36656182)}\n\n`
      logMsg += `artist :\n${getLoginUrl('kakao', 812754446)}\n\n`
      logMsg += `다른 아이디 : doLogin('naver'|'kakao'|'facebook', snsUid)`
      console.log(logMsg, 'color: #0066cc', '')
      window.doLogin = doLogin
    }

    this.checkUserLogin(this.userState)
    this.$nextTick(() => {
      this.setGA(this.$route)
    })

    window.addEventListener('popstate', () => {
      // 뒤로가기시 모달 전부 닫음.
      $('.modal').modal('hide')

      if (this.$store.state.history.back) clearTimeout(this.historyTimer)
      this.$store.commit('setHistory', { back: true })
      this.historyTimer = setTimeout(() => {
        this.$store.commit('setHistory', { back: false })
      }, 500)
    })

    this.$root.$on('img-uploader:request', (uid, option) => {
      this.$refs.ImgUploader.$emit('request', uid, option)
    })

    this.$store.subscribe((mutation) => {
      const { type, payload } = mutation

      switch (type) {
        case 'setNgWordCount': {
          if (payload > 0) {
            this.$refs.ngWordModal.show()
          }
          break
        }
      }
    })

    if (process.env.NODE_ENV === 'development') {
      this.$toast(`빌드시간 : ${DEPLOY_DATE}`)
    }

    if (location.pathname !== '/deep-link') {
      this.$historyStack.get()
    }

    useYoutubeModalStore().$onAction(async (arg) => {
      switch (arg.name) {
        case 'openByUrl':
        case 'openById':
          await this.$nextTick()
          this.$refs.youtubeModal?.show?.()
          break;

        case 'close':
          this.$refs.youtubeModal?.hide?.()
          break;
      }
    })

    this.checkAppData()

    window.pushClick = this.pushClickEvent;
  },
  unmounted() {
    window.removeEventListener('pushClick', this.pushClickEvent);
  },
  methods: {
    checkUserLogin(userState = this.userState) {
      const guestPage = this.$config.constant.guestPages.some(path => path.test(location.pathname))

      if (!('useridx' in userState.userData) && !guestPage) {
        this.$router.push('/logout')
      }

      if ('useridx' in userState.userData && guestPage && this.$route.name === 'intro') {
        this.$router.push('/home')
      }
    },
    networkModalConfirmCb() {
      window.location.reload()
    },
    setTransition(to, from) {
      if (from?.meta?.transition === 'slide-up') {
        return 'slide-down'
      }

      if (this.$route.meta.transition) {
        return this.$route.meta.transition
      }

      const { state, commit } = this.$store
      let stack: [] = state.history.stack
      let toPath = to.path
      let toPathIdx = stack.indexOf(toPath)
      let transition = 'slide-left'

      // NavBottom에 등록된 페이지끼리 이동시 사용
      let mainToPathIdx = this.mainPath.indexOf(to.path)
      let mainFromPathIdx = this.mainPath.indexOf(from.path)

      // NavBottom에 등록된 페이지끼리 이동시
      if (mainToPathIdx !== -1 && mainFromPathIdx !== -1)
      {
        stack = [to.path]
        transition = mainToPathIdx > mainFromPathIdx ? 'slide-left' : 'slide-right'
      }
      // 첫 페이지로 이동시
      else if (['/', '/home'].indexOf(toPath) !== -1)
      {
        stack = []
        transition = 'slide-right'
        if (from.path === '/' || from.path === '/home') {
          transition = 'slide-left'
        }
      }
      // 뒤로가기시
      else if (toPathIdx !== -1)
      {
        stack.splice(toPathIdx, 100)
        stack.push(toPath)
        transition = 'slide-right'
      }
      // 새로운 페이지로 이동시
      else
      {
        stack.push(toPath)
        transition = 'slide-left'
      }

      commit('setHistoryStack', stack)
      this.$historyStack.set(stack)
      return transition
    },
    setGA(route) {
      if (process.env.NODE_ENV !== 'production') return
      gtag('config', 'UA-87465579-5', {
        'page_title': route.meta.gaTitle,
        'page_path': route.path,
      })
      if (route.meta.gaTitle) {
        gtag('event', 'visited:' + (this.$store.state.user.userData.category || 'guest'), {
          'event_category': route.meta.gaTitle || route.path,
          'event_label': route.fullPath,
          'value': 1,
        })
      }
    },
    getEtcMain() {
      const req = {
        method: 'get',
        url: `/etc/main`,
      }

      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = this.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            let billData = data.response.billData
            let popupData = data.response.popupData

            if (billData) {
              this.$store.commit('setBillData', billData)
            }

            this.$store.commit('setEtcMain', data.response)

            this.showPopup(popupData)

            this.$eventBus.$emit('getEtcMain', {
              ...data,
              resultData: {
                ...resultData,
              },
            })
          } else {
            this.$alert(resultData)
          }
        })
        .catch(log.error)
    },
    checkAppData() {
      let deployVersion = localStorage.getItem('deployVersion') || DEPLOY_VERSION
      let timer = setInterval(() => {
        http.get('/app-data.json').then(({data}) => {
          if (data.version !== deployVersion) {
            clearInterval(timer)
            this.$store.commit('setModal', {
              show: true,
              title: '업데이트 안내',
              body: '서비스는 계속 이용하실 수 있지만 원활한 이용을 위해 재실행을 권장합니다.',
              textAlign: 'text-left',
              confirm: '재실행',
              confirmCb: () => {
                window.location.reload(true)
              },
              cancel: '계속 이용',
            })
          }
        }).catch(() => {
          clearInterval(timer)
        })
      }, 600000)
    },
    checkPayment() {
      setTimeout(() => {
        let modalIsAlreadyOpen = this.$store.state.modal.show

        if (!modalIsAlreadyOpen && !new Set(['PaymentResult', 'MessageRoom']).has(this.$route.name) && localStorage.getItem('successfulPayment')) {
          this.$store.commit('setModal', {
            show: true,
            body: this.$lib.imgUrl('img/etc/goto-event-for-client.png', true),
            bodyType: 'image',
            confirm: '돌려받기!',
            confirmCb: modal => {
              localStorage.removeItem('successfulPayment')

              // const android_id = "com.wimz.beagle"
              // const ios_id = "id1339958152"
              // const playStorePrefix = 'https://play.google.com/store/apps/details?id=' + android_id + '&hl=ko'
              // const appStorePrefix = 'https://apps.apple.com/kr/app/' + ios_id
              // const ua = navigator.userAgent
              // const supportDevicesRegEx = /Android|iPhone|iPad/i
              // const supportDevice = ua.match(supportDevicesRegEx)
              // const platform = sessionStorage.getItem('platform')
              // const platformKey = ['android', 'ios'].indexOf(platform)
              // const isApp = platformKey !== -1
              // const isAndroid = isApp && supportDevice && supportDevice[0] === 'Android'
              // const url = encodeURI(isAndroid ? playStorePrefix : appStorePrefix)
              const url = encodeURI('https://blog.naver.com/wimz0816/222641646144')

              this.$runToApp({
                appCb: () => location.href = `hbscheme://gotoWeb?url=${url}`,
                webCb: () => window.open(url),
              })

              modal.hide()
            },
            cancel: '안 받고 나가기',
            cancelCb: modal => {
              localStorage.removeItem('successfulPayment')
              modal.hide()
            },
            hideOnbackdrop: true,
            maxWidth: '44rem',
            bodyMaxHeight: '80vh',
          })
        }
      }, 500)
    },
    checkApplyOffer() {
      setTimeout(() => {
        let modalIsAlreadyOpen = this.$store.state.modal.show

        if (!modalIsAlreadyOpen && this.mainPath.indexOf(this.$route.path) !== -1 && localStorage.getItem('successfulApplyOffer') === '1') {
          this.$store.commit('setModal', {
            show: true,
            body: this.$lib.imgUrl('img/etc/goto-store-for-artist.png'),
            bodyType: 'image',
            confirm: '네, 좋아요',
            confirmCb: modal => {
              localStorage.setItem('successfulApplyOffer', Date.now().toString())

              const android_id = "com.wimz.beagle"
              const ios_id = "id1339958152"
              const playStorePrefix = 'https://play.google.com/store/apps/details?id=' + android_id + '&hl=ko'
              const appStorePrefix = 'https://apps.apple.com/kr/app/' + ios_id
              const ua = navigator.userAgent
              const supportDevicesRegEx = /Android|iPhone|iPad/i
              const supportDevice = ua.match(supportDevicesRegEx)
              const platform = sessionStorage.getItem('platform')
              const platformKey = ['android', 'ios'].indexOf(platform)
              const isApp = platformKey !== -1
              const isAndroid = isApp && supportDevice && supportDevice[0] === 'Android'
              const url = encodeURI(isAndroid ? playStorePrefix : appStorePrefix)

              this.$runToApp({
                appCb: () => location.href = `hbscheme://gotoWeb?url=${url}`,
                webCb: () => window.open(url),
              })

              modal.hide()
            },
            cancel: '아니요',
            cancelCb: modal => {
              localStorage.setItem('successfulApplyOffer', Date.now().toString())
              modal.hide()
            },
            hideOnbackdrop: true,
            maxWidth: '44rem',
            bodyMaxHeight: '80vh',
          })
        }
      }, 500)
    },
    showPopup(popupData) {
      /**
       * 팝업 표시 데이터
       *
       * gourl : 터치시 이동할 URL (https 또는 http로 시작하면 외부 브라우저 호출, / 로 시작하면 앱내 이동)
       * contents : 내용 (내용 아니면 이미지 파일 둘중에 하나만 표시함)
       * imgUrl : 이미지URL (내용 아니면 이미지 파일 둘중에 하나만 표시함)
       */
      if (popupData && typeof popupData === 'object' && Object.keys(popupData).length > 0) {
        let body: string = popupData.contents || ''
        let bodyType = 'text'

        const confirmDate = Number(this.mainProfile?.confirmDate || 0)

        /**
         * popupData.contents에 "/등록만/"이 포홤된 경우 `mainProfile.confirmDate`가 있는 사람에게만 팝업 노출
         */
        if (body.includes('/등록만/') && confirmDate === 0) {
          return;
        }

        if (popupData.imgUrl) {
          body = this.$lib.cdnUrl(popupData.imgUrl)
          bodyType = 'image'
        }

        if (localStorage.getItem('mainPopupEndDate') !== moment().format('YYYY-MM-DD')) {
          setTimeout(() => {
            if (this.mainPath.indexOf(this.$route.path) !== -1) {
              this.$store.commit('setModal', {
                show: true,
                body,
                textAlign: 'text-left',
                bodyType,
                bodyCb: modal => {
                  if (popupData.gourl) this.$gotoWeb(popupData.gourl)
                  modal.hide()

                },
                confirm: '닫기',
                cancel: '오늘 그만보기',
                cancelCb: modal => {
                  localStorage.setItem('mainPopupEndDate', moment().format('YYYY-MM-DD'))
                  modal.hide()
                },
                hiddenCb: () => {
                  this.showPopup()
                },
                hideOnbackdrop: true,
                maxWidth: '44rem',
                bodyMaxHeight: '80vh',
              })
            }
          }, 500)
          return
        }
      }
    // 멤버십 가입 팝업 미노출 처리
    //   if (this.isPlusMemberTargetArtist) {
    //     if (localStorage.getItem('plusMembershipPopupEndDate') !== moment().format('YYYY-MM-DD')) {
    //       setTimeout(() => {
    //         if (this.mainPath.indexOf(this.$route.path) !== -1) {
    //           this.$store.commit('setModal', {
    //             show: true,
    //             body: this.$lib.imgUrl('img/popup/plus-membership-popup.jpg', true),
    //             bodyType: 'image',
    //             bodyCb: modal => {
    //               this.$gotoWeb('/new-membership')
    //               modal.hide()
    //             },
    //             confirm: '닫기',
    //             cancel: '오늘 그만보기',
    //             cancelCb: modal => {
    //               localStorage.setItem('plusMembershipPopupEndDate', moment().format('YYYY-MM-DD'))
    //               modal.hide()
    //             },
    //             hideOnbackdrop: true,
    //             maxWidth: '44rem',
    //             bodyMaxHeight: '80vh',
    //           })
    //         }
    //       }, 500)
    //       return
    //     }
    //   }
    },
    onYoutubeReady() {
      this.$refs.youtube?.player?.playVideo?.()
    },
    pushClickEvent(params) {
      if(params && typeof params == 'object') {
        let url = `/message/${params.offerId}/${params.artistId}`;
        let pickPro = this.$store.getters.getArtistProfiles.find(v => v.isPickPro == true ) || this.profiles[0]
        if(params.artistId != pickPro.artistidx) {
          url = `/home`;
        }
        if(url != this.$route.path) this.$router.push({path: url, params: {offerUid: params.offerId, targetUseridx: params.artistId}});
      }
    }
  },
})
</script>

<style lang="scss">
.page-modal .page-modal-scroll .page-modal-body.ngWordModal {
  margin: 0;

  .ngWordModal-header {
    margin-bottom: 50px;

    .ngWordModal-siren {
      margin-top: 80px;

      img {
        display: block;
        width: 80px;
        margin: 0 auto 25px;
      }
    }

    h1 {
      font-size: 3.2rem;
      font-weight: 500;
      color: #000;
      text-align: center;
      margin: 0 auto 30px;
    }

    p {
      font-weight: 400;
      font-size: 2rem;
      color: #000;
      text-align: center;
      line-height: 2.8rem;

      b {
        font-weight: 400;
        color: #f33d12;
      }
    }
  }

  .ngWordModal-qna {
    padding: 40px 0;

    h1 {
      font-size: 26px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }

    ul {
      margin-bottom: 60px;

      li {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        .qna-item {
          font-size: 1.9rem;
          text-indent: -1.2em;
          padding-left: 1.2em;
          line-height: 1.5;

          &.qna-q {
            font-weight: 600;
          }

          &.qna-a {
            font-weight: 400;
          }
        }
      }
    }

    .ngWordModal-helpcenter {
      color: #0725a9;
      font-size: 20px;
      font-weight: 400;
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  .ngWordModal-footer {
    margin: 0 -3rem;
  }
}
</style>
