export default {
    state: {
        main: {
            offerProNoCount: 0, // 안읽은 지원서수 (0이 아니면 new 표시, 일반회원일 경우에만 출력)
            directOfferNoCount: 0, // 안읽은 1:1오퍼의 수 (0이 아니면 new 표시, 전문가일 경우에만 출력)
            artistContactData: null, // 값이 있으면 메인 전문가문의 new 표시 (공통사항)
        },
    },
    mutations: {
        setEtcMain(state, payload) {
            state.main.offerProNoCount = payload?.offerProNoCount || 0
            state.main.directOfferNoCount = payload?.directOfferNoCount || 0
            state.main.artistContactData = payload?.artistContactData
        },
    },
    actions: {},
    getters: {},
}
