import qs from 'querystring'
import Vue from 'vue'
import { polyfill } from 'es6-promise'
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store/index'
import VueYoutube from 'vue-youtube'
import { MdSwitch } from 'vue-material/dist/components'
import 'vue-material/dist/components/MdSwitch/index.css'
import 'expose-loader?$!expose-loader?jQuery!jquery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/default.scss'
import './assets/css/transition.scss'
import $config from './config/index'
import VCalendar from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { VueMaskDirective } from 'v-mask'
import  VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
import Skeleton from 'vue-loading-skeleton';


import MomentPlugin from '@/plugins/moment'
import Http from './plugins/http'
import log from './lib/log'
import Toast from './plugins/toast'
import Alert from './plugins/alert'
import HistoryStack from './plugins/historyStack'
import RunToApp from './plugins/runToApp'
import EventBus from './plugins/eventBus'

polyfill()
import * as $lib from './lib/globalLib'
import '@/registerServiceWorker.js'
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

// (function joinTest() {
//   /* 회원가입 테스트 */
//   const search = qs.stringify({
//     sns: 'kakao',
//     /* 마지막 snsUid : 0006 */
//     snsUid: '0007',
//   })

//   if (location.pathname !== '/join') {
//     location.href = `/join?${search}`
//   }
// })()

const pinia = createPinia()

const query = qs.parse(location.search.replace(/^\?/, ''))

if (query.sns && query.snsUid) {
  localStorage.removeItem('sns')
  localStorage.removeItem('snsUid')
  localStorage.removeItem('name')
  localStorage.removeItem('phone')
  sessionStorage.removeItem('useridx')
  sessionStorage.removeItem('userToken')
}

// const isDev = /dev|local/.test(location.hostname.split('.')[0]) ? true : false
const sns = query.sns || localStorage.getItem('sns')
const snsUid = query.snsUid || localStorage.getItem('snsUid')
const pushToken = query.pushToken || sessionStorage.getItem('pushToken')
const platform = query.platform || sessionStorage.getItem('platform') || 'webapp'
const deployDate = localStorage.getItem('deployDate')
const referrer = query.referrer
const memo = query.memo
const name = query.name || localStorage.getItem('name')
const phone = query.phone || localStorage.getItem('phone')

if (platform) {
  sessionStorage.setItem('platform', platform)
}

if (deployDate !== DEPLOY_DATE) {
  localStorage.setItem('deployDate', DEPLOY_DATE)
}

localStorage.setItem('deployVersion', DEPLOY_VERSION)

if (referrer) {
  sessionStorage.setItem('referrer', referrer)
}

if (memo) {
  sessionStorage.setItem('initial-query:memo', memo)
}

if (name) {
  localStorage.setItem('name', name)
}

if (phone) {
  localStorage.setItem('phone', phone)
}

const vueInit = () => {
  if (sessionStorage.getItem('useridx')) {
    let onoff = sessionStorage.getItem('pushDeviceSettingValue')

    if (['on', 'off'].indexOf(onoff) !== -1) {
      const req = {
        method: 'put',
        url: `/user/pushDeviceSetting/${onoff}`,
      }
      Vue.prototype.$http(req)
        .then(data => {
          log(...Object.values(req), data)
          return true
        })
        .catch(err => {
          log.error(err)
          return true
        })
        .then(() => sessionStorage.removeItem('pushDeviceSettingValue'))
    }
  }

  new Vue({
    router,
    store,
    pinia,
    render: h => h(App)
  }).$mount('#app')
  document.querySelector('#loading').style.display = 'none'
}

store.router = router

const prototype = {
  $lib,
  $config,
  log,
}

Object.assign(window, { ...prototype })

Vue.config.productionTip = false

Vue.prototype = Object.assign(Vue.prototype, prototype)

Vue.use(MomentPlugin)
Vue.use(PiniaVuePlugin)
Vue.use(EventBus)
Vue.use(VueYoutube)
Vue.use(VCalendar, {
  firstDayOfWeek: 1,
})
Vue.use(MdSwitch)
Vue.use(Toast)
Vue.use(Http, { store })
Vue.use(Alert)
Vue.use(Loading)
Vue.use(HistoryStack, { router })
Vue.use(RunToApp)
Vue.directive('mask', VueMaskDirective)
Vue.use(VueMeta)
Vue.use(VueClipboard)
Vue.use(Skeleton);

if (sns && snsUid) {
  store.dispatch('login', { sns, snsUid, pushToken, platform })
    .catch((error) => {
      log.error(error)
    })
    .then(() => {
      vueInit()
    })
} else {
  vueInit()
}
