import moment from 'moment'
import 'moment-timezone'

moment.locale('ko')
moment.tz.setDefault('Asia/Seoul')

export default {
    install(Vue) {
        Vue.prototype.moment = moment
        Vue.prototype.$moment = moment
        window.moment = moment
    },
}

export { moment }
