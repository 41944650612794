import qs from 'querystring'
import axios from 'axios'
import {
  setHttp
} from '../lib/globalLib'
import store from '@/store'



export default {
  install(Vue, { store }) {
    Vue.prototype.$http = async function (option) {
        const query = qs.parse(location.search.replace(/^\?/, ''))      
        let platform = query.platform || sessionStorage.getItem('platform')
        
        if(!option.url.includes('login')) {
            await reLoginCheck(query, platform);
        }
        
        let useridx = sessionStorage.getItem('useridx')
        let usertoken = sessionStorage.getItem('userToken')
        const useLoading = option.loading !== false
        const useHeader = option.noHeader !== false
        const httpParam = setHttp(option)
        let loader = { hide: () => {} }
        

        httpParam.headers.platform = platform ? platform : 'webapp'


        if (useridx) {
          httpParam.headers.useridx = useridx
        } else {
          delete httpParam.headers.useridx
        }

        if (usertoken) {
          httpParam.headers.usertoken = usertoken
        } else {
          delete httpParam.headers.usertoken
        }

        if (!useHeader) {
          delete httpParam.headers
        }

        if (!store.state.loading && useLoading) {
          store.commit('setLoading', true)
          loader = this.$loading.show({
            color: '#a059b6',
            width: 100,
            height: 100,
            backgroundColor: 'transparent',
          })
        }

        return new Promise((resolve, reject) => {
          axios(httpParam)
          .then(data => {
            if (useLoading) {
              loader.hide()
              store.commit('setLoading', false)
            }
            resolve(data)
          })
          .catch(e => {
            if (useLoading) {
              loader.hide()
              store.commit('setLoading', false)
            }
            if (e.code === 'ECONNABORTED') {
              store.commit('setNetworkModal', true)
            }
            reject(e)
          })
        })
    }
  }
}

async function reLoginCheck(query, platform) {
  if((!sessionStorage.getItem('useridx') || !sessionStorage.getItem('userToken'))) {
    const sns = localStorage.getItem('sns')
    const snsUid = localStorage.getItem('snsUid')
    const pushToken = query.pushToken || sessionStorage.getItem('pushToken')

    try {
      await store.dispatch('login', { sns, snsUid, pushToken, platform })  
    } catch (error) {
      log.error(error)
    }
  }
}
