<template>
  <div ref="modal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" :class="[{ 'no-border': noBorder },{'round-modal': roundModal}]" role="document" :style="{ maxWidth }">
      <div class="modal-content">
        <div class="modal-header" v-if="$slots.header">
          <slot name="header"/>
        </div>
        <div class="modal-header" v-if="title">
          <h5 class="modal-title">{{title}}</h5>
          <button v-if="roundModal" type="button" class="btn-close" data-dismiss="modal" @click="onCancel">
            <icon src="img/common/close-black.png" width="2.8rem" height="2.8rem" :cover="false" />
          </button>
        </div>
        <div class="modal-body" :style="{ maxHeight: bodyMaxHeight }" @click="onBody">
          <slot name="body"/>
        </div>
        <div class="modal-footer" :class="{ single: !useCancel }" v-if="useFooter">
          <button v-if="useCancel" type="button" class="btn btn-secondary" data-dismiss="modal" @click="onCancel">{{cancel}}</button>
          <button type="button" class="btn btn-primary" @click="onConfirm">{{confirm}}</button>
        </div>
      </div>
    </div>
    <div class="modal-backdrop" @click="() => hideOnbackdrop ? hide() : null"></div>
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  components: {
    Icon,
  },
  name: 'Modal',
  props: {
    title: {
      type: String,
    },
    confirm: {
      type: String,
      default: '확인',
    },
    confirmCb: {
      type: Function,
    },
    useCancel: {
      type: Boolean,
      default: true,
    },
    cancel: {
      type: String,
      default: '취소',
    },
    cancelCb: {
      type: Function,
    },
    showCb: {
      type: Function,
    },
    shownCb: {
      type: Function,
    },
    hideCb: {
      type: Function,
    },
    hiddenCb: {
      type: Function,
    },
    bodyCb: {
      type: Function,
    },
    maxWidth: {
      type: String,
      default: '36rem',
    },
    bodyMaxHeight: {
      type: String,
      default: '55vh',
    },
    useFooter: {
      type: Boolean,
      default: true,
    },
    hideOnbackdrop: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    roundModal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      $modal: null,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$modal = $(this.$refs.modal).modal({
        show: false,
        backdrop: false,
      })

      this.$modal.on('show.bs.modal', this.onShow)
      this.$modal.on('shown.bs.modal', this.onShown)
      this.$modal.on('hide.bs.modal', this.onHide)
      this.$modal.on('hidden.bs.modal', this.onHidden)
    })
  },
  methods: {
    show() {
      let container = document.querySelector('.container');
      container.style.overflow = 'hidden';
      this.$nextTick().then(() => {
        if (this.$store.state.loading) {
          setTimeout(() => {
            this.show()
          }, 350)
        } else {
          this.$modal.modal('show')
        }
      })
    },
    hide() {
      let container = document.querySelector('.container');
      container.style.overflow = 'auto';
      this.$modal.modal('hide')
    },
    onCancel() {
      if (this.cancelCb) this.cancelCb(this)
      else this.hide()
    },
    onConfirm() {
      if (this.confirmCb) this.confirmCb(this)
      else this.hide()
    },
    onShow() {
      if (this.showCb) this.showCb(this)
    },
    onShown() {
      if (this.shownCb) this.shownCb(this)
    },
    onHide() {
      if (this.hideCb) this.hideCb(this)
    },
    onHidden() {
      if (this.hiddenCb) this.hiddenCb(this)
    },
    onBody() {
      if (this.bodyCb) this.bodyCb(this)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    max-width: 36rem;
    margin: auto;
    font-size: 2rem;
    color: #000000;

    .modal-header {
      padding: 2rem;
      .modal-title {
        flex: 1;
        padding: 1rem 0;
        font-size: 2.2rem;
        font-weight: 500;
        text-align: center;
      }
    }

    .modal-body {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 55vh;
      // ios 스크롤 관련
      -webkit-overflow-scrolling: touch;

      ul {
        li {
          padding: 0 1.5rem;
          line-height: 8rem;
          color: #5a5a5a;

          .icon {
            float: right;
          }

          &.on {
            color: #a059b6;
            font-weight:600;
          }
        }
      }

      > img {
        margin: -1rem;
        max-width: calc(100% + 2rem);
        width: calc(100% + 2rem);
      }
    }

    .modal-footer {
      padding: 2rem 1rem;
      .btn {
        color: #a059b6;
        font-size: 2.2rem;
        width: 50%;

        background-color: transparent;
        border: none;

        &.btn-secondary {
          color: #5a5a5a;
        }
        // &.btn-primary {
        // }
      }
      &.single {
        .btn {
          width: 100%;
        }
      }
    }
    &.no-border {
      .modal-header,
      .modal-footer {
        border: 0;
      }
    }
    &.round-modal {
      .modal-content {
        border-radius: 1.2rem;
        .modal-header {
          align-items: center;
          .modal-title {
            font-weight: 600;
            text-align: left;
          }
        }
        .modal-body {
          padding: 2rem 2.5rem 0;
          input {
            width: 100%;
            padding: 1.3rem 1.6rem;
            border: 1px solid #A8A8A8;
            border-radius: 0.8rem;
          }
          .input-notice {
            margin-top: 1rem;
            margin-bottom: 0;
            color: #F33841;
            font-size: 1.4rem;
          }
        }
        .modal-footer {
          padding: 2rem 2.5rem 3rem;
          .btn {
            width: 50%;
            padding: 1.5rem;
            border-radius: 0.8rem;
            background-color: #a059b6;
            color: #fff;
            font-size: 2rem;
            font-weight: 600;
            &.btn-secondary {
              color: #5a5a5a;
            }
          }
          &.single {
            .btn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.modal-backdrop {
  // position: fixed;
  // top: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  z-index: -1;
  background-color: rgba($color: #000, $alpha: 0.5);
}
</style>
