<template>
  <div class="ui-navBottom">
    <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/home')}" @click="() => gotoTab('/home')">
      <icon v-show="!isActive('/home')" :src="`img/nav-bottom/home.png`" width="28px" height="28px" :cover="false" />
      <icon v-show="isActive('/home')" :src="`img/nav-bottom/home-on.png`" width="28px" height="28px" :cover="false" />
      <span class="text">홈</span>
    </div>

    <template v-if="isClient">
      <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/client/my-offer')}" @click="() => gotoTab('/client/my-offer')">
        <icon v-show="!isActive('/client/my-offer')" :src="`img/nav-bottom/human-outline.png`" width="28px" height="28px" :cover="false" origin-url />
        <icon v-show="isActive('/client/my-offer')" :src="`img/nav-bottom/human-outline-on.png`" width="28px" height="28px" :cover="false" origin-url />
        <span class="text">MY</span>
        <div class="new" v-if="hasNewOfferPro"></div>
      </div>
    </template>
    <template v-else>
      <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/artist/my-offer')}" @click="() => gotoTab('/artist/my-offer')">
        <icon v-show="!isActive('/artist/my-offer')" :src="`img/nav-bottom/paper.png`" width="28px" height="28px" :cover="false" />
        <icon v-show="isActive('/artist/my-offer')" :src="`img/nav-bottom/paper-on.png`" width="28px" height="28px" :cover="false" />
        <span class="text">MY 의뢰</span>
        <div class="new" v-if="hasNewDirectOffer"></div>
      </div>
    </template>

    <!-- 멤버십 무료 전환 - 채팅 메뉴 변경 -->
    <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/communicate')}" @click="() => gotoTab('/communicate')">
      <icon v-show="!isActive('/communicate')" :src="`img/nav-bottom/dialog.png`" width="28px" height="28px" :cover="false" origin-url />
      <icon v-show="isActive('/communicate')" :src="`img/nav-bottom/dialog-on.png`" width="28px" height="28px" :cover="false" origin-url />
      <span class="text">채팅</span>
      <div class="new" v-if="hasNewMsg || hasNewQna"></div>
    </div>

    <!-- 멤버십 무료 전환 - 메뉴 재구성 -->
    <template v-if="isClient">
      <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/client/fav')}" @click="() => gotoTab('/client/fav')">
        <icon v-show="!isActive('/client/fav')" :src="`img/nav-bottom/favorite.png`" width="28px" height="28px" :cover="false" origin-url />
        <icon v-show="isActive('/client/fav')" :src="`img/nav-bottom/favorite-on.png`" width="28px" height="28px" :cover="false" origin-url />
        <span class="text">찜</span>
      </div>
    </template>
    <template v-else>
      <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/artist/payment')}" @click="() => gotoTab('/artist/payment')">
        <icon v-show="!isActive('/artist/payment')" :src="`img/nav-bottom/pay.png`" width="28px" height="28px" :cover="false" />
        <icon v-show="isActive('/artist/payment')" :src="`img/nav-bottom/pay-on.png`" width="28px" height="28px" :cover="false" />
        <span class="text">결제 내역</span>
      </div>
    </template>

    

    <!-- <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/message/list')}" @click="() => gotoTab('/message/list')">
      <icon v-show="!isActive('/message/list')" :src="`img/nav-bottom/dialog.png`" width="28px" height="28px" :cover="false" origin-url />
      <icon v-show="isActive('/message/list')" :src="`img/nav-bottom/dialog-on.png`" width="28px" height="28px" :cover="false" origin-url />
      <span class="text">채팅</span>
      <div class="new" v-if="hasNewMsg"></div>
    </div> -->

    <!-- 전문가 프로필 버튼 -->
    <template v-if="isClient">
      <div class="ui-navBottom-btn" :class="{'ui-navBottom-btn-active': isActive('/setting')}" @click="() => gotoTab('/setting')">
        <icon v-show="!isActive('/setting')" :src="`img/nav-bottom/more.png`" width="28px" height="28px" :cover="false" />
        <icon v-show="isActive('/setting')" :src="`img/nav-bottom/more-on.png`" width="28px" height="28px" :cover="false" />
        <span class="text">더보기</span>
      </div>
    </template>
    <template v-else >
        <div @touchend="clearTimer" @touchstart="startTimer" class="ui-navBottom-btn profile has-img" :class="{'ui-navBottom-btn-active': isActive('/profile')}" @click="handleClick" > <!-- 프로필 아이콘이 있는 경우 'has-img' 클래스 추가 -->
          <div v-if="profileImg">
            <div v-show="!isActive('/profile')" class="profile-img" :style="{backgroundImage: `url(${profileImg})`}"></div>
            <div v-show="isActive('/profile')" class="profile-img" :style="{backgroundImage: `url(${profileImg})`}"></div>
          </div>
          <div v-else>
            <!-- 프로필 아이콘이 없을 경우 노출 -->
            <icon v-show="!isActive('/profile')" :src="`img/nav-bottom/profile-no-img.png`"  width="34px" height="34px" :cover="false" />
            <icon v-show="isActive('/profile')" :src="`img/nav-bottom/profile-no-img.png`" width="34px" height="34px" :cover="false" />
          </div>
          <span class="text">프로필</span>
          <div v-if="hasNewAlarm" class="new"></div>
        </div>
    </template>
    <div class="tooltip-profile" :class="{'hide': this.aprvProfiles.length < 1 || tooltipShow == false}" v-if="isArtist"> <!-- 등록된 프로필이 1개 이상인 경우 노출 --> 
      <div class="tooltip-profile-inner">
        <button type="button" class="tooltip-profile-close" @click="() => hideTooltip()"></button>
        <div>프로필을 꾸~욱 누르면<br>다른 프로필로 전환할 수 있어요!</div>
      </div>
    </div>
    <ProfileSelect
      v-if="showProfileSelectModal"
      :profiles="aprvProfiles || []"
      :hasNewAlarm="alarmList || []"
      @close="modalClose"
      @open-full="fullModal"
      @submit="() => nextStep()"
    ></ProfileSelect>
    <ProfileSelectFull
    v-if="showProfileSelectFullModal"
    :profiles="aprvProfiles || []"
    :hasNewAlarm="alarmList || []"
    @close="modalFullClose"
    @submit="() => nextStep()"
    >
    </ProfileSelectFull>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon.vue'
import FooterBox from '@/components/common/FooterBox.vue'
import { ref } from '@vue/composition-api'
import ProfileSelect from "@/views/profile/ProfileSelect.vue";
import ProfileSelectFull from "@/views/profile/ProfileSelectFull.vue"

export default {
  name: 'NavBottom',
  components: {
    Icon,
    FooterBox,
    ProfileSelect,
    ProfileSelectFull,
  },
  data() {
    return {
      alarmList: [],
      hasNewAlarm: false,
      pickAlarm: {},
    }
  },
  setup() {
    const isShow = ref(false);
  },
  created() {
    this.getArtistProfiles()
    this.getHasNewAlarm()
  },
  beforeRouteEnter(to, from, next) {
    next(Vue => {
      Vue.$refs.container.scrollTop = Vue.scroll.lastY
      Vue.getArtistProfiles()
      Vue.getHasNewAlarm()
    })
  },
  computed: {
    tooltipShow() {
      return this.$store.getters.getTooltipShow
    },
    showProfileSelectModal() {
      return this.$store.getters.isShowProfileSelectModal
    },
    showProfileSelectFullModal() {
      return this.$store.getters.isShowProfileSelectFullModal
    },
    userData() {
      return this.$store.state.user.userData
    },
    isArtist() {
      return this.userData.category === 'artist'
    },
    isClient() {
      return this.userData.category === 'client'
    },
    hasNewQna() {
      return this.isArtist && !!this.$store?.state?.etc?.main?.artistContactData
    },
    hasNewMsg() {
      return this.pickAlarm.hasNewMsg && this.pickAlarm.hasNewMsg > 0 ? true : false
    },
    hasNewOfferPro() {
      return this.$store.state.etc.main.offerProNoCount > 0
    },
    hasNewDirectOffer() {
      return this.pickAlarm.hasNewDirectOffer && this.pickAlarm.hasNewDirectOffer > 0 ? true : false
    },
    isActive() {
      return (path: string = '/home') => {
        return new RegExp(`^${path}`).test(this.$route.path)
      }
    },
    aprvProfiles() {
      return this.profileList.filter(v => v.artistProfileStep == 3 );
    },
    profileList(){
      return this.$store.getters.getArtistProfiles;
    },
    profileImg(){
      let mainProfile = this.aprvProfiles.find(v => v.isPickPro == true) || this.aprvProfiles[0]
      if(mainProfile){
        let img = mainProfile.teamImgUrl ? mainProfile.teamImgUrl : mainProfile.imgUrl1;
        return img ? img: ''
      }
      return '';
    }
  },
  methods: {
    fullModal() {
      this.modalClose();
      this.$store.commit('setProfileSelectFullModal', !this.showProfileSelectFullModal);
    },
    gotoTab(path) {
      if (!this.isActive(path)) {
        this.$gotoWeb(path)
      } else {
        window?.scrollTo?.({ top: 0 })
        this.$eventBus.$emit('refresh', {path})
      }
    },
    showActive() {
      this.isShow = true;
    },
    startTimer() {
      // 일정 시간이 지나면 long click으로 처리
      this.timer = setTimeout(() => {
        this.handleLongClick();
      }, 500); 
      return 
    },
    clearTimer() {
      // 마우스를 떼면 타이머를 초기화
      clearTimeout(this.timer);
    },
    handleClick() {
      this.gotoTab('/profile');
    },
    handleLongClick() {
      if(this.aprvProfiles.length > 0){
        this.hideTooltip();
        if(!this.showProfileSelectModal) {
          this.$store.commit('setProfileSelectModal', !this.showProfileSelectModal);
        }
      }else{
        this.gotoTab('/profile');
      }
    },
    hideTooltip() {
      this.$store.commit('setTooltipShow', false);
    },
    modalClose() {
      this.$store.commit('setProfileSelectModal', !this.showProfileSelectModal);
      this.getArtistProfiles()
      this.getHasNewAlarm()
    },
    modalFullClose() {
      this.$store.commit('setProfileSelectFullModal', !this.showProfileSelectFullModal);
      this.getArtistProfiles()
      this.getHasNewAlarm()
    },
    nextStep() {
      this.$store.commit('setArtistData', null)
      this.$store.commit('setArtistProfileStep', 1)
      this.$router.push(`/artist/my-profile`)
    },
    getArtistProfiles() {
      this.$store.dispatch('getProfileList')
    },
    getHasNewAlarm(){
      if (!this.userData.useridx) return Promise.resolve()
      
      const req = {
          method: 'get',
          url: `/artist/hasAlarm`,
      }
      return this.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)
          const resultData = this.$lib.resultCheck(data.resultData)
          
          if (resultData.success) {
            let artistData = new Array();
            let sum = 0;
            var alarms = data.response.profileAlarms;
            if(alarms){
              this.alarmList = alarms;
              alarms.forEach((v, index) => {
                if(index > 0){
                  sum = sum + parseInt(v.hasNewDirectOffer) + parseInt(v.hasNewMsg) + parseInt(v.hasNewContact);
                }else{
                  this.pickAlarm = v;
                }
              });
              if(sum > 0) this.hasNewAlarm = true;
            }
          } else {
            this.$alert(resultData)
          }
          return
          })
        .catch((e) => {
          log.error(e)
          return
        })
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';
.ui-navBottom {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 768px;
  height: 90px;
  box-shadow: 0 0 15px rgba(152, 152, 152, 0.5);
  background-color: #fbfbfb;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .ui-navBottom-btn {
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .text {
      margin-top: 10px;
      font-size: 16px;
      color: #5A5A5A;
    }
    .new {
      position: absolute;
      right: 30%;
      top: 13%;
      width: 7px;
      height: 7px;
      background-color: #f33d12;
      border-radius: 50%;
    }

    &.ui-navBottom-btn-active {
      .text {
        color: $color-deepLavender;
      }
    }

    &.profile {
      position: relative;
      .icon {
        border-radius: 50%;
      }
      &.has-img {
        .icon {
          border: 1px solid #ededed;
        }
        &.ui-navBottom-btn-active  {
          .icon {
            border-color: #AB54BB;
          }
        }
      }
    }
  }
  .tooltip-profile {
    display: block;
    position: fixed;
    z-index: 1000;
    bottom: 110px;
    right: 14px;
    width: 300px;
    border-radius: 4px;
    background-color: #AB54BB;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    &.hide {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -7px;
      right: 25px;
      width: 14px;
      height: 14px;
      background-color: #AB54BB;
      transform: rotate(45deg);
    }
  &-inner {
      position: relative;
      padding: 16px 56px 16px 16px;
      .tooltip-profile-close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 20px;
        height: 20px;
        background: url(/img/common/close-button@2x.png) no-repeat center / cover;
      }
    }
  }
  .profile-img {
    overflow: hidden;
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 100%;
    background-position: 50% 50%;
    background-size: cover;
  }
}
</style>
