export default {
  install(Vue) {
    let lastPath = ''

    Vue.prototype.$runToApp = function({ appCb, androidCb, iosCb, webCb }, useWebOnFail = false) {
      let ua = navigator.userAgent
      let supportDevicesRegEx = /Android|iPhone|iPad/i
      let supportDevice = ua.match(supportDevicesRegEx)

      const platform = sessionStorage.getItem('platform')
      const platformKey = ['android', 'ios'].indexOf(platform)
      const isApp = platformKey !== -1
      const isAndroid = isApp && supportDevice && supportDevice[0] === 'Android'
      const isIos = isApp && !isAndroid
      lastPath = this.$route.path

      if (isApp) {
        if (typeof appCb === 'function') {
          appCb()
        } else if (isAndroid && typeof androidCb === 'function') {
          androidCb()
        } else if (isIos && typeof iosCb === 'function') {
          iosCb()
        }

        if (useWebOnFail) {
          setTimeout(() => {
            lastPath === this.$route.path && runWeb()
          }, 500)
        }
      } else {
        runWeb()
      }

      function runWeb() {
        typeof webCb === 'function' && webCb()
      }
    }

    Vue.prototype.$gotoWeb = function(url) {
      if (/^https?:\/\//.test(url)) {
        this.$runToApp({
          appCb: () => location.href = `hbscheme://gotoWeb?url=${encodeURI(url)}`,
          webCb: () => window.open(encodeURI(url)),
        })
      } else if (/^\//.test(url)) {
        this.$router.push(url)
      }
    }
  },
}