<template>
  <div
    id="gnb"
    v-if="isShow"
    :class="{
      deepLavender: gnbBg === 'deepLavender',
      transparent: $route.meta.title === 'none' || gnbBg === 'transparent',
    }"
    :style="{
      backgroundColor: $route.meta.title === 'none'
        ? `rgba(255,255,255, ${gnbBgAlpha})`
        : null
    }"
  >
    <div class="gnb-header">
      <div
        class="gnb-left"
        v-if="isShow">
        <template v-if="!$route.meta.noLnb && $route.name === 'home'">
          <!-- <div class="ui-icon-menu" @click="openLnb()">
            <div class="ui-icon-menu-bar"></div>
            <div class="ui-icon-menu-bar"></div>
            <div class="ui-icon-menu-bar"></div>
          </div> -->
          <icon class="ui-btn" @click="() => openLnb()" :src="`img/common/menu-${gnbBg === 'deepLavender' ? 'white' : 'gray'}.png`" width="3rem" height="3rem" />
        </template>
        <template v-else>
          <div class="ui-btn" @click="gnbLeftBtnCb">
            <icon
              v-if="gnbLeftBtn === 'close'"
              src="img/common/close-gray-button.png"
              width="2rem"
              height="2rem"
              cursor="pointer"
              style="margin: 5px"
            />
            <icon
              v-else-if="gnbLeftBtn !== 'none'"
              :src="`img/common/back-arrow-button${$route.meta.title === 'none'
                ? gnbBgAlpha < 1
                  ? '-w'
                  : ''
                : ''
              }.png`"
              width="2.5rem"
              height="2.5rem"
              cursor="pointer"
              style="margin: 0.25rem"
              :cover="false"
            />
          </div>
        </template>
      </div>

      <div class="gnb-center ui-ellipsis">
        <router-link to="/home" v-if="$store.state.gnbTitle || $route.meta.title">

            
          <template>{{$lib.t_genre($store.state.gnbTitle) || ($route.meta.title === 'none' ? '' : $lib.t_genre($route.meta.title))}}</template>
        </router-link>
        <router-link to="/home" v-else>
          <!-- <icon class="ui-ma" :src="logoUrl" width="14rem" height="3rem" :cover="false" /> -->
        </router-link>
      </div>

      <div
        class="gnb-right"
        :class="[
        {'gnb-right-messageRoom': $route.name === 'MessageRoom'},
        {'multi-btns':  gnbRightBtn === 'profile-main'},
        ]
        "
        >
        <template v-if="$route.name === 'ClientRegisteredOffer'">
          <div
            class="ui-btn color-lavender"
            :style="{
              position: 'absolute',
              width: 'max-content',
              right: 0,
            }"
            @click="gnbRightBtnCb"
          >
            새로 문의하기
          </div>
        </template>
        <template v-else-if="$route.name === 'MessageRoom'">
          <template v-if="$store.state.gnbBtns.moreBtnInMessageRoomForArtist">
            <div
              class="ui-btn"
              @click="() => gnbRightBtnCb('forArtist')"
            >
              <icon
                src="img/message/more.png"
                width="27px"
                height="30px"
                :cover="false"
                cursor="pointer"
                :style="{
                  marginLeft: '24px',
                  marginTop: '2px',
                }"
              />
            </div>
          </template>
          <template v-else>
            <div
              class="ui-btn" @click="() => gnbRightBtnCb('out')">
              <icon
                src="img/message/goout.png"
                width="22px"
                height="30px"
                :cover="false"
                cursor="pointer"
                :style="{
                  marginLeft: '24px',
                  marginTop: '2px',
                }"
                origin-url
              />
            </div>
          </template>
          <!-- <div
            v-if="isClient"
            class="ui-btn" @click="() => gnbRightBtnCb('report')">
            <icon
              src="img/gnb/report.png"
              width="27px"
              height="30px"
              :cover="false"
              cursor="pointer"
              :style="{
                marginLeft: '24px',
              }"
            />
          </div> -->
          <template v-if="$store.state.gnbBtns.phone">
            <div class="ui-btn" @click="() => gnbRightBtnCb('phone')">
              <icon
                src="img/gnb/phone.png"
                width="22px"
                height="30px"
                :cover="false"
                cursor="pointer"
                :style="{
                  marginLeft: '24px',
                  marginTop: '2px',
                }"
              />
            </div>
          </template>
        </template>
        <template v-else>
          <div class="ui-icon-search" v-if="gnbRightBtn === 'search'">
            <div class="ui-icon-search-circle"></div>
            <div class="ui-icon-search-bar"></div>
          </div>

          <div
            v-if="gnbRightBtn === 'menu'"
            class="ui-icon-menu"
            @click="gnbRightBtnCb"
          >
            <div class="ui-icon-menu-bar"></div>
            <div class="ui-icon-menu-bar"></div>
            <div class="ui-icon-menu-bar"></div>
          </div>

          <div
            v-if="gnbRightBtn === 'my-profile'"
            class="ui-icon-my-profile"
            @click="gnbRightBtnCb"
          >
            저장 및 나가기
          </div>

          <div
            v-if="gnbRightBtn === 'close'"
            class="ui-btn"
            @click="gnbRightBtnCb"
          >
            <icon
              src="img/common/close-gray-button.png"
              width="2rem"
              height="2rem"
              cursor="pointer"
              style="margin: 5px"
            />
          </div>

          <div
            v-if="gnbRightBtn === 'calc-fee'"
            class="ui-icon-my-profile"
            @click="gnbRightBtnCb"
          >
            <icon
              src="img/common/close-gray-button.png"
              width="2rem"
              height="2rem"
              cursor="pointer"
              style="margin: 5px"
            />
          </div>

          <template
            v-if="gnbRightBtn === 'profile-main'"
          >
            <div
              class="ui-btn"
              @click="rightBtnCb('profile')"
            >
              <icon
                src="img/gnb/profile.png"
                width="3.2rem"
                height="3.2rem"
              />
            </div>
            <div
              class="ui-btn"
              @click="rightBtnCb('setting')"
            >
              <icon
                src="img/gnb/setting.png"
                width="3.2rem"
                height="3.2rem"
              />
            </div>
          </template>
        </template>
      </div>
    </div>

    <div
      v-if="/^\/offer\/post\/[0-9]{1,2}/.test($route.path) && $route.params.step > 1"
      class="gnb-body gnb-body-postOffer">
      <ProgressBar :percent="$store.state.gnbBody.postOfferPercent" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import ProgressBar from '@/components/common/ProgressBar'

export default {
  name: 'gnb',
  components: {
    Icon,
    ProgressBar,
  },
  props: {
    height: {
      type: Number,
    },
  },
  data() {
    return {
      scrollY: 0,
    }
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      }
    },
    isClient: {
      get() {
        return this.userData.category === 'client'
      }
    },
    isArtist: {
      get() {
        return this.userData.category === 'artist'
      },
    },
    offerUid: {
      get() {
        return Number(this.$route.params?.offerUid)
      }
    },
    logoUrl: {
      get() {
        const gnbBg = this.gnbBg
        return `img/logo${gnbBg === 'white' ? '-b' : ''}.png`
      },
    },
    gnbLeftBtn() {
      return this.$store?.state?.gnbLeftBtn || this.$route?.meta?.gnbLeftBtn
    },
    gnbRightBtn() {
      return this.$store?.state?.gnbRightBtn || this.$route?.meta?.gnbRightBtn
    },
    gnbLeftBtnCb: {
      get() {
        const gnbLeftBtnCb = this.$store.state.gnbLeftBtnCb
        if (gnbLeftBtnCb) return gnbLeftBtnCb
        return () => this.$router.back()
      },
    },
    gnbRightBtnCb: {
      get() {
        const gnbRightBtnCb = this.$store.state.gnbRightBtnCb
        if (gnbRightBtnCb) return gnbRightBtnCb
        return () => {}
      },
    },
    isShow() {
      return this.platform !== 'webapp' || (this.platform === 'webapp' && this.$route.name === 'WriteReview') || this.$store.getters.isLAN
    },
    gnbBgAlpha() {
      const alpha = Math.floor((this.scrollY / this.$store.state.gnbHeight) * 10)
      return alpha / 10
    },
    platform() {
      return sessionStorage.getItem('platform') || 'webapp'
    },
    gnbBg() {
      return this.$store.state.gnbBg || this.$route.meta.gnbBg || 'white'
    },
  },
  watch: {
    '$route.path'(path) {
      switch (path) {
        // case '/home':
        //   if (this.isClient) {
        //     this.$store.commit('setGnbBg', 'deepLavender')
        //   } else {
        //     this.$store.commit('setGnbBg')
        //   }
        //   break
        default:
          this.$store.commit('setGnbBg')
          if (this.$route.meta.title === 'none') {
            this.scrollY = 0
          }
          break
      }
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      window.addEventListener('scroll', this.getScrollY, true)
    })
  },
  methods: {
    openLnb() {
      this.$store.commit('setLnb', 'toggle')
    },
    getScrollY() {
      this.scrollY = document.querySelector('.container')?.scrollTop || 0
    },
    rightBtnCb(type){
      if(type == 'profile'){
        this.$store.commit('setArtistData', null)
        this.$store.commit('setArtistProfileStep', 1)
        this.$router.push(`/artist/my-profile`)
      } else if (type == 'setting'){
        this.$router.push(`/setting`)
      }
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

body.modal-open {
  #gnb {
    z-index: 0;
  }
}
#gnb {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  max-width: 768px;
  margin: auto;
  background-color: #fff;
  // box-shadow: 0 -3px 6px 1px rgba(0, 0, 0, 0.3);
  transition: 0.2s ease-in-out;

  &.deepLavender {
    background-color: $color-deepLavender;
    a {
      color: $color-white;
    }
  }
  &.transparent {
    background-color: transparent;
  }

  a {
    color: #000;
    text-decoration: none;
  }

  .gnb-header {
    position: relative;
    padding: 3rem;
    line-height: 3.8rem;
    font-size: 2.1rem;
    text-align: center;

    > div {
      height: 3rem;
    }

    .gnb-center {
      position: relative;
      z-index: 0;
      max-width: 290px;
      margin: 0 auto;
    }
    .gnb-left,
    .gnb-right {
      position: absolute;
      top: 3rem;
      width: 3rem;
      height: 3rem;
      z-index: 1;
    }

    .gnb-left {
      left: 3rem;
    }

    .gnb-right {
      right: 3rem;

      &.gnb-right-messageRoom {
        width: 10rem;
        .ui-btn {
          float: right;
        }
      }

      &.multi-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: auto;
      }
    }
  }

  .ui-icon-search {
    position: relative;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    .ui-icon-search-circle {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 1.75rem;
      height: 1.75rem;
      border: solid 0.2rem #a8a8a8;
      border-radius: 50%;
    }
    .ui-icon-search-bar {
      position: absolute;
      left: 17px;
      top: 20px;
      width: 1rem;
      height: 0.25rem;
      background-color: #a8a8a8;
      transform: rotate(45deg);
    }
  }

  .ui-icon-my-profile {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 14rem;
    text-align: right;
    line-height: 3rem;
    font-size: 2rem;
    cursor: pointer;
  }

  &.deepLavender {
    .ui-icon-menu-bar {
      background-color: $color-white;
    }
    .ui-icon-search-circle {
      border-color: $color-white;
    }
    .ui-icon-search-bar {
      background-color: $color-white;
    }
  }
}
</style>
