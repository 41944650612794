var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer-box", {
    staticClass: "select-profile-modal",
    attrs: {
      "submit-text": "프로필 추가",
      modalShow: _vm.isShow,
      modalCb: function() {
        return _vm.modalClose()
      },
      submitCb: function() {
        return _vm.submit()
      },
      modalFullCb: function() {
        return _vm.modalFullShow()
      },
      draggAble: true
    },
    scopedSlots: _vm._u([
      {
        key: "modalBody",
        fn: function() {
          return [
            _c("div", { staticClass: "select-profile-wrap" }, [
              _c("div", { staticClass: "select-profile-area" }, [
                _c("div", { staticClass: "select-profile-tit" }, [
                  _vm._v("프로필 전환")
                ]),
                _c(
                  "ul",
                  { staticClass: "profile-select-list" },
                  _vm._l(_vm.profiles, function(row, key) {
                    return _c("li", { key: key }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.isPickPro,
                            expression: "isPickPro"
                          }
                        ],
                        staticClass: "sr-only",
                        attrs: {
                          type: "radio",
                          id: "profile" + (key + 1),
                          name: "profileChange"
                        },
                        domProps: {
                          value: row.isPickPro,
                          checked: _vm._q(_vm.isPickPro, row.isPickPro)
                        },
                        on: {
                          change: function($event) {
                            _vm.isPickPro = row.isPickPro
                          }
                        }
                      }),
                      _c(
                        "label",
                        {
                          attrs: { for: "profile" + (key + 1) },
                          on: {
                            click: function() {
                              return _vm.select(row)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "img-area" }, [
                            _c("div", {
                              staticClass: "img",
                              style: {
                                backgroundColor: "lightgrey",
                                backgroundImage:
                                  "url(" +
                                  _vm.$lib.cdnUrl(
                                    row.teamImgUrl || row.imgUrl1
                                  ) +
                                  ")"
                              }
                            }),
                            _vm.hasAnyTrueValue(row.artistidx)
                              ? _c("div", { staticClass: "new" })
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "profile-info" }, [
                            _c("div", { staticClass: "userNickName" }, [
                              _vm._v(_vm._s(row.teamName))
                            ]),
                            _c("div", { staticClass: "genre" }, [
                              _vm._v(_vm._s(row.genre))
                            ])
                          ]),
                          _c("div", { staticClass: "radio-btn" })
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "submitLeftSlot",
        fn: function() {
          return [
            _c("icon", {
              style: {
                display: "inline-block",
                marginBottom: "-8px"
              },
              attrs: {
                slot: "submitLeftSlot",
                src: "img/common/plus-white.png",
                width: "3.2rem",
                height: "3.2rem",
                cover: false
              },
              slot: "submitLeftSlot"
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }