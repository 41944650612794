var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "img-uploader",
      class: {
        show: _vm.isShow
      }
    },
    [
      _c("input", {
        ref: "file",
        attrs: { type: "file", accept: "image/*" },
        on: { change: _vm.fileChange }
      }),
      _c("img", { ref: "img" }),
      _c("div", { staticClass: "img-uploader-btns" }, [
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.closeUploader } },
          [_vm._v("취소")]
        ),
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.imgRotate } },
          [_vm._v("회전")]
        ),
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.imgCrop } },
          [_vm._v("자르기")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }