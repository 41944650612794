const defaultInclude = JSON.stringify([
  'Home',
  'JoinInput',
  'OfferSearchResult',
  'ArtistMyOffer',
  'ArtistDirectOffer',
  'ArtistFavOffer',
  'ClientMyOffer',
  'ClientRegisteredOffer',
  'ArtistList',
  'ClientFavArtist',
  'ApplyOffer',
  'Consulting',
  'Setting',
  'ProfileList',
  'Qna',
  'QnaDetail',
  'Recruiter',
  'PaymentHistoryList',
  'Communicate',
  'ClientFavArtist'
])

export default {
  state: {
    max: 5,
    include: JSON.parse(defaultInclude),
  },
  mutations: {
    keepAlive_include_removeKey(state, payload) {
      let includeKey = state.include.indexOf(key => key === payload)
      if (includeKey !== -1) state.include.splice(includeKey, 1)
    },
    keepAlive_include_addKey(state, payload) {
      let includeKey = state.include.indexOf(key => key === payload)
      if (includeKey === -1) state.include.push(includeKey)
    },
  },
  actions: {
  },
  getters: {
    keepAlive: state => ({ route }) => {
      let max = state.max
      let include = []

      if (route.name === 'home') {
        include = ['Home', 'ApplyOffer']
      } else {
        include = state.include
      }

      return {
        max,
        include,
      }
    },
  },
}