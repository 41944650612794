import { usePostOfferStore } from '@/pinia/modules/post-offer'

const defaultState = JSON.stringify({
  artistidx: null,
  mode: null,
  startOfferFlag: 'n',
  genre: null,
  playDate: null,
  location: null,
  address: null,
  wmcStyle: null,
  wsong: null,
  geoX: null,
  geoY: null,
  priceStart: null,
  priceEnd: null,
  title: null,
  detail: null,
  phone: null,
  playTime: null,
  playLocation: null,
  expCount: null,
  eventType: null,
  wage: null,
  wgender: null,
  wmic: null,
  wmakeUp: null,
  wtransCost: null,
  whairStyle: null,
  productType: null,
  _wantPrice: null,
})

export default {
  state: Object.assign({}, JSON.parse(defaultState)),
  mutations: {
    setPostOffer(state, data) {
      state = Object.assign(state, data)
    },
    resetPostOffer(state, data = {}) {
      state = Object.assign(state, data, JSON.parse(defaultState))
      sessionStorage.removeItem('eventDate')
    },
  },
  actions: {
    'postOffer/dataFiltering'(context, data = {}) {
      let result = {}

      Object.keys(JSON.parse(defaultState)).forEach((key) => {
        result[key] = data[key]
      })

      return result
    },
    'postOffer/setData'(context, data = {}) {
      let result = {}

      for (let key in data) {
        if (/^_.+/.test(key)) {
          continue
        }

        result[key] = data[key] === null ? '' : data[key]
      }

      // 모델인 경우 eventType과 genre를 바꿔줌
      if (result?.eventType === '모델') {
        const nextGenre = result.eventType
        result.eventType = result.genre
        result.genre = nextGenre
      }

      return result
    },
    async 'postOffer/submit'({commit, dispatch, state}, vm) {
      const req = {
        method: 'post',
        url: `/client/offer`,
        data: await dispatch('postOffer/setData', state),
      }

      return vm.$http(req)
        .then(({ data }) => {
          log(...Object.values(req), data)

          const resultData = vm.$lib.resultCheck(data.resultData)

          if (resultData.success) {
            sessionStorage.setItem('pageCache:PostOffer_Complete', JSON.stringify(state))
            sessionStorage.removeItem('eventDate')
            commit('resetPostOffer')
            usePostOfferStore().$reset()
          }

          return resultData
        })
    },
  },
  getters: {
  },
}